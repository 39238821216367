import {
    IndexTable,
    LegacyCard,
    useIndexResourceState,
    Button,
    Icon,
} from "@shopify/polaris";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import logo from "../assets/images/room_type.png";
import {
    BgThemeButton,
} from "./UI/Buttons";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import Loader from "./UI/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";

const Salutation = () => {
    const navigate = useNavigate();
    const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
        useState(false);
    const userToken = JSON.parse(localStorage.getItem("userToken"));

    const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
        useState(false);

    const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);
    const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

    const [showLowerMenuAddMenu, setShowLowerMenuAddMenu] = useState(false);

    const [showModelMenuActive, setShowModelMenuActive] = useState(false);

    const [syncPopup, setSyncPopup] = useState(false);

    const resourceName = {
        singular: "order",
        plural: "orders",
    };

    const [orders, setOrders] = useState([]);
    const [btnStatus, setButtonStatus] = useState([]);

    const property_id = useSelector((state) => state.property.value);

    const [loader, setLoader] = useState(true);

    const fetchInfo = async () => {
        try {
            const response = await fetch(
                // {{live}}/api/v1/admin/plans/list
                `${process.env.REACT_APP_BASE_URL}/api/v1/salutation/list`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            const data = await response.json();
            console.log(data);
            if (data.message === "Unauthorised.") {
                navigate("/");
            }
            setOrders(data.data);
        } catch (error) {
            console.error(error);
        }
    };

    const [fetchedRoomType, setFetchedRoomType] = useState([]);

    useEffect(() => {
        // if (property_id) {
        fetchInfo();
        fetchAllModules();
        // fetchRoomType(property_id?.id);
        // }
    }, []);

    const {
        selectedResources,
        allResourcesSelected,
        handleSelectionChange,
        clearSelection,
    } = useIndexResourceState(orders);


    useEffect(() => {
        if (selectedResources.length === 1) {
            // getOneData(selectedResources[0]);

            setShowLowerMenuMultipleSelect(true);
            setShowLowerMenuAddMenu(false);
        } else if (selectedResources.length > 1) {
            setShowLowerMenuMultipleSelect(true);
            setShowLowerMenuSingleSelect(false);
            setShowLowerMenuAddMenu(false);
        } else {
            setShowLowerMenuSingleSelect(false);
            setShowLowerMenuAddMenu(false);
            setShowLowerMenuMultipleSelect(false);
        }
    }, [selectedResources]);

    const addButtonClickHandler = () => {
        setShowLowerMenuSingleSelect(false);

        setShowLowerMenuMultipleSelect(false);
        setShowLowerMenuAddMenu(!showLowerMenuAddMenu);
    };

    const getOneData = async (id) => {
        setSelectedId(id);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/salutation/edit/${id}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            const data = await response.json();

            if (data.success) {
                clearSelection();
                setAddSalutationData(data.data);
                setShowAddModelMenuActive(true);
                return;
            } else {
                showErrorToast("Something went wrong");
            }

        } catch (error) {
            console.error(error);
        }
    };

    const rowMarkup = orders?.map(({ id, property_name, name, price, index }) => {
        return (
            <IndexTable.Row
                id={id}
                key={index}
                selected={selectedResources.includes(id)}
                position={id}
            >
                <IndexTable.Cell>{name}</IndexTable.Cell>

                <IndexTable.Cell>
                    <button
                        style={{
                            padding: "5px",
                            fontSize: "14px",
                            color: "white",
                            fontWeight: "500",
                            backgroundColor: "#3968ed",
                            borderRadius: "5px",
                            border: "none",
                            cursor: "pointer",
                            zIndex: "99999999",
                        }}
                        onClick={() => getOneData(id)}
                    >
                        Edit
                    </button>
                </IndexTable.Cell>
            </IndexTable.Row>
        );
    });

    const [deletedId, setDeletedId] = useState(null);

    const showModelMenu = (id) => {
        setDeletedId(id);
        setShowModelMenuActive(!showModelMenuActive);
    };

    // Plan Name Options
    const [selectedId, setSelectedId] = useState(null);

    // UseState for forms
    const [addSalutationData, setAddSalutationData] = useState({
        name: "",
    });

    const [allModulesArray, setAllModulesArray] = useState([]);
    const [allModules, setAllModules] = useState([]);

    const fetchAllModules = async () => {
        try {
            setLoader(true);
            const response = await fetch(
                //{{development}}/api/v1/admin/channels/list
                `${process.env.REACT_APP_BASE_URL}/api/v1/all/modules`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            const data = await response.json();
            // console.log(data, "data");
            setAllModulesArray(data.data);
            // console.log(data.data.data);
            setLoader(false);
            // console.log(data.rooms);
        } catch (error) {
            console.error(error);
            setLoader(false);
        }
    };

    const setAddSalutationDataHandleChange = (event) => {
        setAddSalutationData({
            ...addSalutationData,
            [event.target.name]: event.target.value,
        });
    };

    const deletedIds = {
        ids: selectedResources,
    };


    const deleteData = async () => {
        console.log(deletedIds);
        const response = await fetch(
            // dev.revcatalyst.in/api/v1/globalAmenities/delete/{id}
            `${process.env.REACT_APP_BASE_URL}/api/v1/salutation/delete`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userToken}`,
                },
                body: JSON.stringify(deletedIds),
            }
        );

        const data = await response.json();
        console.log(data);

        if (data.success) {
            fetchInfo();
            clearSelection();
            setShowLowerMenuMultipleSelect(false);
            setShowModelMenuActive(false);
            showSuccessToast(data.message);
        } else {
            showErrorToast(data.message);
        }
    };

    const handleSubmitAddMeals = async (id) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/salutation/add `,
                {
                    method: "POST",
                    body: JSON.stringify(addSalutationData),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            const data = await response.json();

            if (data.success) {
                fetchInfo(property_id.id);
                clearSelection();
                setShowEditModelMenuActive(false);
                showSuccessToast(data.message);
                setAddSalutationData({ name: '' });
                // setSelectedOptions([]);
            } else {
                showErrorToast(data.message);
            }
        } catch (error) {
            console.error("Async error:", error);
        }
    };

    const updateFunction = async (id) => {
        console.log(id);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/salutation/update/${id}`,
                {
                    method: "POST",
                    body: JSON.stringify({
                        ...addSalutationData,
                        // amenity_icon
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            const data = await response.json();

            if (data.success) {
                fetchInfo(property_id.id);
                clearSelection();
                setShowAddModelMenuActive(false);
                showSuccessToast(data.message);
                // setSelectedOptions([]);
            } else {
                showErrorToast(data.message);
            }
        } catch (error) {
            console.error("Async error:", error);
            // Handle the error as needed
        }
    };

    return (
        <>
            {/* <SidebarInnerLists innerLists={rooms_inner_lists} /> */}
            {loader ? (
                <Loader />
            ) : (
                <>
                    <div className="room-types-full-container">
                        <div className="room-types-full-container-div">
                            <div className="room-type-heading">Salutation</div>
                            <div className="flex flex_gap_10">
                                <BgThemeButton
                                    onClick={() => setShowEditModelMenuActive(true)}
                                    children={"Add"}
                                />
                            </div>
                        </div>

                        <LegacyCard>
                            <IndexTable
                                resourceName={resourceName}
                                itemCount={orders.length}
                                selectedItemsCount={
                                    allResourcesSelected ? "All" : selectedResources.length
                                }
                                onSelectionChange={handleSelectionChange}
                                headings={[
                                    { title: "Salutation" },
                                    { title: "" },
                                    // { title: "" },
                                ]}
                            >
                                {rowMarkup}
                            </IndexTable>

                            {/* Multiple select */}
                            {showLowerMenuMultipleSelect && (
                                <div className=" room-type-bottom-container-center">
                                    <div className="room-type-bottom-container-div multipleroom-delete-btn-div">
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "100%",
                                            }}
                                        >
                                            <Button plain onClick={showModelMenu}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        gap: "5px",
                                                    }}
                                                >
                                                    <Icon destructive source={DeleteMajor} color="base" />
                                                    Delete
                                                </div>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Edit Button Click */}
                            {showAddModelMenuActive && (
                                <>
                                    <div className="bg-backdrop">
                                        <form
                                            onSubmit={(e) => e.preventDefault()}
                                            style={{ width: "400px" }}
                                            className="stock-add-model-container"
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    marginBottom: "15px",
                                                }}
                                            >
                                                <div className="add-ota-model-container-heading">
                                                    Edit
                                                </div>
                                                <div
                                                    onClick={() => setShowAddModelMenuActive(false)}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <Icon source={CancelMajor} color="base" />
                                                </div>
                                            </div>
                                            <div className="form-container-div-half">
                                                <div className="form-container-div">
                                                    <VerticalInputFieldCustom
                                                        required={true}
                                                        titleName={"Salutation"}
                                                        name="name"
                                                        type="text"
                                                        value={addSalutationData.name}
                                                        onChange={setAddSalutationDataHandleChange}
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    gap: "10px",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "start",
                                                        gap: "10px",
                                                    }}
                                                >
                                                    <BgThemeButton
                                                        onClick={(e) => updateFunction(selectedId)}
                                                        type="submit"
                                                        children={"Update"}
                                                    />
                                                    <Button
                                                        onClick={() => [
                                                            setShowAddModelMenuActive(false),
                                                            setAddSalutationData({}),
                                                        ]}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </>
                            )}

                            {showEditModelMenuActive && (
                                <>
                                    <div className="bg-backdrop">
                                        <div
                                            className="stock-add-model-container"
                                            style={{ width: "400px" }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <div className="add-ota-model-container-heading">
                                                    Add Salutation
                                                </div>
                                                <div
                                                    onClick={() => setShowEditModelMenuActive(false)}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    <Icon source={CancelMajor} color="base" />
                                                </div>
                                            </div>
                                            <div style={{
                                                margin: "10px 0",
                                            }}>
                                                <VerticalInputFieldCustom
                                                    className="inputDynamicPreviewBill"
                                                    type="text"
                                                    name="name"
                                                    style={{ width: "100%" }}
                                                    value={addSalutationData.name}
                                                    onChange={setAddSalutationDataHandleChange}
                                                />
                                            </div>
                                            <BgThemeButton onClick={handleSubmitAddMeals}>
                                                Add
                                            </BgThemeButton>
                                        </div>
                                    </div>
                                </>
                            )}
                        </LegacyCard>
                    </div>

                    {showModelMenuActive && (
                        <>
                            <div className="bg-backdrop">
                                <section className="room-type-model-container ">
                                    <h4>Are You Sure?</h4>
                                    <p>You Want To Remove?</p>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: "10px",
                                        }}
                                    >
                                        <Button
                                            onClick={() =>
                                                setShowModelMenuActive(!showModelMenuActive)
                                            }
                                        >
                                            Discard
                                        </Button>
                                        <Button onClick={deleteData} destructive>
                                            Yes, Remove
                                        </Button>
                                    </div>
                                </section>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );

};
export default Salutation;
