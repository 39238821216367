import React, { useCallback, useState } from "react";

import {
  Button,
  Collapsible,
  DropZone,
  Icon,
  LegacyCard,
  LegacyStack,
  Select,
  TextField,
  Text,
  Thumbnail,
  ButtonGroup,
  Page,
  Badge,
  Tag,
} from "@shopify/polaris";
import { ChevronDownMinor, NoteMinor } from "@shopify/polaris-icons";
import { Link, useNavigate } from "react-router-dom";
import { BgOutlineThemeButton, BgThemeButton } from "./UI/Buttons";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import VerticalTextAreaCustom from "./CustomComponents/VerticalTextAreaCustom";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";

const AddCustomerProfile = () => {
  const [files, setFiles] = useState([]);
  const [value, setValue] = useState("Jaded Pixel");
  const [selected, setSelected] = useState("today");

  // Token
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const handleChange = useCallback((newValue) => setValue(newValue), []);

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const [formErrors, setFormErrors] = useState({});

  // Validate function
  const validate = (values) => {
    const errors = {};
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneNumberRegex = /^([+]\d{2})?\d{10}$/;
    const pincodeRegex = /^[1-9][0-9]{5}$/;

    if (!values.billing_email) {
      // errors.property_email = "Email is required!";
    } else {
      if (emailRegex.test(values.billing_email)) {
        errors.billing_email = "";
      } else {
        errors.billing_email = "This is not a valid email format!";
      }
    }

    if (!values.billing_phone) {
      errors.billing_phone = "Phone number is required!";
    } else {
      if (phoneNumberRegex.test(values.billing_phone)) {
        errors.billing_phone = "";
      } else {
        errors.billing_phone = "This is not a valid Phone Number!";
      }
    }

    if (!values.billing_pincode) {
      errors.billing_pincode = "Pincode is required!";
    } else {
      if (pincodeRegex.test(values.billing_pincode)) {
        errors.billing_pincode = "";
      } else {
        errors.billing_pincode = "This is not a valid Pin Code";
      }
    }

    return errors;
  };

  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(true);
  const [open4, setOpen4] = useState(true);
  const [open5, setOpen5] = useState(true);
  const [open6, setOpen6] = useState(true);
  const [open7, setOpen7] = useState(true);
  const [open8, setOpen8] = useState(true);
  const [open9, setOpen9] = useState(true);

  const handleToggle1 = useCallback(() => setOpen1((open1) => !open1), []);
  const handleToggle2 = useCallback(() => setOpen2((open2) => !open2), []);
  const handleToggle3 = useCallback(() => setOpen3((open3) => !open3), []);
  const handleToggle4 = useCallback(() => setOpen4((open4) => !open4), []);
  const handleToggle5 = useCallback(() => setOpen5((open5) => !open5), []);
  const handleToggle6 = useCallback(() => setOpen6((open6) => !open6), []);
  const handleToggle7 = useCallback(() => setOpen7((open7) => !open7), []);
  const handleToggle8 = useCallback(() => setOpen8((open8) => !open8), []);
  const handleToggle9 = useCallback(() => setOpen9((open9) => !open9), []);

  // upload files
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

  const fileUpload = !files.length && (
    <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />
  );

  const uploadedFiles = files.length > 0 && (
    <LegacyStack vertical>
      {files.map((file, index) => (
        <LegacyStack alignment="center" key={index}>
          <Thumbnail
            size="small"
            alt={file.name}
            source={
              validImageTypes.includes(file.type)
                ? window.URL.createObjectURL(file)
                : NoteMinor
            }
          />
          <div>
            {file.name}{" "}
            <Text variant="bodySm" as="p">
              {file.size} bytes
            </Text>
          </div>
        </LegacyStack>
      ))}
    </LegacyStack>
  );

  // For status options
  const [selectedOption, setSelectedOption] = useState("today");

  const handleSelectStatusChange = useCallback(
    (value) => setSelectedOption(value),
    []
  );

  // for Tag

  const [selectedTags, setSelectedTags] = useState([
    "Rustic",
    "Antique",
    "Vinyl",
    "Refurbished",
    "Singh",
  ]);
  const removeTag = useCallback(
    (tag) => () => {
      setSelectedTags((previousTags) =>
        previousTags.filter((previousTag) => previousTag !== tag)
      );
    },
    []
  );
  const tagMarkup = selectedTags.map((option) => (
    <Tag key={option} onRemove={removeTag(option)}>
      {option}
    </Tag>
  ));

  const navigate = useNavigate();

  const [addCustomerData, setAddCustomerData] = useState({
    account_name: "",
    billing_email: "",
    billing_phone: "",
    company_name: "",
    company_gst: "",
    billing_address: "",
    billing_city: "",
    billing_state: "",
    billing_pincode: "",
    billing_country: "",
    property_limits: "",
  });

  const statusOptions = [
    { label: "Select Option", value: "" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];

  // Onchange Function
  const setAddItemDataHandleChange = (event) => {
    setAddCustomerData({
      ...addCustomerData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitAddRoomType = (event) => {
    console.log(addCustomerData);
    event.preventDefault();

    const errors = validate(addCustomerData);
    setFormErrors(errors);

    const hasErrors = Object.values(errors).some((error) => !!error);

    console.log(hasErrors, "hasErrors");
    if (hasErrors === true) {
      showErrorToast("Please check the above errors.");
      return;
    }
    // http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/properties/store
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/customer/create`, {
      method: "POST",
      body: JSON.stringify({ ...addCustomerData }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if (data.success) {
        // setLoader(false);
        showSuccessToast(data.message);
        navigate("/customers");
        // setRevPlans(data.data.data);
      } else if (data.error) {
        const firstErrorKey = Object.keys(data.error)[0];
        const firstErrorMessage = data.error[firstErrorKey][0];
        showErrorToast(firstErrorMessage);
        // setLoader(false);
      } else {
        const firstErrorKey = Object.keys(data.error)[0];
        const firstErrorMessage = data.error[firstErrorKey][0];
        showErrorToast(firstErrorMessage);
        // showErrorToast("Something went wrong....!!!");
        // setLoader(false);
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      showErrorToast("An error occurred while processing your request.");
      // setLoader(false);
    });
  };

  return (
    <>
      <form
        onSubmit={handleSubmitAddRoomType}
        className="edit-rooms-full-container"
      >
        <div>
          <Page
            backAction={{ content: "Products", url: `/customers` }}
            title="Add Customer"
            compactTitle
          >
            <div className="edit-rooms-full-container-div">
              <div className="edit-rooms-full-container-left">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                    width: "591px",
                  }}
                >
                  <div>
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={handleToggle1}
                          ariaExpanded={open1}
                          ariaControls="basic-collapsible"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          <div>Contact Person</div>
                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>
                        <Collapsible
                          open={open1}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          <div className="edit-rooms-form-wrapper-container">
                            <div className="edit-rooms-form-container">
                              <div className="form-container-div-full">
                                <VerticalInputFieldCustom
                                  error={formErrors.account_name ? true : false}
                                  required={true}
                                  focused={false}
                                  name="account_name"
                                  titleName="Name"
                                  onChange={setAddItemDataHandleChange}
                                  autoComplete="off"
                                  value={addCustomerData.account_name}
                                />
                                {formErrors.account_name && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "start",
                                      gap: "5px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0_1_301)">
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM6.3 4.2C6.3 3.8134 6.6134 3.5 7 3.5C7.3866 3.5 7.7 3.8134 7.7 4.2V7C7.7 7.3866 7.3866 7.7 7 7.7C6.6134 7.7 6.3 7.3866 6.3 7V4.2ZM7 10.5C7.3866 10.5 7.7 10.1866 7.7 9.8C7.7 9.4134 7.3866 9.1 7 9.1C6.6134 9.1 6.3 9.4134 6.3 9.8C6.3 10.1866 6.6134 10.5 7 10.5Z"
                                            fill="#E03838"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_1_301">
                                            <rect
                                              width="14"
                                              height="14"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </div>
                                    <p style={{ color: "red" }}>
                                      {formErrors.account_name}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="form-container-div-half">
                              <div className="form-container-div">
                                <VerticalInputFieldCustom
                                  error={
                                    formErrors.billing_phone ? true : false
                                  }
                                  type={"number"}
                                  required={true}
                                  focused={false}
                                  name="billing_phone"
                                  titleName="Phone No"
                                  onChange={setAddItemDataHandleChange}
                                  autoComplete="off"
                                  value={addCustomerData.billing_phone}
                                />
                                {formErrors.billing_phone && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "start",
                                      gap: "5px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0_1_301)">
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM6.3 4.2C6.3 3.8134 6.6134 3.5 7 3.5C7.3866 3.5 7.7 3.8134 7.7 4.2V7C7.7 7.3866 7.3866 7.7 7 7.7C6.6134 7.7 6.3 7.3866 6.3 7V4.2ZM7 10.5C7.3866 10.5 7.7 10.1866 7.7 9.8C7.7 9.4134 7.3866 9.1 7 9.1C6.6134 9.1 6.3 9.4134 6.3 9.8C6.3 10.1866 6.6134 10.5 7 10.5Z"
                                            fill="#E03838"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_1_301">
                                            <rect
                                              width="14"
                                              height="14"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </div>
                                    <p style={{ color: "red" }}>
                                      {formErrors.billing_phone}
                                    </p>
                                  </div>
                                )}
                              </div>
                              <div className="form-container-div">
                                <VerticalInputFieldCustom
                                  error={
                                    formErrors.billing_email ? true : false
                                  }
                                  required={true}
                                  focused={false}
                                  name="billing_email"
                                  titleName="Email"
                                  onChange={setAddItemDataHandleChange}
                                  autoComplete="off"
                                  value={addCustomerData.billing_email}
                                />
                                {formErrors.billing_email && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "start",
                                      gap: "5px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0_1_301)">
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM6.3 4.2C6.3 3.8134 6.6134 3.5 7 3.5C7.3866 3.5 7.7 3.8134 7.7 4.2V7C7.7 7.3866 7.3866 7.7 7 7.7C6.6134 7.7 6.3 7.3866 6.3 7V4.2ZM7 10.5C7.3866 10.5 7.7 10.1866 7.7 9.8C7.7 9.4134 7.3866 9.1 7 9.1C6.6134 9.1 6.3 9.4134 6.3 9.8C6.3 10.1866 6.6134 10.5 7 10.5Z"
                                            fill="#E03838"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_1_301">
                                            <rect
                                              width="14"
                                              height="14"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </div>
                                    <p style={{ color: "red" }}>
                                      {formErrors.billing_email}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div>

                  <div>
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={handleToggle3}
                          ariaExpanded={open3}
                          ariaControls="basic-collapsible"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          <div>Address</div>
                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>
                        <Collapsible
                          open={open3}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          <div className="edit-rooms-form-wrapper-container">
                            <div className="edit-rooms-form-container">
                              <div className="form-container-div-half">
                                <div className="form-container-div">
                                  <VerticalInputFieldCustom
                                    required={true}
                                    focused={false}
                                    name="billing_city"
                                    titleName="City"
                                    onChange={setAddItemDataHandleChange}
                                    autoComplete="off"
                                    value={addCustomerData.billing_city}
                                  />
                                </div>
                                <div className="form-container-div">
                                  <VerticalInputFieldCustom
                                    required={true}
                                    focused={false}
                                    name="billing_state"
                                    titleName="State"
                                    onChange={setAddItemDataHandleChange}
                                    autoComplete="off"
                                    value={addCustomerData.billing_state}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-container-div-half">
                              <div className="form-container-div">
                                <VerticalInputFieldCustom
                                  required={true}
                                  focused={false}
                                  name="billing_country"
                                  titleName="Country"
                                  onChange={setAddItemDataHandleChange}
                                  autoComplete="off"
                                  value={addCustomerData.billing_country}
                                />
                              </div>
                              <div className="form-container-div">
                                <VerticalInputFieldCustom
                                  error={
                                    formErrors.billing_pincode ? true : false
                                  }
                                  type={"number"}
                                  required={true}
                                  focused={false}
                                  name="billing_pincode"
                                  titleName="Pincode"
                                  onChange={setAddItemDataHandleChange}
                                  autoComplete="off"
                                  value={addCustomerData.billing_pincode}
                                />
                                {formErrors.billing_pincode && (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "start",
                                      gap: "5px",
                                      marginTop: "3px",
                                    }}
                                  >
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                      >
                                        <g clip-path="url(#clip0_1_301)">
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M7 0C3.1402 0 0 3.1402 0 7C0 10.8598 3.1402 14 7 14C10.8598 14 14 10.8598 14 7C14 3.1402 10.8598 0 7 0ZM6.3 4.2C6.3 3.8134 6.6134 3.5 7 3.5C7.3866 3.5 7.7 3.8134 7.7 4.2V7C7.7 7.3866 7.3866 7.7 7 7.7C6.6134 7.7 6.3 7.3866 6.3 7V4.2ZM7 10.5C7.3866 10.5 7.7 10.1866 7.7 9.8C7.7 9.4134 7.3866 9.1 7 9.1C6.6134 9.1 6.3 9.4134 6.3 9.8C6.3 10.1866 6.6134 10.5 7 10.5Z"
                                            fill="#E03838"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_1_301">
                                            <rect
                                              width="14"
                                              height="14"
                                              fill="white"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </div>
                                    <p style={{ color: "red" }}>
                                      {formErrors.billing_pincode}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="form-container-div">
                              <VerticalTextAreaCustom
                                required={true}
                                focused={false}
                                name="billing_address"
                                titleName="Address"
                                onChange={setAddItemDataHandleChange}
                                autoComplete="off"
                                value={addCustomerData.billing_address}
                              />
                            </div>
                          </div>
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div>
                  {/* <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle4}
                      ariaExpanded={open4}
                      ariaControls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Agent Categories</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open4}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-container">
                        <div className="form-container-div-full">
                          <Select
                            className="textfield"
                            focused={false}
                            label="Select Agent Category"
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-container-div-full">
                          <TextField
                            label="Description"
                            onChange={handleChange}
                            multiline={4}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div> */}

                  {/* <div>
              <LegacyCard sectioned>
                <LegacyStack vertical>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontWeight: "700",
                      fontSize: "14px",
                    }}
                  >
                    Media
                    <Button
                      onClick={handleToggle5}
                      ariaExpanded={open5}
                      ariaControls="basic-collapsible"
                    >
                      <Icon source={ChevronDownMinor} />
                    </Button>
                  </div>
                  <Collapsible
                    open={open5}
                    id="basic-collapsible"
                    transition={{
                      duration: "500ms",
                      timingFunction: "ease-in-out",
                    }}
                    expandOnPrint
                  >
                    <DropZone onDrop={handleDropZoneDrop} variableHeight>
                      {uploadedFiles}
                      {fileUpload}
                    </DropZone>
                  </Collapsible>
                </LegacyStack>
              </LegacyCard>
            </div>
            <div>
              <LegacyCard sectioned>
                <LegacyStack vertical>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontWeight: "700",
                      fontSize: "14px",
                    }}
                  >
                    Search Engine Listing
                    <Button
                      onClick={handleToggle6}
                      ariaExpanded={open6}
                      ariaControls="basic-collapsible"
                    >
                      <Icon source={ChevronDownMinor} />
                    </Button>
                  </div>
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#888",
                    }}
                  >
                    Add a title and description to see how this Room might
                    appear
                    <br />
                    in a search engine listing
                  </p>
                  <Collapsible
                    open={open6}
                    id="basic-collapsible"
                    transition={{
                      duration: "500ms",
                      timingFunction: "ease-in-out",
                    }}
                    expandOnPrint
                  >
                    <div className="edit-rooms-form-container">
                      <div className="form-container-div-full">
                        <TextField
                          className="textfield"
                          focused={false}
                          label="Page Title"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-container-div-full">
                        <TextField
                          className="textfield"
                          focused={false}
                          label="Meta Description"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-container-div-full">
                        <TextField
                          className="textfield"
                          focused={false}
                          label="URL Handle"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </Collapsible>
                </LegacyStack>
              </LegacyCard>
            </div> */}
                </div>
                <div className="rooms-edit-left-container-buttons">
                  <div>
                    <BgThemeButton type={"submit"} children={"Add"} />
                  </div>
                  <div>
                    <Link style={{ textDecoration: "none" }} to={"/customers"}>
                      <BgOutlineThemeButton children={"Discard"} />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="edit-rooms-full-container-right">
                <form
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <div>
                    <LegacyCard sectioned>
                      <LegacyStack vertical>
                        <div
                          onClick={handleToggle2}
                          ariaExpanded={open2}
                          ariaControls="basic-collapsible"
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontWeight: "700",
                            fontSize: "14px",
                          }}
                        >
                          <div>Business details</div>
                          <div>
                            <Icon source={ChevronDownMinor} />
                          </div>
                        </div>
                        <Collapsible
                          open={open2}
                          id="basic-collapsible"
                          transition={{
                            duration: "500ms",
                            timingFunction: "ease-in-out",
                          }}
                          expandOnPrint
                        >
                          <div className="edit-rooms-form-wrapper-container">
                            <div className="edit-rooms-form-container">
                              <div className="form-container-div-full">
                                <VerticalInputFieldCustom
                                  required={true}
                                  focused={false}
                                  name="company_name"
                                  titleName="Company Name"
                                  onChange={setAddItemDataHandleChange}
                                  autoComplete="off"
                                  value={addCustomerData.company_name}
                                />
                              </div>
                            </div>
                            <div className="form-container-div-half">
                              <div className="form-container-div">
                                <VerticalInputFieldCustom
                                  required={true}
                                  focused={false}
                                  name="company_gst"
                                  titleName="Gst No"
                                  onChange={setAddItemDataHandleChange}
                                  autoComplete="off"
                                  value={addCustomerData.company_gst}
                                />
                              </div>
                            </div>
                            <div className="form-container-div-half">
                              <div className="form-container-div">
                                <VerticalInputFieldCustom
                                  type={"number"}
                                  required={true}
                                  focused={false}
                                  name="property_limits"
                                  titleName="Property Limit"
                                  onChange={setAddItemDataHandleChange}
                                  autoComplete="off"
                                  value={addCustomerData.property_limits}
                                />
                              </div>
                            </div>
                          </div>
                        </Collapsible>
                      </LegacyStack>
                    </LegacyCard>
                  </div>
                  {/* <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle4}
                      aria_expanded={open4}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Status</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open4}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <VerticalInputSelectCustomCopy
                        required={true}
                        options={statusOptions}
                        name="customer_status"
                        value={addCustomerData.customer_status}
                        focused={false}
                        titleName="Status"
                        onChange={setAddItemDataHandleChange}
                        autoComplete="off"
                      />
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle8}
                      ariaExpanded={open8}
                      ariaControls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Asign Hotel</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open8}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-container">
                        <div className="form-container-div-full">
                          <Select
                            className="textfield"
                            focused={false}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </div>
                        <LegacyStack spacing="tight">{tagMarkup}</LegacyStack>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div> */}
                  {/* <div>
              <LegacyCard sectioned>
                <LegacyStack vertical>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "300px",
                      alignItems: "center",
                      fontWeight: "700",
                      fontSize: "14px",
                    }}
                  >
                    Pricing
                    <Button
                      onClick={handleToggle9}
                      ariaExpanded={open9}
                      ariaControls="basic-collapsible"
                    >
                      <Icon source={ChevronDownMinor} />
                    </Button>
                  </div>
                  <Collapsible
                    open={open9}
                    id="basic-collapsible"
                    transition={{
                      duration: "500ms",
                      timingFunction: "ease-in-out",
                    }}
                    expandOnPrint
                  >
                    <div className="edit-rooms-form-container">
                      <div className="form-container-div-full">
                        <TextField
                          className="textfield"
                          focused={false}
                          label="Rack Price"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-container-div-full">
                        <TextField
                          className="textfield"
                          focused={false}
                          label="Extra Adults"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <TextField
                          className="textfield"
                          focused={false}
                          label="Child with Bed"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-container-div">
                        <TextField
                          className="textfield"
                          focused={false}
                          label="Child Without Bed"
                          onChange={handleChange}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </Collapsible>
                </LegacyStack>
              </LegacyCard>
            </div> */}
                </form>
              </div>
            </div>
          </Page>
        </div>
      </form>
    </>
  );
};

export default AddCustomerProfile;
