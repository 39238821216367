import {
  TextField,
  IndexTable,
  LegacyCard,
  IndexFilters,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Badge,
  Avatar,
  Button,
  Icon,
  Select,
  Page,
} from "@shopify/polaris";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import logo from "../assets/images/room_type.png";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  WithoutBgButtonBlue,
} from "./UI/Buttons";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import Loader from "./UI/Loader/Loader";
import StatusUpdate from "./CustomComponents/StatusUpdate";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SidebarInnerLists from "./CustomComponents/SidebarInnerLists";
import { rooms_inner_lists } from "../assets/constant";
import { checkIsAuthenticatedFalse } from "../reduxToolkit/Slice/isAuthenticatedSlice";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";

const AllAddons = () => {
  const navigate = useNavigate();
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);
  const [showEditModelMenuActive, setShowEditModelMenuActive] = useState(false);

  const [showLowerMenuAddMenu, setShowLowerMenuAddMenu] = useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [syncPopup, setSyncPopup] = useState(false);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [itemStrings, setItemStrings] = useState([
    "All",
    "Unpaid",
    "Open",
    "Closed",
    "Local delivery",
    "Local pickup",
  ]);
  const deleteView = (index) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
  };

  const duplicateView = async (name) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    await sleep(1);
    return true;
  };

  const tabs = itemStrings.map((item, index) => ({
    content: item,
    key: index,
    index,
    onAction: () => {},
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions:
      index === 0
        ? []
        : [
            {
              type: "rename",
              onAction: () => {},
              onPrimaryAction: async (value) => {
                const newItemsStrings = tabs.map((item, idx) => {
                  if (idx === index) {
                    return value;
                  }
                  return item.content;
                });
                await sleep(1);
                setItemStrings(newItemsStrings);
                return true;
              },
            },
            {
              type: "duplicate",
              onPrimaryAction: async (value) => {
                await sleep(1);
                duplicateView(value);
                return true;
              },
            },
            {
              type: "edit",
            },
            {
              type: "delete",
              onPrimaryAction: async () => {
                await sleep(1);
                deleteView(index);
                return true;
              },
            },
          ],
  }));

  const [selected, setSelected] = useState(0);

  const onCreateNewView = async (value) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    return true;
  };

  const sortOptions = [
    { label: "Order", value: "order asc", directionLabel: "Ascending" },
    { label: "Order", value: "order desc", directionLabel: "Descending" },
    { label: "Customer", value: "customer asc", directionLabel: "A-Z" },
    { label: "Customer", value: "customer desc", directionLabel: "Z-A" },
    { label: "Date", value: "date asc", directionLabel: "A-Z" },
    { label: "Date", value: "date desc", directionLabel: "Z-A" },
    { label: "Total", value: "total asc", directionLabel: "Ascending" },
    { label: "Total", value: "total desc", directionLabel: "Descending" },
  ];

  const [sortSelected, setSortSelected] = useState(["order asc"]);
  const { mode, setMode } = useSetIndexFiltersMode();
  const onHandleCancel = () => {};

  const onHandleSave = async () => {
    await sleep(1);
    return true;
  };

  const primaryAction =
    selected === 0
      ? {
          type: "save-as",
          onAction: onCreateNewView,
          disabled: false,
          loading: false,
        }
      : {
          type: "save",
          onAction: onHandleSave,
          disabled: false,
          loading: false,
        };
  const [accountStatus, setAccountStatus] = useState(undefined);
  const [moneySpent, setMoneySpent] = useState(undefined);
  const [taggedWith, setTaggedWith] = useState("");
  const [queryValue, setQueryValue] = useState("");

  const handleAccountStatusChange = useCallback(
    (value) => setAccountStatus(value),
    []
  );
  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );
  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleAccountStatusRemove = useCallback(
    () => setAccountStatus(undefined),
    []
  );
  const handleMoneySpentRemove = useCallback(
    () => setMoneySpent(undefined),
    []
  );
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAccountStatusRemove();
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [
    handleAccountStatusRemove,
    handleMoneySpentRemove,
    handleQueryValueRemove,
    handleTaggedWithRemove,
  ]);

  const filters = [
    {
      key: "accountStatus",
      label: "Account status",
      filter: (
        <ChoiceList
          title="Account status"
          titleHidden
          choices={[
            { label: "Enabled", value: "enabled" },
            { label: "Not invited", value: "not invited" },
            { label: "Invited", value: "invited" },
            { label: "Declined", value: "declined" },
          ]}
          selected={accountStatus || []}
          onChange={handleAccountStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "taggedWith",
      label: "Tagged with",
      filter: (
        <TextField
          label="Tagged with"
          value={taggedWith}
          onChange={handleTaggedWithChange}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    },
    {
      key: "moneySpent",
      label: "Money spent",
      filter: (
        <RangeSlider
          label="Money spent is between"
          labelHidden
          value={moneySpent || [0, 500]}
          prefix="$"
          output
          min={0}
          max={2000}
          step={1}
          onChange={handleMoneySpentChange}
        />
      ),
    },
  ];

  const appliedFilters = [];

  if (accountStatus && !isEmpty(accountStatus)) {
    const key = "accountStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, accountStatus),
      onRemove: handleAccountStatusRemove,
    });
  }

  if (moneySpent) {
    const key = "moneySpent";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }

  if (!isEmpty(taggedWith)) {
    const key = "taggedWith";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  const booked = (
    <div
      style={{
        color: "#E03838",
        padding: "6px",
        backgroundColor: "#FFEAEA",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
      }}
    >
      Booked
    </div>
  );

  const available = (
    <div
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
      }}
    >
      Available
    </div>
  );

  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();
  // fetch data
  const [btnStatus, setButtonStatus] = useState([]);

  const property_id = useSelector((state) => state.property.value);

  const [loader, setLoader] = useState(true);

  const fetchInfo = async () => {
    // console.log(id, "propertyId");
    // console.log(userToken.token, "token");
    // if (!id) {
    //   navigate("/login");
    // }
    try {
      const response = await fetch(
        // {{live}}/api/v1/admin/plans/list
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/properties`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data.properties);
    } catch (error) {
      console.error(error);
    }
  };

  const [fetchedRoomType, setFetchedRoomType] = useState([]);

  const fetchRoomType = async (id) => {
    if (!id) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setFetchedRoomType(data.rooms);
    } catch (error) {
      console.error(error + "sdfghjkl;");
    }
  };

  useEffect(() => {
    // if (property_id) {
    fetchInfo();
    fetchAllModules();
    // fetchRoomType(property_id?.id);
    // }
  }, []);

  setTimeout(() => {
    setLoader(false);
  }, 1000);

  // let { selectedResources, allResourcesSelected, handleSelectionChange } =
  //   useIndexResourceState(orders);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(orders);

  const removeSingle = () => {
    setOrders((current) =>
      current.filter((uniqueRoom) => {
        return uniqueRoom.id !== selectedResources[0];
      }, clearSelection())
    );
  };

  const removeMultiple = () => {
    selectedResources.map((item) => {
      setOrders((current) =>
        current.filter((uniqueRoom) => {
          return uniqueRoom.id !== item;
        }, clearSelection())
      );
    });
  };

  const selectedIndex = () => {
    selectedResources = [];
  };

  useEffect(() => {
    if (selectedResources.length === 1) {
      // getOneData(selectedResources[0]);

      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuAddMenu(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuAddMenu(false);
    } else {
      // setAddItemData({
      //   room_name: "",
      //   room_no: "",
      //   floor_no: "",
      //   status: "booked",
      //   room_id: fetchedRoomType ? fetchedRoomType[0]?.id : "",

      //   property_id: property_id.id,
      // });
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuAddMenu(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const addButtonClickHandler = () => {
    setShowLowerMenuSingleSelect(false);

    setShowLowerMenuMultipleSelect(false);
    setShowLowerMenuAddMenu(!showLowerMenuAddMenu);
  };

  // getItemChecked(selectedResources[0]);
  const newSelectedResources = [];
  const showId = (id) => {
    newSelectedResources.push(id);
  };

  const getOneData = async (id) => {
    setSelectedId(id);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit/plan/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.success) {
        setAddItemData(data.data);
        //

        const transformedArray = data.data.features.map((item) => ({
          id: item.module_id,
          limit: parseInt(item.plan_limit),
          selected: true,
          module_name: item.module.module_name,
        }));

        console.log(transformedArray, "transformedArray");
        setAllModules(transformedArray);

        setShowAddModelMenuActive(true);
        return;
      } else {
        showErrorToast("Something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [bookedBtn, setBookedBtn] = useState(booked);

  const rowMarkup = orders?.map(({ id, property_name, name, price, index }) => {
    return (
      <IndexTable.Row
        id={id}
        key={index}
        selected={selectedResources.includes(id)}
        position={id}
      >
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={""}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={property_name}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <CustomCssComponentTableText
            innerText={price}
          ></CustomCssComponentTableText>
        </IndexTable.Cell>
        <IndexTable.Cell>
          {/* <button
            style={{
              padding: "5px",
              fontSize: "14px",
              color: "white",
              fontWeight: "500",
              backgroundColor: "#3968ed",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              zIndex: "99999999",
            }}
            onClick={() => getOneData(id)}
          >
            Edit
          </button> */}
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  const [deletedId, setDeletedId] = useState(null);

  const showModelMenu = (id) => {
    setDeletedId(id);
    setShowModelMenuActive(!showModelMenuActive);
  };

  const removeItemConfirm = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  // Plan Name Options
  const [selectedId, setSelectedId] = useState(null);

  const planStatusOptions = [
    { label: "Booked", value: "booked", name: "Booked" },
    {
      label: "Available",
      value: "available",
      name: "Available",
    },
  ];

  const [planStatusSelected, setPlanStatusSelected] = useState("Booked");
  const planStatusHandleSelectChange = useCallback(
    (value) => setPlanStatusSelected(value),
    []
  );

  const roomTypeOptions = fetchedRoomType.map((roomType) => {
    return { label: roomType.room_name, value: roomType.id };
  });

  const [roomTypeSelected, setRoomTypeSelected] = useState("EP");
  const roomTypeHandleSelectChange = useCallback(
    (value) => setRoomTypeSelected(value),
    []
  );

  const handleSubmit = useCallback((e) => {}, []);

  const statusOptions = [
    { label: "Booked", value: "booked" },
    { label: "Available", value: "available" },
  ];

  // UseState for forms
  const [addItemData, setAddItemData] = useState({
    name: "",
    price: "",
    property_id: property_id?.id,
  });

  const [allModulesArray, setAllModulesArray] = useState([]);
  const [allModules, setAllModules] = useState([]);

  const fetchAllModules = async () => {
    try {
      setLoader(true);
      const response = await fetch(
        //{{development}}/api/v1/admin/channels/list
        `${process.env.REACT_APP_BASE_URL}/api/v1/all/modules`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      // console.log(data, "data");
      setAllModulesArray(data.data);
      // console.log(data.data.data);
      setLoader(false);
      // console.log(data.rooms);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const setAddItemDataHandleChange = (event) => {
    setAddItemData({
      ...addItemData,
      [event.target.name]: event.target.value,
    });
  };
  // console.log(addItemData);

  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/delete/plan`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ id: deletedId }),
      }
    );

    const data = await response.json();
    console.log(data);

    if (data.success) {
      fetchInfo();
      clearSelection();
      setShowLowerMenuMultipleSelect(false);
      setShowModelMenuActive(false);
    } else {
      showErrorToast(data.message);
    }
    // now do whatever you want with the data
    // console.log(data);
    // fetchInfo();
    // clearSelection();
    // setShowLowerMenuMultipleSelect(false);
    // setShowModelMenuActive(false);
  };

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCheckboxChange = (e, index, id) => {
    const checked = e.target.checked;
    setAllModules((prevModules) => {
      const newModules = [...prevModules];
      console.log(newModules, "newModules");

      if (!newModules[index]) {
        newModules[index] = {
          id: id,
          selected: checked,
          limit: null,
        };
        newModules[index].selected = checked;
        newModules[index].limit = null;
      } else {
        newModules[index].selected = checked;
        newModules[index].limit = null;
      }

      return newModules;
    });
  };

  const handleLimitChange = (e, index) => {
    const limitValue = e.target.value;
    setAllModules((prevModules) => {
      const newModules = [...prevModules];
      newModules[index].limit = limitValue;
      return newModules;
    });
  };

  const selectedModules = allModules.filter((module) => {
    return (
      module.selected === true &&
      module.limit > 0 &&
      module.limit === null &&
      module.limit === ""
    );
  });

  const handleSubmitAddMeals = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/create/plan`,
        {
          method: "POST",
          body: JSON.stringify({
            ...addItemData,
            // show_online: 1,
            modules: allModules,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.success) {
        fetchInfo(property_id.id);
        clearSelection();
        setShowEditModelMenuActive(false);
        showSuccessToast(data.message);
        setSelectedOptions([]);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Async error:", error);
      // Handle the error as needed
    }
  };

  const updateFunction = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/update/plan/${id}`,
        {
          method: "POST",
          body: JSON.stringify({
            ...addItemData,
            // show_online: 1,
            modules: allModules,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.success) {
        fetchInfo(property_id.id);
        clearSelection();
        setShowAddModelMenuActive(false);
        showSuccessToast(data.message);
        setSelectedOptions([]);
      } else {
        showErrorToast(data.message);
      }
    } catch (error) {
      console.error("Async error:", error);
      // Handle the error as needed
    }
  };

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
    clearSelection();
  };

  // const [revPlans, setRevPlans] = useState([]);
  // const fetchPlans = async () => {
  //   try {
  //     const response = await fetch(
  //       // {{live}}/api/v1/admin/plans/list
  //       `${process.env.REACT_APP_BASE_URL}/api/v1/admin/plans/list`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${userToken}`,
  //         },
  //       }
  //     );
  //     const data = await response.json();
  //     setRevPlans(data.data.data);
  //     if (data.message === "Unauthorised.") {
  //       navigate("/");
  //     }
  //     // if (data?.properties?.length > 0) {
  //     //   if (selectedProperty) {
  //     //   } else {
  //     //     setSelectedHotelOption(data.properties[0]);
  //     //     setIsFetching(false);
  //     //   }
  //     // } else if (data.properties.length === 0) {
  //     //   navigate("/nopropertypage");
  //     //   // localStorage.clear();
  //     // }

  //     // if (allProperties === 0) {
  //     //   navigate("/nopropertypage");
  //     // }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const currentDate = new Date().toISOString().split("T")[0];

  const [asignOwnerDate, setAsignOwnerDate] = useState({
    name: "",
    email: "",
    property_id: "",
  });

  const [syncOwnerData, setSyncOwnerData] = useState({
    startDate: "",
    endDate: "",
    property_id: "",
    sync_type: "",
  });

  const setAddItemDataHandleChangeSync = (event) => {
    setSyncOwnerData({
      ...syncOwnerData,
      [event.target.name]: event.target.value,
    });
  };

  // const handleAsignOwnerDataSync = (event) => {
  //   event.preventDefault();
  //   // /api/v1/admin/property/assign
  //   console.log(asignOwnerDate);
  //   fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/hotel-center/push/rates`, {
  //     method: "POST",
  //     body: JSON.stringify({
  //       hotelIdArray: selectedResources,
  //       syncType: syncOwnerData.sync_type,
  //       startDate: syncOwnerData.startDate,
  //       endDate: syncOwnerData.endDate,
  //       hotelCenterCode: 1149580311,
  //     }),
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${userToken}`,
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data);

  //       if (data.success) {
  //         fetchInfo();
  //         showSuccessToast(data.message);

  //         navigate("/customers");
  //       } else {
  //         showErrorToast("Please enter details carefully");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error:", error);
  //       showErrorToast("An error occurred while processing your request.");
  //     });
  // };

  const handleAsignOwnerDataSync = (event) => {
    event.preventDefault();
    // /api/v1/admin/property/assign
    console.log(asignOwnerDate);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/hotel-center/push/property-data`,
      {
        method: "POST",
        body: JSON.stringify({
          hotelId: syncOwnerData.property_id,
          // startDate: syncOwnerData.startDate,
          // endDate: syncOwnerData.endDate,
          partnerCode: "revcatalyst_technologies_pvt_ltd_1149580311",
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);

        if (data.success) {
          fetchInfo();
          showSuccessToast(data.message);

          navigate("/customers");
        } else {
          showErrorToast("Please enter details carefully");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const syncTypeOptions = [
    {
      label: "Property Rate",
      value: "property_rate",
    },
    {
      label: "Property Data",
      value: "property_data",
    },
    {
      label: "Inventory & Availability",
      value: "inventory_availability",
    },
  ];

  // dynamic inputs
  const [dynamicInputList, setDynamicInputList] = useState([
    {
      id: 1,
      select_add_on: "",
      add_on_cost: "",
    },
  ]);

  const handleAddClick = (id) => {
    const lastItem = dynamicInputList[dynamicInputList.length - 1];
    if (
      (lastItem && lastItem.select_add_on === "") ||
      lastItem.add_on_cost === "" ||
      lastItem.add_on_cost === " "
    ) {
      showErrorToast("Please add the above fields first...!!");
    } else {
      setDynamicInputList([
        ...dynamicInputList,
        {
          id: id + 1,
          select_add_on: "",
          add_on_cost: "",
        },
      ]);
    }
  };

  const handleRemoveClick = (idToRemove) => {
    const updatedList = dynamicInputList.filter(
      (item) => item.id !== idToRemove
    );
    setDynamicInputList(updatedList);
  };

  const dynamicPreviewBillHandleChange = (e, index) => {
    const { name, value } = e.target;

    const isDuplicate = dynamicInputList.some(
      (item, i) =>
        i !== index && item.select_add_on === value && name === "select_add_on"
    );

    if (isDuplicate) {
      showErrorToast("Duplicate entry.");
      return;
    }

    setDynamicInputList((prevInputList) => {
      const updatedInputList = [...prevInputList];
      updatedInputList[index][name] = value;
      return updatedInputList;
    });
  };

  return (
    <>
      {/* <SidebarInnerLists innerLists={rooms_inner_lists} /> */}
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div className="room-type-heading">Addons</div>
              <div className="flex flex_gap_10">
                <BgThemeButton
                  onClick={() => setShowEditModelMenuActive(true)}
                  children={"Add"}
                />
                {/* <BgThemeButton
                  onClick={() => setSyncPopup(true)}
                  children={"Sync"}
                /> */}
              </div>
            </div>

            <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={orders.length}
                selectedItemsCount={
                  allResourcesSelected ? "All" : selectedResources.length
                }
                onSelectionChange={handleSelectionChange}
                headings={[
                  { title: "" },
                  { title: "Property Name" },
                  { title: "" },
                ]}
              >
                {rowMarkup}
              </IndexTable>

              {/* Multiple select */}
              {showLowerMenuMultipleSelect && (
                <div className=" room-type-bottom-container-center">
                  <div className="room-type-bottom-container-div multipleroom-delete-btn-div">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Button plain onClick={() => setSyncPopup(true)}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          {/* <Icon source={RefreshIcon} color="base" /> */}
                          Sync
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              )}

              {/* Edit Button Click */}
              {showAddModelMenuActive && (
                <>
                  <div className="bg-backdrop">
                    <form
                      onSubmit={(e) => e.preventDefault()}
                      style={{ width: "700px" }}
                      className="stock-add-model-container"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "15px",
                        }}
                      >
                        <div className="add-ota-model-container-heading">
                          Edit Plans
                        </div>
                        <div
                          onClick={() => setShowAddModelMenuActive(false)}
                          style={{ cursor: "pointer" }}
                        >
                          <Icon source={CancelMajor} color="base" />
                        </div>
                      </div>
                      <div className="form-container-div-half">
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            required={true}
                            titleName={"Plan Name"}
                            name="name"
                            type="text"
                            value={addItemData.name}
                            onChange={setAddItemDataHandleChange}
                          />
                        </div>
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            required={true}
                            titleName={"Plan Price"}
                            name="price"
                            type="number"
                            value={+addItemData.price}
                            onChange={setAddItemDataHandleChange}
                          />
                        </div>
                        {/* <div className="form-container-div">
                          <VerticalInputFieldCustom
                            // required={true}
                            titleName={"Limit"}
                            name="limit"
                            type="number"
                            value={addItemData.limit}
                            onChange={setAddItemDataHandleChange}
                          />
                        </div> */}
                      </div>
                      <hr />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            gap: "10px",
                          }}
                        >
                          <BgThemeButton
                            onClick={(e) => updateFunction(selectedId)}
                            type="submit"
                            children={"Update"}
                          />
                          <Button
                            onClick={() => [
                              setShowAddModelMenuActive(false),
                              setAllModules([]),
                              setAddItemData({}),
                            ]}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
              {/* Edit Button Click */}
              {syncPopup && (
                <>
                  <div className="bg-backdrop">
                    <form
                      onSubmit={(e) => handleAsignOwnerDataSync(e)}
                      style={{ width: "700px" }}
                      className="stock-add-model-container"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "15px",
                        }}
                      >
                        <div className="add-ota-model-container-heading">
                          Sync
                        </div>
                        <div
                          onClick={() => setSyncPopup(false)}
                          style={{ cursor: "pointer" }}
                        >
                          <Icon source={CancelMajor} color="base" />
                        </div>
                      </div>

                      <div className="form-container-div-half">
                        {/* <div className="form-container-div">
                    <VerticalInputSelectCustomCopy
                      required={true}
                      options={roomTypeOptions}
                      titleName="Select Property"
                      name="property_id"
                      value={syncOwnerData.property_id}
                      onChange={setAddItemDataHandleChangeSync}
                    />
                  </div> */}
                        <div className="form-container-div">
                          <VerticalInputSelectCustomCopy
                            options={syncTypeOptions}
                            required={true}
                            titleName="Sync Type"
                            name="sync_type"
                            value={syncOwnerData.sync_type}
                            onChange={setAddItemDataHandleChangeSync}
                            min={currentDate}
                          />
                        </div>
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            required={true}
                            titleName="Start Date"
                            name="startDate"
                            type="date"
                            value={syncOwnerData.startDate}
                            onChange={setAddItemDataHandleChangeSync}
                            min={currentDate}
                          />
                        </div>
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            required={true}
                            titleName="End Date"
                            name="endDate"
                            type="date"
                            value={syncOwnerData.endDate}
                            onChange={setAddItemDataHandleChangeSync}
                            min={syncOwnerData.startDate}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            gap: "10px",
                          }}
                        >
                          {syncOwnerData.startDate &&
                          syncOwnerData.endDate &&
                          syncOwnerData.sync_type ? (
                            <BgThemeButton type="submit" children={"Sync"} />
                          ) : (
                            <BgThemeButtonDisabled
                              type="submit"
                              children={"Sync"}
                            />
                          )}
                          <Button onClick={() => setSyncPopup(false)}>
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}

              {showEditModelMenuActive && (
                <>
                  <div className="bg-backdrop">
                    <div
                      className="stock-add-model-container"
                      style={{ width: "400px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "15px",
                        }}
                      >
                        <div className="add-ota-model-container-heading">
                          Sync
                        </div>
                        <div
                          onClick={() => setSyncPopup(false)}
                          style={{ cursor: "pointer" }}
                        >
                          <Icon source={CancelMajor} color="base" />
                        </div>
                      </div>
                      <div className="form-container-div-half">
                        <table
                          style={{
                            backgroundColor: "white",
                            padding: "0 10px 20px 0px",
                            borderCollapse: "collapse",
                            borderRadius: "10px",
                            fontFamily: "Inter",
                            width: "100%",
                          }}
                        >
                          <tr
                            style={{
                              textAlign: "left",
                            }}
                          >
                            <th style={{ padding: "0px" }}>Amenities</th>
                            <th style={{ padding: "0px" }}>Add On Cost</th>
                            {/* <th style={{ padding: "10px" }}>Total Amount</th> */}
                            <th
                              style={{
                                width: "10px",
                                padding: "10px",
                              }}
                            ></th>
                          </tr>

                          {dynamicInputList &&
                            dynamicInputList.map((bill, index) => {
                              return (
                                // <div>{bill.reference}</div>
                                <tr>
                                  <>
                                    <td style={{ paddingRight: "10px" }}>
                                      <VerticalInputFieldCustom
                                        className="inputDynamicPreviewBill"
                                        type="text"
                                        name="select_add_on"
                                        value={
                                          dynamicInputList[index]?.select_add_on
                                        }
                                        style={{ width: "100px" }}
                                        onChange={(e) =>
                                          dynamicPreviewBillHandleChange(
                                            e,
                                            index
                                          )
                                        }
                                      />
                                    </td>
                                    <td>
                                      <VerticalInputFieldCustom
                                        className="inputDynamicPreviewBill"
                                        type="text"
                                        name="add_on_cost"
                                        value={
                                          dynamicInputList[index]?.add_on_cost
                                        }
                                        style={{ width: "100px" }}
                                        onChange={(e) =>
                                          dynamicPreviewBillHandleChange(
                                            e,
                                            index
                                          )
                                        }
                                      />
                                    </td>

                                    <td
                                      style={{
                                        padding: "10px",
                                        width: "10px",
                                      }}
                                    >
                                      {dynamicInputList.length === index + 1 ? (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handleAddClick(bill.id)
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                          >
                                            <g clipPath="url(#clip0_1270_978)">
                                              <path
                                                d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                                stroke="#3968ED"
                                              />
                                              <path
                                                d="M10.5866 7H3.41333"
                                                stroke="#3968ED"
                                                strokeMiterlimit="10"
                                              />
                                              <path
                                                d="M7 3.41344L7 10.5867"
                                                stroke="#3968ED"
                                                strokeMiterlimit="10"
                                              />
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_1270_978">
                                                <rect
                                                  width="14"
                                                  height="14"
                                                  fill="white"
                                                />
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        </div>
                                      ) : (
                                        <div
                                          onClick={() =>
                                            handleRemoveClick(bill.id)
                                          }
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                          >
                                            <g clipPath="url(#clip0_1270_876)">
                                              <path
                                                d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                                stroke="#3968ED"
                                              />
                                              <path
                                                d="M10.5866 7H3.41333"
                                                stroke="#3968ED"
                                                strokeMiterlimit="10"
                                              />
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_1270_876">
                                                <rect
                                                  width="14"
                                                  height="14"
                                                  fill="white"
                                                />
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        </div>
                                      )}
                                    </td>
                                  </>
                                </tr>
                              );
                            })}
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </LegacyCard>
          </div>

          {showModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-type-model-container ">
                  <h4>Are You Sure?</h4>
                  <p>You Want To Remove?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        setShowModelMenuActive(!showModelMenuActive)
                      }
                    >
                      Discard
                    </Button>
                    <Button onClick={deleteData} destructive>
                      Yes, Remove
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}
        </>
      )}
    </>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "moneySpent":
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case "taggedWith":
        return `Tagged with ${value}`;
      case "accountStatus":
        return value.map((val) => `Customer ${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};
export default AllAddons;
