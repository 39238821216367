import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isAuthenticatedSlice: "",
}
export const isAuthenticatedSlice = createSlice({
    name: 'isAuthenticated',
    initialState,
    reducers: {
        checkIsAuthenticatedTrue: (state, action) => {
            state.value = action.payload;
            localStorage.setItem('authenticated', JSON.stringify(action.payload));
            console.log(action.payload);
        },
        checkIsAuthenticatedFalse: (state, action) => {
            state.value = action.payload;
            localStorage.setItem('authenticated', JSON.stringify(action.payload));
            console.log(action.payload);
        },
        clearIsAuthenticated: (state, action) => {
            state.value = action.payload;
            localStorage.removeItem('authenticated');
            console.log(action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const { checkIsAuthenticatedTrue, checkIsAuthenticatedFalse, clearIsAuthenticated } = isAuthenticatedSlice.actions;

export default isAuthenticatedSlice.reducer;