import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BgOutlineThemeButton, BgThemeButton } from "./UI/Buttons";
import Loader from "./UI/Loader/Loader";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import { Page } from "@shopify/polaris";

const LowRateInventory = () => {
  const [purchaseHeadingActiveIndex, setPurchaseHeadingActiveIndex] =
    useState(1);
  const [purchaseHeadingActive, setPurchaseHeadingActive] = useState("");

  const setPurchaseHeading = (idx, param) => {
    setPurchaseHeadingActiveIndex(idx);
    setFillingState(param);

    fetchInfo();
    if (purchaseHeadingActive === idx) {
      console.log(param);

      setPurchaseHeadingActive("active");
    } else {
      setPurchaseHeadingActive("");
    }
  };
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);

  const [fillingState, setFillingState] = useState("fast_filling");
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  console.log(fillingState);
  // FETCH DATA
  // "http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/inventories/99eafc59-0b26-49fa-9fc5-c0bf4a02cb01"
  // https://app.revcatalyst.in/api/v1/inventories/9a033594-8ceb-4560-93c4-dea6c754222e?inventoryType=fast_filling%27
  const fetchInfo = async (fillingState) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/inventories/9a033594-8ceb-4560-93c4-dea6c754222e?inventoryType=${fillingState}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      console.log(data, "data");
      setData(data);
    } catch (error) {
      console.error(error);
    }
  };

  //   console.log(loader);
  useEffect(() => {
    fetchInfo();
  }, []);

  setTimeout(() => {
    setLoader(false);
  }, 100);

  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  // console.log(data);
  const dummyRoomPlansName = [
    {
      name: "Base Rate",
      svg: (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 15 14"
            fill="none"
          >
            <path
              d="M7.50005 6.3001C8.65985 6.3001 9.60005 5.3599 9.60005 4.2001C9.60005 3.0403 8.65985 2.1001 7.50005 2.1001C6.34025 2.1001 5.40005 3.0403 5.40005 4.2001C5.40005 5.3599 6.34025 6.3001 7.50005 6.3001Z"
              fill="#888888"
            />
            <path
              d="M5.05004 11.9002C4.60218 11.9002 4.15432 11.7294 3.81261 11.3877C3.12919 10.7042 3.12919 9.59621 3.81261 8.91279C4.22509 8.50856 5.54005 7.7001 7.50005 7.7001C9.46005 7.7001 10.775 8.50856 11.1875 8.91279C11.8709 9.59621 11.8709 10.7042 11.1875 11.3877C10.8458 11.7294 10.3979 11.9002 9.95005 11.9002H5.05004Z"
              fill="#888888"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 15 14"
            fill="none"
          >
            <path
              d="M7.50005 6.3001C8.65985 6.3001 9.60005 5.3599 9.60005 4.2001C9.60005 3.0403 8.65985 2.1001 7.50005 2.1001C6.34025 2.1001 5.40005 3.0403 5.40005 4.2001C5.40005 5.3599 6.34025 6.3001 7.50005 6.3001Z"
              fill="#888888"
            />
            <path
              d="M5.05004 11.9002C4.60218 11.9002 4.15432 11.7294 3.81261 11.3877C3.12919 10.7042 3.12919 9.59621 3.81261 8.91279C4.22509 8.50856 5.54005 7.7001 7.50005 7.7001C9.46005 7.7001 10.775 8.50856 11.1875 8.91279C11.8709 9.59621 11.8709 10.7042 11.1875 11.3877C10.8458 11.7294 10.3979 11.9002 9.95005 11.9002H5.05004Z"
              fill="#888888"
            />
          </svg>
        </>
      ),
    },
    {
      name: "Extra Adult",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 15 14"
          fill="none"
        >
          <path
            d="M7.50005 6.3001C8.65985 6.3001 9.60005 5.3599 9.60005 4.2001C9.60005 3.0403 8.65985 2.1001 7.50005 2.1001C6.34025 2.1001 5.40005 3.0403 5.40005 4.2001C5.40005 5.3599 6.34025 6.3001 7.50005 6.3001Z"
            fill="#888888"
          />
          <path
            d="M5.05004 11.9002C4.60218 11.9002 4.15432 11.7294 3.81261 11.3877C3.12919 10.7042 3.12919 9.59621 3.81261 8.91279C4.22509 8.50856 5.54005 7.7001 7.50005 7.7001C9.46005 7.7001 10.775 8.50856 11.1875 8.91279C11.8709 9.59621 11.8709 10.7042 11.1875 11.3877C10.8458 11.7294 10.3979 11.9002 9.95005 11.9002H5.05004Z"
            fill="#888888"
          />
        </svg>
      ),
    },
    {
      name: "Extra Child",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 15 14"
          fill="none"
        >
          <path
            d="M2.25 4.08325V10.4999M2.25 8.16659H12.75M12.75 10.4999V5.83325C12.75 5.52383 12.6271 5.22709 12.4083 5.00829C12.1895 4.7895 11.8928 4.66659 11.5833 4.66659H6.91667V8.16659M4 5.83325C4 5.98796 4.06146 6.13633 4.17085 6.24573C4.28025 6.35513 4.42862 6.41659 4.58333 6.41659C4.73804 6.41659 4.88642 6.35513 4.99581 6.24573C5.10521 6.13633 5.16667 5.98796 5.16667 5.83325C5.16667 5.67854 5.10521 5.53017 4.99581 5.42077C4.88642 5.31138 4.73804 5.24992 4.58333 5.24992C4.42862 5.24992 4.28025 5.31138 4.17085 5.42077C4.06146 5.53017 4 5.67854 4 5.83325Z"
            stroke="#888888"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      name: "Extra Child",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 9 14"
          fill="none"
        >
          <path
            d="M2.75005 1.75C2.75005 1.28587 2.93442 0.840752 3.26261 0.512563C3.5908 0.184374 4.03592 0 4.50005 0C4.96417 0 5.40929 0.184374 5.73748 0.512563C6.06567 0.840752 6.25005 1.28587 6.25005 1.75C6.25005 2.21413 6.06567 2.65925 5.73748 2.98744C5.40929 3.31563 4.96417 3.5 4.50005 3.5C4.03592 3.5 3.5908 3.31563 3.26261 2.98744C2.93442 2.65925 2.75005 2.21413 2.75005 1.75ZM4.06255 10.5V13.125C4.06255 13.609 3.67153 14 3.18755 14C2.70356 14 2.31255 13.609 2.31255 13.125V7.86953L1.74106 8.77734C1.48403 9.1875 0.942624 9.30781 0.535202 9.05078C0.12778 8.79375 0.00199857 8.25508 0.25903 7.84766L1.35005 6.1168C2.0309 5.03125 3.22036 4.375 4.50005 4.375C5.77973 4.375 6.96919 5.03125 7.65005 6.11406L8.74106 7.84766C8.99809 8.25781 8.87505 8.79648 8.46762 9.05352C8.0602 9.31055 7.5188 9.1875 7.26176 8.78008L6.68755 7.86953V13.125C6.68755 13.609 6.29653 14 5.81255 14C5.32856 14 4.93755 13.609 4.93755 13.125V10.5H4.06255Z"
            fill="#888888"
          />
        </svg>
      ),
    },
  ];

  const [inventoryRoomOpen, setInventoryRoomOpen] = useState(false);
  const [inventoryRoomOpenIdx, setInventoryRoomOpenIdx] = useState(1);
  const accordionOpenInventory = (idx) => {
    console.log(idx);
    setInventoryRoomOpenIdx(idx);
    if (inventoryRoomOpenIdx === idx) {
      setInventoryRoomOpen(false);
    } else {
      setInventoryRoomOpen(false);
    }
  };
  // console.log(inventoryRoomOpen);
  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="room-types-full-container">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div style={{ marginLeft: "-22px" }}>
                <Page
                  backAction={{ content: "Products", url: `/inventory` }}
                  title="Low Rate Inventory"
                  compactTitle
                ></Page>
              </div>
              <div></div>
            </div>
            {/* <div className="room-types-full-container-div">
              <div className="room-type-heading">
                Manage Inventory, Rates & Restrictions
              </div>
              <div>
                <Link style={{ textDecoration: "none" }} to={`/roomstype/add`}>
                  <BgThemeButton children={"Low Inventory Dates"} />
                </Link>
              </div>
            </div> */}

            <div className="inventory-full-container">
              <div
                style={{
                  borderBottom: "1px solid #ccc",
                  paddingBottom: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="inventory-first-row-head">
                  <div
                    onClick={() => setPurchaseHeading(1, "fast_filling")}
                    className={`booking-first-row-head-divs ${purchaseHeadingActiveIndex === 1 ? "active" : ""
                      }`}
                  >
                    Fast Filling
                  </div>
                  <div
                    onClick={() => setPurchaseHeading(2, "almost_sold")}
                    className={`booking-first-row-head-divs ${purchaseHeadingActiveIndex === 2 ? "active" : ""
                      }`}
                  >
                    Almost Sold Out
                  </div>
                  <div
                    onClick={() => setPurchaseHeading(3, "sold_out")}
                    className={`booking-first-row-head-divs ${purchaseHeadingActiveIndex === 3 ? "active" : ""
                      }`}
                  >
                    Sold Out
                  </div>
                </div>
                <div>
                  <BgOutlineThemeButton children={"Bulk Updates"} />
                </div>
              </div>

              <div className="inventory-second-column-2div">
                <div
                  style={{
                    borderRadius: "6px",
                  }}
                >
                  <div
                    style={{
                      background: "#EFF0F2",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      padding: "5px 33px",
                      borderBottom: "0.5px solid #CCC",
                    }}
                  >
                    <div
                      style={{
                        color: "#333",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      Room & Rates
                    </div>

                    <div
                      className="inventory-dates"
                      style={{ display: "flex", gap: "73px" }}
                    >
                      {data?.date_range?.map((date) => {
                        return (
                          <div
                            className="inventory-dates-div"
                            style={{
                              padding: "10px 26px",
                              width: "80px",
                              borderRadius: "4px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <div className="inventory-upper-date">
                              {date.slice(8, 10)}
                            </div>

                            <div className="inventory-upper-month">
                              {months[parseInt(date.slice(5, 7), 10) - 1]}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    style={{
                      borderRadius: "6px",
                      background: "#EFF0F2",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      padding: "5px 33px",
                    }}
                  >
                    <div
                      style={{
                        color: "#888",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "600",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div>Competitor Rates</div>
                      <div style={{ display: "flex", marginLeft: "5px" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.5999 6.9999C12.5999 3.9073 10.0925 1.3999 6.9999 1.3999C3.9073 1.3999 1.3999 3.9073 1.3999 6.9999C1.3999 10.0925 3.9073 12.5999 6.9999 12.5999C10.0925 12.5999 12.5999 10.0925 12.5999 6.9999ZM6.2999 9.0999C6.2999 9.4865 6.6133 9.7999 6.9999 9.7999C7.3865 9.7999 7.6999 9.4865 7.6999 9.0999V7.6999C7.6999 7.3133 7.3865 6.9999 6.9999 6.9999C6.6133 6.9999 6.2999 7.3133 6.2999 7.6999V9.0999ZM6.2999 4.8999C6.2999 5.2865 6.6133 5.5999 6.9999 5.5999C7.3865 5.5999 7.6999 5.2865 7.6999 4.8999C7.6999 4.5133 7.3865 4.1999 6.9999 4.1999C6.6133 4.1999 6.2999 4.5133 6.2999 4.8999Z"
                            fill="#888888"
                          />
                        </svg>
                      </div>
                    </div>

                    <div style={{ display: "flex", gap: "73px" }}>
                      {data?.date_range?.map((date) => {
                        return (
                          <div
                            style={{
                              // padding: "10px 26px",
                              width: "80px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <div className="inventory-INRPrice">
                              INR 3360.00
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="inventory-third-column-3div">
                <div style={{ marginRight: "7px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_1_1380)">
                      <path
                        d="M11.3189 0H2.6811C1.20037 0 0 1.20037 0 2.6811V11.3189C0 12.7996 1.20037 14 2.6811 14H11.3189C12.7996 14 14 12.7996 14 11.3189V2.6811C14 1.20037 12.7996 0 11.3189 0Z"
                        fill="#3968ED"
                      />
                      <path
                        d="M10.5866 7H3.41333"
                        stroke="white"
                        strokeMiterlimit="10"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_1380">
                        <rect width="14" height="14" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div>Hide Rates</div>
                <div></div>
              </div>

              {data?.rooms?.map((room, index) => {
                console.log(room);
                return (
                  <div
                    className={`inventory-fourth-column-4div ${inventoryRoomOpenIdx === index + 1
                        ? "inventoryRoomOpen"
                        : "inventoryRoomClose"
                      }`}
                  >
                    <div
                      style={{
                        borderRadius: "6px",
                        border: "0.5px solid #CCC",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          padding: "0 0 0 22px",
                          borderBottom: "0.5px solid #CCC",
                        }}
                      >
                        <div
                          style={{
                            color: "#333",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: "600",
                            width: "58%",
                          }}
                        >
                          <div
                            className="inventory-third-column-3div"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div
                              style={{ display: "flex", cursor: "pointer" }}
                              onClick={() => accordionOpenInventory(index + 1)}
                            >
                              <div
                                style={{ display: "flex", marginRight: "7px" }}
                              >
                                {inventoryRoomOpenIdx === index + 1 ? (
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                    >
                                      <g clip-path="url(#clip0_1270_876)">
                                        <path
                                          d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                          stroke="#3968ED"
                                        />
                                        <path
                                          d="M10.5866 7H3.41333"
                                          stroke="#3968ED"
                                          strokeMiterlimit="10"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_1270_876">
                                          <rect
                                            width="14"
                                            height="14"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </>
                                ) : (
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                    >
                                      <g clip-path="url(#clip0_1270_1017)">
                                        <path
                                          d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                          stroke="#3968ED"
                                        />
                                        <path
                                          d="M10.5866 7H3.41333"
                                          stroke="#3968ED"
                                          strokeMiterlimit="10"
                                        />
                                        <path
                                          d="M7 3.41344L7 10.5867"
                                          stroke="#3968ED"
                                          strokeMiterlimit="10"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_1270_1017">
                                          <rect
                                            width="14"
                                            height="14"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </>
                                )}
                              </div>
                              <div className="inventory-roomName">
                                {room.room_name}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                gap: "10px",
                              }}
                            >
                              <div className="updates-rates-button">
                                Update Rates
                              </div>
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_1_1562)">
                                    <path
                                      d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
                                      fill="#DAE3FC"
                                    />
                                    <path
                                      d="M5.54088 4.01685L8.50242 6.97838L5.4978 9.983"
                                      stroke="#3968ED"
                                      strokeMiterlimit="10"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1_1562">
                                      <rect
                                        width="14"
                                        height="14"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div style={{ display: "flex" }}>
                          {data?.rooms[0]?.inventory
                            ?.slice(0, 7)
                            .map((data) => {
                              return (
                                <div
                                  style={{
                                    width: "150px",
                                    height: "60px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    // border: "0.5px solid #CCC",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      flexDirection: "column",
                                      gap: "5px",
                                    }}
                                  >
                                    <input
                                      value={data.available_rooms}
                                      className="inventory-1st-column-inner-input"
                                      style={{ width: "40px" }}
                                      type="text"
                                    />
                                    <div className="inventory-1st-column-inner-input-bottom-text">
                                      {data.sold_rooms} Sold / {data.hold_rooms}{" "}
                                      Hold
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>

                      {/* inner div */}
                      {Object.keys(room.rates).map((rateType) => {
                        console.log(rateType);
                        return (
                          <div key={rateType}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                // alignItems: "center",
                                width: "100%",
                                padding: "0 0 0 18px",

                                transition: "0.5s all ease-in",
                                borderBottom: "0.5px solid #CCC",
                              }}
                            >
                              <div
                                style={{
                                  color: "#333",
                                  fontFamily: "Inter",
                                  fontSize: "12px",
                                  fontWeight: "600",
                                  width: "100%",
                                }}
                              >
                                <div style={{}}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div className="inventory-third-column-3div">
                                      <div>
                                        <div
                                          style={{
                                            display: "flex",
                                            marginRight: "7px",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div style={{ display: "flex" }}>
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="14"
                                              height="14"
                                              viewBox="0 0 14 14"
                                              fill="none"
                                            >
                                              <path
                                                d="M10.5866 7H3.41333"
                                                stroke="#3968ED"
                                                strokeMiterlimit="10"
                                              />
                                            </svg>
                                          </div>
                                          <div>{rateType}</div>
                                        </div>
                                      </div>
                                      <div></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div>{room.rat}</div> */}

                              <div style={{ display: "flex" }}>
                                <div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {dummyRoomPlansName.map((roomType) => {
                                      return (
                                        <div
                                          style={{
                                            width: "150px",
                                            height: "60px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            border: "0.5px solid #CCC",
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <div>{roomType.svg}</div>
                                            <div
                                              style={{
                                                color: "#888",
                                                fontSize: "12px",
                                                fontWeight: "400",
                                              }}
                                            >
                                              {roomType.name}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>

                                {Object.keys(room.rates[rateType]).map(
                                  (date) => {
                                    return (
                                      <>
                                        <div key={date}>
                                          {room.rates[rateType][date].map(
                                            (rate) => {
                                              return (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    flexDirection: "column",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                      }}
                                                    >
                                                      <>
                                                        {/* <div
                                                        style={{
                                                          width: "150px",
                                                          height: "60px",
                                                          display: "flex",
                                                          justifyContent:
                                                            "center",
                                                          alignItems: "center",
                                                          flexDirection:
                                                            "column",
                                                          border:
                                                            "0.5px solid #CCC",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            alignItems:
                                                              "center",
                                                            flexDirection:
                                                              "column",
                                                            gap: "5px",
                                                          }}
                                                        >
                                                          <input
                                                            value="500"
                                                            className="inventory-1st-column-inner-input"
                                                            style={{
                                                              width: "55px",
                                                            }}
                                                            type="text"
                                                          />
                                                        </div>
                                                      </div>
                                                      <div
                                                        style={{
                                                          width: "150px",
                                                          height: "60px",
                                                          display: "flex",
                                                          justifyContent:
                                                            "center",
                                                          alignItems: "center",
                                                          flexDirection:
                                                            "column",
                                                          border:
                                                            "0.5px solid #CCC",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            alignItems:
                                                              "center",
                                                            flexDirection:
                                                              "column",
                                                            gap: "5px",
                                                          }}
                                                        >
                                                          <input
                                                            value="500"
                                                            className="inventory-1st-column-inner-input"
                                                            style={{
                                                              width: "55px",
                                                            }}
                                                            type="text"
                                                          />
                                                        </div>
                                                      </div> */}
                                                        <div
                                                          style={{
                                                            width: "150px",
                                                            height: "60px",
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            alignItems:
                                                              "center",
                                                            flexDirection:
                                                              "column",
                                                            border:
                                                              "0.5px solid #CCC",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "center",
                                                              alignItems:
                                                                "center",
                                                              flexDirection:
                                                                "column",
                                                              gap: "5px",
                                                            }}
                                                          >
                                                            <input
                                                              value={
                                                                rate.rate_rack_price
                                                              }
                                                              className="inventory-1st-column-inner-input"
                                                              style={{
                                                                width: "55px",
                                                              }}
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div
                                                          style={{
                                                            width: "150px",
                                                            height: "60px",
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            alignItems:
                                                              "center",
                                                            flexDirection:
                                                              "column",
                                                            border:
                                                              "0.5px solid #CCC",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "center",
                                                              alignItems:
                                                                "center",
                                                              flexDirection:
                                                                "column",
                                                              gap: "5px",
                                                            }}
                                                          >
                                                            <input
                                                              value={
                                                                rate.rate_bar_price
                                                              }
                                                              className="inventory-1st-column-inner-input"
                                                              style={{
                                                                width: "55px",
                                                              }}
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div
                                                          style={{
                                                            width: "150px",
                                                            height: "60px",
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            alignItems:
                                                              "center",
                                                            flexDirection:
                                                              "column",
                                                            border:
                                                              "0.5px solid #CCC",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "center",
                                                              alignItems:
                                                                "center",
                                                              flexDirection:
                                                                "column",
                                                              gap: "5px",
                                                            }}
                                                          >
                                                            <input
                                                              value={
                                                                rate.child_with_Bed
                                                              }
                                                              className="inventory-1st-column-inner-input"
                                                              style={{
                                                                width: "55px",
                                                              }}
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                        <div
                                                          style={{
                                                            width: "150px",
                                                            height: "60px",
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            alignItems:
                                                              "center",
                                                            flexDirection:
                                                              "column",
                                                            border:
                                                              "0.5px solid #CCC",
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              justifyContent:
                                                                "center",
                                                              alignItems:
                                                                "center",
                                                              flexDirection:
                                                                "column",
                                                              gap: "5px",
                                                            }}
                                                          >
                                                            <input
                                                              value={
                                                                rate.child_without_Bed
                                                              }
                                                              className="inventory-1st-column-inner-input"
                                                              style={{
                                                                width: "55px",
                                                              }}
                                                              type="text"
                                                            />
                                                          </div>
                                                        </div>
                                                      </>
                                                    </div>
                                                  </div>
                                                </div>
                                                // <div key={rate.id}>
                                                //   <p>
                                                //     Rate Plan Name:{" "}
                                                //     {rate.rate_plan_name}
                                                //   </p>
                                                //   <p>
                                                //     Rate Plan Type:{" "}
                                                //     {rate.rate_plan_type}
                                                //   </p>
                                                //   <p>
                                                //     Rate Rack Price:{" "}
                                                //     {rate.rate_rack_price}
                                                //   </p>
                                                // </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </>
                                    );
                                  }
                                )}
                              </div>

                              {/* <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <>
                                      <div
                                        style={{
                                          width: "150px",
                                          height: "60px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          flexDirection: "column",
                                          border: "0.5px solid #CCC",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            gap: "5px",
                                          }}
                                        >
                                          <input
                                            value="500"
                                            className="inventory-1st-column-inner-input"
                                            style={{ width: "55px" }}
                                            type="text"
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "150px",
                                          height: "60px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          flexDirection: "column",
                                          border: "0.5px solid #CCC",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            gap: "5px",
                                          }}
                                        >
                                          <input
                                            value="500"
                                            className="inventory-1st-column-inner-input"
                                            style={{ width: "55px" }}
                                            type="text"
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "150px",
                                          height: "60px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          flexDirection: "column",
                                          border: "0.5px solid #CCC",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            gap: "5px",
                                          }}
                                        >
                                          <input
                                            value="500"
                                            className="inventory-1st-column-inner-input"
                                            style={{ width: "55px" }}
                                            type="text"
                                          />
                                        </div>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LowRateInventory;
