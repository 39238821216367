import {
  TextField,
  IndexTable,
  LegacyCard,
  IndexFilters,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Badge,
  Avatar,
  Button,
  Icon,
  Select,
} from "@shopify/polaris";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import logo from "../assets/images/room_type.png";
import { Link, useNavigate } from "react-router-dom";
import { BgOutlineThemeButton, BgThemeButton } from "./UI/Buttons";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import { useSelector } from "react-redux";
import Loader from "../Components/UI/Loader/Loader";
import AddCsvFile from "./UI/AddCsvFile";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import NoDataFound from "./CustomComponents/NoDataFound copy";
const Competitors = () => {
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);
  const [showAddRatesMenuActive, setShowAddRatesMenuActive] = useState(false);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [itemStrings, setItemStrings] = useState([
    "All",
    "Unpaid",
    "Open",
    "Closed",
    "Local delivery",
    "Local pickup",
  ]);
  const deleteView = (index) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
  };

  const duplicateView = async (name) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    await sleep(1);
    return true;
  };
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const tabs = itemStrings.map((item, index) => ({
    content: item,
    index,
    onAction: () => {},
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions:
      index === 0
        ? []
        : [
            {
              type: "rename",
              onAction: () => {},
              onPrimaryAction: async (value) => {
                const newItemsStrings = tabs.map((item, idx) => {
                  if (idx === index) {
                    return value;
                  }
                  return item.content;
                });
                await sleep(1);
                setItemStrings(newItemsStrings);
                return true;
              },
            },
            {
              type: "duplicate",
              onPrimaryAction: async (value) => {
                await sleep(1);
                duplicateView(value);
                return true;
              },
            },
            {
              type: "edit",
            },
            {
              type: "delete",
              onPrimaryAction: async () => {
                await sleep(1);
                deleteView(index);
                return true;
              },
            },
          ],
  }));
  const [selected, setSelected] = useState(0);
  const onCreateNewView = async (value) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    return true;
  };
  const sortOptions = [
    { label: "Order", value: "order asc", directionLabel: "Ascending" },
    { label: "Order", value: "order desc", directionLabel: "Descending" },
    { label: "Customer", value: "customer asc", directionLabel: "A-Z" },
    { label: "Customer", value: "customer desc", directionLabel: "Z-A" },
    { label: "Date", value: "date asc", directionLabel: "A-Z" },
    { label: "Date", value: "date desc", directionLabel: "Z-A" },
    { label: "Total", value: "total asc", directionLabel: "Ascending" },
    { label: "Total", value: "total desc", directionLabel: "Descending" },
  ];
  const [sortSelected, setSortSelected] = useState(["order asc"]);
  const { mode, setMode } = useSetIndexFiltersMode();
  const onHandleCancel = () => {};

  const onHandleSave = async () => {
    await sleep(1);
    return true;
  };

  const primaryAction =
    selected === 0
      ? {
          type: "save-as",
          onAction: onCreateNewView,
          disabled: false,
          loading: false,
        }
      : {
          type: "save",
          onAction: onHandleSave,
          disabled: false,
          loading: false,
        };
  const [accountStatus, setAccountStatus] = useState(undefined);
  const [moneySpent, setMoneySpent] = useState(undefined);
  const [taggedWith, setTaggedWith] = useState("");
  const [queryValue, setQueryValue] = useState("");

  const handleAccountStatusChange = useCallback(
    (value) => setAccountStatus(value),
    []
  );
  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );
  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleAccountStatusRemove = useCallback(
    () => setAccountStatus(undefined),
    []
  );
  const handleMoneySpentRemove = useCallback(
    () => setMoneySpent(undefined),
    []
  );
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAccountStatusRemove();
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [
    handleAccountStatusRemove,
    handleMoneySpentRemove,
    handleQueryValueRemove,
    handleTaggedWithRemove,
  ]);

  const filters = [
    {
      key: "accountStatus",
      label: "Account status",
      filter: (
        <ChoiceList
          title="Account status"
          titleHidden
          choices={[
            { label: "Enabled", value: "enabled" },
            { label: "Not invited", value: "not invited" },
            { label: "Invited", value: "invited" },
            { label: "Declined", value: "declined" },
          ]}
          selected={accountStatus || []}
          onChange={handleAccountStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "taggedWith",
      label: "Tagged with",
      filter: (
        <TextField
          label="Tagged with"
          value={taggedWith}
          onChange={handleTaggedWithChange}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    },
    {
      key: "moneySpent",
      label: "Money spent",
      filter: (
        <RangeSlider
          label="Money spent is between"
          labelHidden
          value={moneySpent || [0, 500]}
          prefix="$"
          output
          min={0}
          max={2000}
          step={1}
          onChange={handleMoneySpentChange}
        />
      ),
    },
  ];

  const appliedFilters = [];
  if (accountStatus && !isEmpty(accountStatus)) {
    const key = "accountStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, accountStatus),
      onRemove: handleAccountStatusRemove,
    });
  }
  if (moneySpent) {
    const key = "moneySpent";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }
  if (!isEmpty(taggedWith)) {
    const key = "taggedWith";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }
  const manageButton = <BgOutlineThemeButton children={"Manage"} />;

  const [orders, setOrders] = useState([]);
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  // Fetch Info
  const property_id = useSelector((state) => state.property.value);
  // console.log(property_id.id);

  const [loader, setLoader] = useState(true);

  const fetchInfo = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/admin/competitors/list`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      console.log(data, "data");
      setOrders(data.data.data);
      // console.log(data.rooms);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  setTimeout(() => {
    setLoader(false);
  }, 1000);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(orders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
    // setShowLowerMenuSingleSelect(false);
    // setShowLowerMenuMultipleSelect(false);(!showLowerMenuAddMenu);
  };

  const addModalRatesButtonClickHandler = () => {
    setShowAddRatesMenuActive(!showAddRatesMenuActive);
    // setShowLowerMenuSingleSelect(false);
    // setShowLowerMenuMultipleSelect(false);(!showLowerMenuAddMenu);
  };

  const removeAddModalContainer = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
  };

  const getItemChecked = (id) => {
    console.log(id);
  };

  // getItemChecked(selectedResources[0]);
  const [competitorId, setCompetitorId] = useState("");

  useEffect(() => {
    setCompetitorId(competitorId);
  }, [competitorId]);

  const addCompetitorsRatesHandler = (id) => {
    setCompetitorId(id);
    setShowAddRatesMenuActive(true);
    console.log(id);
  };

  // UseState for forms
  const [addRatesData, setAddRateData] = useState({
    rate_date: "",
    rate: "",

    competitor_id: competitorId,
  });

  const setAddItemRateDataHandleChange = (event) => {
    setAddRateData({
      ...addRatesData,
      [event.target.name]: event.target.value,
    });
  };

  const navigate = useNavigate();

  const rowMarkup = orders?.map(
    ({
      id,
      average_rate,
      competitor_name,
      hotel_category,
      manageButton,
      index,
    }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          {<CustomCssComponentTableText innerText={competitor_name} />}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {<CustomCssComponentTableText innerText={hotel_category} />}
        </IndexTable.Cell>
        {/* <IndexTable.Cell>
          {<CustomCssComponentTableText innerText={average_rate} />}
        </IndexTable.Cell> */}
        <IndexTable.Cell>
          <button
            style={{
              padding: "5px",
              fontSize: "14px",
              color: "white",
              fontWeight: "500",
              backgroundColor: "#3968ed",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              zIndex: "99999999",
            }}
            onClick={() => navigate(`/ratescalender/${id}`)}
          >
            Manage Rates
          </button>
        </IndexTable.Cell>
      </IndexTable.Row>
    )
  );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const removeItemConfirm = () => {
    setShowModelMenuActive(!showModelMenuActive);
    console.log("removed");
  };

  // Plan Type Options
  const hotelCategoryOptions = [
    { label: "Standard", value: "Standard" },
    { label: "Luxury", value: "Luxury" },
  ];

  const [hotelCategoryTypeSelected, setHotelCategoryPlanTypeSelected] =
    useState("EP");
  const hotelCategoryTypeHandleSelectChange = useCallback(
    (value) => setHotelCategoryPlanTypeSelected(value),
    []
  );

  // Plan Name Options
  const planNameOptions = [
    { label: "Room Only", value: "newestUpdate" },
    { label: "Room + Complimentary Breakfast", value: "oldestUpdate" },
    {
      label: "Room with Complimentary Breakfast and dinner",
      value: "mostSpent",
    },
    { label: "Breakfast + Lunch + Dinner", value: "mostOrders" },
  ];

  const [planNameSelected, setNameTypeSelected] = useState("EP");
  const planNameHandleSelectChange = useCallback(
    (value) => setNameTypeSelected(value),
    []
  );

  // Plan Name Options
  const planStatusOptions = [
    { label: "Booked", value: "Booked" },
    {
      label: "Available",
      value: "Available",
    },
  ];
  const [planStatusSelected, setPlanStatusSelected] = useState("Booked");
  const planStatusHandleSelectChange = useCallback(
    (value) => setPlanStatusSelected(value),
    []
  );

  // Plan Type Options
  const roomTypeOptions = [
    { label: "Deluxe", value: "Deluxe" },
    { label: "Super Deluxe", value: "Super Deluxe" },
    { label: "Cottage", value: "Cottage" },
    { label: "Luxury", value: "Luxury" },
    { label: "Executive", value: "Executive" },
    { label: "Family", value: "Family" },
  ];

  const [roomTypeSelected, setRoomTypeSelected] = useState("EP");
  const roomTypeHandleSelectChange = useCallback(
    (value) => setRoomTypeSelected(value),
    []
  );

  // UseState for forms
  const [competitorsData, setCompetitorsData] = useState({
    average_rate: "",
    competitor_name: "",
    hotel_category: "",
    // status: "booked",

    property_id: property_id.id,
  });

  const handleSubmitAddMeals = (event) => {
    console.log(competitorId, "competitorId competitorId");
    // http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/assign/rooms/store
    console.log({
      ...addRatesData,
      competitor_id: competitorId,
    });
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/admin/update-competitor-rate`,
      {
        method: "POST",
        body: JSON.stringify({
          ...addRatesData,
          competitor_id: competitorId,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setCompetitorId("");
          showSuccessToast(data.message);
          setShowAddRatesMenuActive(false);
          setAddRateData({
            rate: "",
            rate_date: "",
          });
        } else {
          showErrorToast("An Error occurred. Please try again...!!");
        }
      });
  };

  const setAddItemDataHandleChange = (event) => {
    setCompetitorsData({
      ...competitorsData,
      [event.target.name]: event.target.value,
    });
  };

  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    clearSelection();
    console.log(deletedIds);
    // http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/competitors/delete
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/competitors/delete`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    );

    const data = await response.json();
    clearSelection();
    // now do whatever you want with the data
    console.log(data);
    fetchInfo(property_id.id);
    clearSelection();
    setShowLowerMenuMultipleSelect(false);
    setShowModelMenuActive(false);
  };

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div className="room-type-heading">Competitors</div>
              <div>
                <BgThemeButton
                  onClick={addModalButtonClickHandler}
                  children={"Add Competitors"}
                />
              </div>
            </div>
            {orders && orders?.length > 0 ? (
              <LegacyCard>
                <IndexTable
                  selectable={false}
                  resourceName={resourceName}
                  itemCount={orders.length}
                  selectedItemsCount={
                    allResourcesSelected ? "All" : selectedResources.length
                  }
                  onSelectionChange={handleSelectionChange}
                  headings={[
                    { title: "Name" },
                    { title: "Hotel Category" },
                    { title: "" },
                    { title: "" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>

                {/* Single select */}
                {showLowerMenuSingleSelect && (
                  <div className=" room-type-bottom-container">
                    <div className="room-type-bottom-container-div multipleroom-delete-btn-div">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          gap: "20px",
                        }}
                      >
                        <Button plain destructive onClick={showModelMenu}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <Icon source={DeleteMajor} color="base" />
                            </span>
                            <span>Remove</span>
                          </div>
                        </Button>
                        {/* <Link
                    style={{ textDecoration: "none" }}
                    to={`/competitors/manage`}
                  >
                    <BgOutlineThemeButton children={"Manage"} />
                  </Link> */}
                      </div>
                    </div>
                  </div>
                )}

                {/* Multiple select */}
                {showLowerMenuMultipleSelect && (
                  <div className=" room-type-bottom-container">
                    <div className="room-type-bottom-container-div multipleroom-delete-btn-div">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Button plain destructive onClick={showModelMenu}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <Icon source={DeleteMajor} color="base" />
                            </span>
                            <span>Remove</span>
                          </div>
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </LegacyCard>
            ) : (
              <div>
                <NoDataFound />
              </div>
            )}
          </div>

          {/* Add Button Click */}
          {showAddModelMenuActive && (
            <>
              {/* <div className="bg-backdrop">
                <section
                  style={{ width: "300px" }}
                  className="room-plan-add-model-container"
                >
                  <h4>Add Competitor</h4> */}

              <AddCsvFile
                showAddModelMenuActive={showAddModelMenuActive}
                setShowAddModelMenuActive={setShowAddModelMenuActive}
                title={"Import Competitors"}
              />
              {/* </section>
              </div> */}
            </>
          )}

          {/* Add Rates Click */}
          {showAddRatesMenuActive && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <div className="add-ota-model-container-heading">
                      Add Rates
                    </div>
                    <div
                      onClick={() => setShowAddRatesMenuActive(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Date"
                        name="rate_date"
                        type="date"
                        value={addRatesData.rate_date}
                        onChange={setAddItemRateDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        titleName="Rate"
                        name="rate"
                        type="number"
                        value={addRatesData.rate}
                        onChange={setAddItemRateDataHandleChange}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        onClick={(e) => handleSubmitAddMeals(e)}
                        type="submit"
                        children={"Add"}
                      />
                      <Button onClick={() => setShowAddRatesMenuActive(false)}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}

          {showModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-type-model-container ">
                  <h4>Are You Sure?</h4>
                  <p>You Want To Remove?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        setShowModelMenuActive(!showModelMenuActive)
                      }
                    >
                      Discard
                    </Button>
                    <Button onClick={deleteData} destructive>
                      Yes, Remove
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}
        </>
      )}
    </>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "moneySpent":
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case "taggedWith":
        return `Tagged with ${value}`;
      case "accountStatus":
        return value.map((val) => `Customer ${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};
export default Competitors;
