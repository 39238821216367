import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Collapsible,
  DropZone,
  Icon,
  LegacyCard,
  LegacyStack,
  Link,
  Select,
  TextField,
  Text,
  Thumbnail,
  ButtonGroup,
  Page,
  Badge,
  Tag,
} from "@shopify/polaris";
import { ChevronDownMinor, NoteMinor } from "@shopify/polaris-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { BgOutlineThemeButton, BgThemeButton } from "./UI/Buttons";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalTextAreaCustom from "./CustomComponents/VerticalTextAreaCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
const EditRooms = () => {
  const [files, setFiles] = useState([]);
  const [value, setValue] = useState("Jaded Pixel");
  const [selected, setSelected] = useState("today");

  const handleChange = useCallback((newValue) => setValue(newValue), []);

  const handleSelectChange = useCallback((value) => setSelected(value), []);

  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(true);
  const [open4, setOpen4] = useState(true);
  const [open5, setOpen5] = useState(true);
  const [open6, setOpen6] = useState(true);
  const [open7, setOpen7] = useState(true);
  const [open8, setOpen8] = useState(true);
  const [open9, setOpen9] = useState(true);

  const handleToggle1 = useCallback(() => setOpen1((open1) => !open1), []);
  const handleToggle2 = useCallback(() => setOpen2((open2) => !open2), []);
  const handleToggle3 = useCallback(() => setOpen3((open3) => !open3), []);
  const handleToggle4 = useCallback(() => setOpen4((open4) => !open4), []);
  const handleToggle5 = useCallback(() => setOpen5((open5) => !open5), []);
  const handleToggle6 = useCallback(() => setOpen6((open6) => !open6), []);
  const handleToggle7 = useCallback(() => setOpen7((open7) => !open7), []);
  const handleToggle8 = useCallback(() => setOpen8((open8) => !open8), []);
  const handleToggle9 = useCallback(() => setOpen9((open9) => !open9), []);

  // upload files
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];

  const fileUpload = !files.length && (
    <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />
  );

  const uploadedFiles = files.length > 0 && (
    <LegacyStack vertical>
      {files.map((file, index) => (
        <LegacyStack alignment="center" key={index}>
          <Thumbnail
            size="small"
            alt={file.name}
            source={
              validImageTypes.includes(file.type)
                ? window.URL.createObjectURL(file)
                : NoteMinor
            }
          />
          <div>
            {file.name}{" "}
            <Text variant="bodySm" as="p">
              {file.size} bytes
            </Text>
          </div>
        </LegacyStack>
      ))}
    </LegacyStack>
  );

  // For status options
  const [selectedOption, setSelectedOption] = useState("today");

  const statusOptions = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last 7 days", value: "lastWeek" },
  ];
  const handleSelectStatusChange = useCallback(
    (value) => setSelectedOption(value),
    []
  );
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  // for Tag
  const [selectedTags, setSelectedTags] = useState([
    "Rustic",
    "Antique",
    "Vinyl",
    "Refurbished",
    "Singh",
  ]);
  const removeTag = useCallback(
    (tag) => () => {
      setSelectedTags((previousTags) =>
        previousTags.filter((previousTag) => previousTag !== tag)
      );
    },
    []
  );
  const tagMarkup = selectedTags.map((option) => (
    <Tag key={option} onRemove={removeTag(option)}>
      {option}
    </Tag>
  ));

  const navigate = useNavigate();

  const [addRoomTypeData, setAddRoomTypeData] = useState({
    id: "",
    room_total: 0,
    rack_price: 0,
    max_child: 0,
    room_name: "",
    max_adults: 0,
    base_child: 0,
    base_adult: 0,
    infants: 0,
    extra_bed_limit: 0,
    room_size: 0,
    room_size_unit: "",
    room_bed_type: "",
    room_description: "",
    amenities: "ac, bathtub, room",
    property_id: "99f12663-7d6d-4d28-8f08-786b7daa73f1",
    breakfast: 0,
    lunch: 100,
    dinner: 100,
    extra_adults: 0,
    child_with_Bed: 0,
    child_without_Bed: 0,
    extra_bedding: "enabled",
    page_title: "",
    meta_description: "",
    url_handle: "",

    // room_view_type: "",
    // room_images: "",
    // status: "active",
  });
  const location = useLocation();
  useEffect(() => {
    const propsData = location.state;
    console.log(propsData);
    setAddRoomTypeData(propsData);
  }, []);

  // Onchange Function
  const setAddItemDataHandleChange = (event) => {
    setAddRoomTypeData({
      ...addRoomTypeData,
      [event.target.name]: event.target.value,
    });
  };

  // Fetch data
  // const fetchInfo = async () => {
  //   try {
  //     setLoader(true); // Set loading before sending API request
  //     const response = await await fetch("http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/rooms/99f12663-7d6d-4d28-8f08-786b7daa73f1");
  //     const data = await response.json();
  //     console.log(data);
  //     setOrders(data.rooms)
  //     setLoader(false); // Stop loading
  //   } catch (error) {
  //     setLoader(false); // Stop loading in case of error
  //     console.error(error);
  //   }
  // };

  // Update
  const updateFunction = (id) => {
    console.log(id, "Update Function");

    // const findOrder = orders.find(user => user.id === id)
    // console.log(findOrder,"findOrder");
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/rooms/update/${id}`, {
      method: "POST",
      body: JSON.stringify(addRoomTypeData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    }).then((res) => res.json());
    // .then(json => fetchInfo())
    navigate("/roomstype");
  };

  return (
    <>
      <div className="edit-rooms-full-container">
        <div>
          <Page
            backAction={{ content: "Products", url: `/roomstype` }}
            title="Edit Room"
            compactTitle
            pagination={{
              hasPrevious: true,
              hasNext: true,
            }}
          ></Page>
        </div>
        <div className="edit-rooms-full-container-div">
          <div className="edit-rooms-full-container-left">
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "15px",
                width: "591px",
              }}
            >
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle1}
                      aria-expanded={open1}
                      aria-controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                    >
                      <div>Room details</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open1}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-wrapper-container">
                        <div className="edit-rooms-form-container">
                          <div className="form-container-div-full">
                            <VerticalInputFieldCustom
                              focused={false}
                              name="room_name"
                              titleName="Room Type Name"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                              value={addRoomTypeData.room_name}
                            />
                          </div>
                        </div>
                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              name="room_bed_type"
                              focused={false}
                              titleName="Bed Type"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                              value={addRoomTypeData.room_bed_type}
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              name="room_view_type"
                              value={addRoomTypeData.room_view_type}
                              focused={false}
                              titleName="Room View Type"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="form-container-div">
                          <VerticalTextAreaCustom
                            name="room_description"
                            value={addRoomTypeData.room_description}
                            focused={false}
                            titleName="Descriptions"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                            cols={10}
                            rows={5}
                          />
                        </div>
                        <div className="form-container-div"></div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle2}
                      aria_expanded={open2}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Guest</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open2}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-wrapper-container">
                        <div className="edit-rooms-form-container">
                          <div className="form-container-div-full">
                            <VerticalInputFieldCustom
                              name="base_adult"
                              value={addRoomTypeData.base_adult}
                              focused={false}
                              titleName="Base Adult"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              name="max_adults"
                              value={addRoomTypeData.max_adults}
                              focused={false}
                              titleName="Max Adult"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              name="max_child"
                              value={addRoomTypeData.max_child}
                              focused={false}
                              titleName="Max Child"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle3}
                      aria_expanded={open3}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Size</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open3}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-wrapper-container">
                        <div className="form-container-div-half">
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              name="room_size"
                              value={addRoomTypeData.room_size}
                              focused={false}
                              titleName="Room Size"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                            />
                          </div>
                          <div className="form-container-div">
                            <VerticalInputFieldCustom
                              name="room_size_unit"
                              value={addRoomTypeData.room_size_unit}
                              focused={false}
                              titleName="Unit"
                              onChange={setAddItemDataHandleChange}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle4}
                      aria_expanded={open4}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Extra</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open4}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-container">
                        <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            name="extra_bed_limit"
                            value={addRoomTypeData.extra_bed_limit}
                            focused={false}
                            titleName="Extra Bed Limit"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle5}
                      aria_expanded={open5}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Media</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open5}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <DropZone onDrop={handleDropZoneDrop} variableHeight>
                        {uploadedFiles}
                        {fileUpload}
                      </DropZone>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle6}
                      aria_expanded={open6}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Search Engine Listing</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>

                    <p
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#888",
                      }}
                    >
                      Add a title and description to see how this Room might
                      appear
                      <br />
                      in a search engine listing
                    </p>
                    <Collapsible
                      open={open6}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-container">
                        <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            name="page_title"
                            value={addRoomTypeData.page_title}
                            focused={false}
                            titleName="Page Title"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            name="meta_description"
                            value={addRoomTypeData.meta_description}
                            focused={false}
                            titleName="Meta Description"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            name="url_handle"
                            value={addRoomTypeData.url_handle}
                            focused={false}
                            titleName="Url Handle"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
            </form>
            <div className="rooms-edit-left-container-buttons">
              <div>
                <BgThemeButton
                  onClick={() => updateFunction(addRoomTypeData.id)}
                  children={"Edit"}
                />
              </div>
              <div style={{ color: "rgba(0, 122, 92, 1)" }}>
                <BgOutlineThemeButton children={"Discard"} />
              </div>
            </div>
          </div>

          <div className="edit-rooms-full-container-right">
            <form
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle7}
                      aria_expanded={open7}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Status</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open7}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <VerticalInputSelectCustomCopy
                        options={statusOptions}
                        name="status"
                        value={addRoomTypeData.status}
                        focused={false}
                        titleName="Status"
                        onChange={setAddItemDataHandleChange}
                        autoComplete="off"
                      />
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>

              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle8}
                      aria_expanded={open8}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      <div>Room details</div>
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open8}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-container">
                        <div className="form-container-div-full">
                          <TextField
                            className="textfield"
                            focused={false}
                            onChange={handleChange}
                            autoComplete="off"
                          />
                        </div>
                        <LegacyStack spacing="tight">{tagMarkup}</LegacyStack>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
              <div>
                <LegacyCard sectioned>
                  <LegacyStack vertical>
                    <div
                      onClick={handleToggle9}
                      aria_expanded={open9}
                      aria_controls="basic-collapsible"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      Pricing
                      <div>
                        <Icon source={ChevronDownMinor} />
                      </div>
                    </div>
                    <Collapsible
                      open={open9}
                      id="basic-collapsible"
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <div className="edit-rooms-form-container">
                        <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            name="rack_price"
                            value={addRoomTypeData.rack_price}
                            focused={false}
                            titleName="Rack Price"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-container-div-full">
                          <VerticalInputFieldCustom
                            name="extra_adults"
                            value={addRoomTypeData.extra_adults}
                            focused={false}
                            titleName="Extra Adults"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                      <div className="form-container-div-half">
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            name="child_with_Bed"
                            value={addRoomTypeData.child_with_Bed}
                            focused={false}
                            titleName="Child With Bed"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            name="child_without_Bed"
                            value={addRoomTypeData.child_without_Bed}
                            focused={false}
                            titleName="Child Without Bed"
                            onChange={setAddItemDataHandleChange}
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </Collapsible>
                  </LegacyStack>
                </LegacyCard>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditRooms;
