import React from "react";
import Customers from "../Components/Customers";
import Channels from "../Components/Channels";

const ChannelsPage = () => {
  return (
    <>
      <div className="main_container">
        <Channels />
      </div>
    </>
  );
};

export default ChannelsPage;
