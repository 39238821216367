import React from "react";
import Sidebar from "../Components/Sidebar";

import AddAgentProfile from "../Components/AddAgentProfile";
import NewSidebar from "../Components/NewSidebar";
import AddTarget from "../Components/AddTargets";

const AddTargetPage = () => {
  return (
    <>
      <div className="main_container">
        <AddTarget />
      </div>
    </>
  );
};

export default AddTargetPage;
