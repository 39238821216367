import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
  Button,
  Collapsible,
  DropZone,
  Icon,
  LegacyCard,
  LegacyStack,
  Select,
  TextField,
  Text,
  Thumbnail,
  ButtonGroup,
  Page,
  Badge,
  Tag,
  ChoiceList,
} from "@shopify/polaris";
import { ChevronDownMinor, NoteMinor } from "@shopify/polaris-icons";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  BgThemeButtonDisabled,
  BgThemeButtonFullWidth,
} from "./UI/Buttons";

import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";

import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalTextAreaCustom from "./CustomComponents/VerticalTextAreaCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import { useNavigate } from "react-router-dom";
import CustomToggleButton from "./UI/CustomToggleButton";
import { useSelector } from "react-redux";
import DropZoneFileUploader from "./CustomComponents/DropZoneFileUploader";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";
import Loader from "./UI/Loader/Loader";
// import HandleNaN from "./CustomComponents/HandleNaN/HandleNaN";

const AddTarget = () => {
  const [files, setFiles] = useState([]);
  const [value, setValue] = useState("Jaded Pixel");
  const [selected, setSelected] = useState("today");

  // params

  const { propertyId } = useParams();

  // errors Handle

  const [loader, setLoader] = useState(false);
  const [showAddSlot, setShowAddSlot] = useState(false);
  const property_id = useSelector((state) => state.property.value);

  const [formErrors, setFormErrors] = useState({});

  const handleChange = useCallback((newValue) => setValue(newValue), []);

  const handleSelectChange = useCallback((value) => setSelected(value), []);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [options, setOptions] = useState([]);

  const fetchInfo = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/banquet/menus/${property_id?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOptions(data.menu);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
    }
  }, [property_id]);

  const toggleAccordion = (category_id) => {
    setActiveAccordion((prevId) =>
      prevId === category_id ? null : category_id
    );
    setSelectedOptions({ ...selectedOptions });
    toggleIconRotation(category_id);
  };

  const toggleIconRotation = (category_id) => {
    const icon = document.getElementById(`icon-${category_id}`);
    if (icon) {
      icon.classList.toggle("rotateicon");
    }
  };

  const handleMenuItemsChange = (categoryId, itemName, menuItemId) => {
    setSelectedOptions((prevSelectedOptions) => {
      const optionSelectedPermissions = prevSelectedOptions[itemName] || [];
      const updatedPermissions = optionSelectedPermissions.includes(menuItemId)
        ? optionSelectedPermissions.filter((perm) => perm !== menuItemId)
        : [...optionSelectedPermissions, menuItemId];

      return {
        ...prevSelectedOptions,
        [itemName]: updatedPermissions,
      };
    });
  };

  const [open1, setOpen1] = useState(true);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(true);
  const [open4, setOpen4] = useState(true);
  const [open5, setOpen5] = useState(true);
  const [open6, setOpen6] = useState(true);
  const [open7, setOpen7] = useState(true);
  const [open8, setOpen8] = useState(true);
  const [open9, setOpen9] = useState(true);

  const handleToggle1 = useCallback(() => setOpen1((open1) => !open1), []);
  const handleToggle2 = useCallback(() => setOpen2((open2) => !open2), []);
  const handleToggle3 = useCallback(() => setOpen3((open3) => !open3), []);
  const handleToggle4 = useCallback(() => setOpen4((open4) => !open4), []);
  const handleToggle5 = useCallback(() => setOpen5((open5) => !open5), []);
  const handleToggle6 = useCallback(() => setOpen6((open6) => !open6), []);
  const handleToggle7 = useCallback(() => setOpen7((open7) => !open7), []);
  const handleToggle8 = useCallback(() => setOpen8((open8) => !open8), []);
  const handleToggle9 = useCallback(() => setOpen9((open9) => !open9), []);

  // upload files
  const handleDropZoneDrop = useCallback(
    (_dropFiles, acceptedFiles, _rejectedFiles) =>
      setFiles((files) => [...files, ...acceptedFiles]),
    []
  );

  const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
  const fileUpload = !files.length && (
    <DropZone.FileUpload actionHint="Accepts .gif, .jpg, and .png" />
  );

  const currentDate = new Date().toISOString().split("T")[0];

  const uploadedFiles = files.length > 0 && (
    <LegacyStack vertical>
      {files.map((file, index) => (
        <LegacyStack alignment="center" key={index}>
          <Thumbnail
            size="small"
            alt={file.name}
            source={
              validImageTypes.includes(file.type)
                ? window.URL.createObjectURL(file)
                : NoteMinor
            }
          />
          <div>
            {file.name}{" "}
            <Text variant="bodySm" as="p">
              {file.size} bytes
            </Text>
          </div>
        </LegacyStack>
      ))}
    </LegacyStack>
  );

  const [addItemData, setAddItemData] = useState({
    profit_margin: "",
    property_id: "",
    event_name: "",
    category_id: "",
    no_of_pax: "",
    banquet_id: "",
    client_name: "",
    client_phone: "",
    client_email: "",
    no_of_plates: "",
    per_plate_cost: "",
    event_slots: [],
    per_plate_cost: "",
    no_of_plates: "",
    tax_applied: "18",
    gst_type: "exclusive",
    tax_amount: "",
    grand_total: "",
    event_menues: {},
  });

  // Onchange Function
  const setAddItemDataHandleChange = (event) => {
    setAddItemData({
      ...addItemData,
      // property_id: property_id?.id,
      [event.target.name]: event.target.value,
    });
  };

  // For status options
  const [selectedOption, setSelectedOption] = useState("today");

  const statusOptions = [
    { label: "Select option" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];
  const slotOptions = [
    { label: "Select option" },
    { label: "Morning", value: "Morning" },
    { label: "Afternoon", value: "Afternoon" },
    { label: "Evening", value: "Evening" },
  ];

  const bedTypeOptions = [
    { label: "Select option", value: "" },
    { label: "Futon", value: "Futon" },
    { label: "King", value: "King" },
    { label: "Murphy Bed", value: "Murphy Bed" },
    { label: "Queen", value: "Queen" },
    { label: "Sofa Bed", value: "Sofa Bed" },
    { label: "Tatami Mats", value: "Tatami Mats" },
    { label: "Twin", value: "Twin" },
    { label: "Single", value: "Single" },
    { label: "Full", value: "Full" },
    { label: "Run of the House", value: "Run of the House" },
    { label: "Dorm Bed", value: "Dorm Bed" },
  ];

  const roomAreaTypeOptions = [
    { label: "Select option", value: "" },
    { label: "Square Meters", value: "Square Meters" },
    { label: "Square Feet", value: "Square Feet" },
  ];

  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const roomViewTypeOptions = [
    { label: "Select option", value: "" },
    { label: "Airport view", value: "Airport view" },
    { label: "Bay view", value: "Bay view" },
    { label: "City view", value: "City view" },
    { label: "Courtyard view", value: "Courtyard view" },
    { label: "Golf view", value: "Golf view" },
    { label: "Harbor view", value: "Harbor view" },
    { label: "Intercoastal view", value: "Intercoastal view" },
    { label: "Lake view", value: "Lake view" },
    { label: "Marina view", value: "Marina view" },
    { label: "Mountain view", value: "Mountain view" },
    { label: "Ocean view", value: "Ocean view" },
    { label: "Pool view", value: "Pool view" },
    { label: "River view", value: "River view" },
    { label: "Water view", value: "Water view" },
    { label: "Beach view", value: "Beach view" },
    { label: "Garden view", value: "Garden view" },
    { label: "Park view", value: "Park view" },
    { label: "Forest view", value: "Forest view" },
    { label: "Rain fores", value: ">Rain" },
    { label: "Various views", value: "Various views" },
    { label: "Limited view", value: "Limited view" },
    { label: "Slope view", value: "Slope view" },
    { label: "Strip view", value: "Strip view" },
    { label: "Countryside view", value: "Countryside view" },
    { label: "Sea view", value: "Sea view" },
    { label: "Valley view", value: "Valley view" },
  ];

  const handleSelectStatusChange = useCallback(
    (value) => setSelectedOption(value),
    []
  );

  // for Tag
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);

  const removeTag = useCallback(
    (tag) => () => {
      setSelectedTags((previousTags) =>
        previousTags.filter((previousTag) => previousTag !== tag)
      );
    },
    [selectedTags]
  );

  const [onkeyPressValue, setOnKeyPressValue] = useState("");

  const handleOnKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter" && onkeyPressValue.trim() !== "") {
        e.preventDefault();
        setOnKeyPressValue("");
        // valueArray.push(inputValue)
        setSelectedTags((prevArray) => [...prevArray, onkeyPressValue]);

        // onkeyPressValue = ""
        setOnKeyPressValue("");
      }
    },
    [onkeyPressValue, selectedTags]
  );

  const addCustomSlotHandler = useCallback(
    (e) => {
      if (addItemData.date !== "" && addItemData.slot !== "") {
        const valueToBeAdded = `${addItemData.date} | ${addItemData.slot}`;

        if (selectedTags.includes(valueToBeAdded)) {
          showErrorToast("Already exists");
        } else {
          setSelectedTags((prevArray) => [...prevArray, valueToBeAdded]);
          setAddItemData({
            ...addItemData,
            date: "",
            slot: "",
          });
        }
        // setSelectedDates((prevArray) => [...prevArray, addItemData.date]);
        // setSelectedSlots((prevArray) => [...prevArray, addItemData.slot]);
      } else {
        showErrorToast("Please fill the above inputs");
      }
    },
    [onkeyPressValue, selectedTags, addItemData]
  );

  // const addCustomSlotHandler = useCallback(
  //   (e) => {
  //     if (addItemData.date !== "" && addItemData.slot !== "") {
  //       const valueToBeAdded = `${addItemData.date} (${addItemData.slot})`;

  //       // Check if the value to be added already exists in the arrays
  //       if (!selectedDates.includes(addItemData.date)) {
  //         setSelectedDates((prevArray) => [...prevArray, addItemData.date]);
  //         setSelectedTags((prevArray) => [...prevArray, valueToBeAdded]);
  //       }
  //       if (!selectedSlots.includes(addItemData.slot)) {
  //         setSelectedSlots((prevArray) => [...prevArray, addItemData.slot]);
  //         setSelectedTags((prevArray) => [...prevArray, valueToBeAdded]);
  //       }
  //     } else {
  //       showErrorToast("Please fill the above inputs");
  //     }
  //   },
  //   [onkeyPressValue, selectedDates, selectedSlots, addItemData]
  // );

  const setOnKeyPressValueHandleChange = useCallback(
    (e) => {
      if (e.target.name === "event_slots") {
        setSelectedTags(selectedTags);
      }
      setOnKeyPressValue(e.target.value);
    },
    [selectedTags]
  );

  const tagMarkup = selectedTags.map((option) => (
    <Tag key={option} onRemove={removeTag(option)}>
      {option}
    </Tag>
  ));

  // dynamic inputs
  const [dynamicInputList, setDynamicInputList] = useState([
    {
      id: 1,
      expense_amount: "",
      expense_type: "",
    },
  ]);

  const handleAddClick = (id) => {
    const lastItem = dynamicInputList[dynamicInputList.length - 1];
    if (
      (lastItem && lastItem.expense_amount === "") ||
      lastItem.expense_type === "" ||
      lastItem.expense_type === " "
    ) {
      showErrorToast("Please add the above fields first...!!");
    } else {
      setDynamicInputList([
        ...dynamicInputList,
        {
          id: id + 1,
          expense_amount: "",
          expense_type: "",
        },
      ]);
    }
  };

  const handleRemoveClick = (idToRemove) => {
    const updatedList = dynamicInputList.filter(
      (item) => item.id !== idToRemove
    );
    setDynamicInputList(updatedList);
  };

  const dynamicPreviewBillHandleChange = (e, index) => {
    const { name, value } = e.target;

    const isDuplicate = dynamicInputList.some(
      (item, i) =>
        i !== index && item.expense_type === value && name === "expense_type"
    );

    if (isDuplicate) {
      showErrorToast("Duplicate entry.");
      return;
    }

    setDynamicInputList((prevInputList) => {
      const updatedInputList = [...prevInputList];
      updatedInputList[index][name] = value;
      return updatedInputList;
    });
  };

  const [selectedDatesRange, setSelectedDatesRange] = useState({
    from_date: "",
    to_date: "",
  });

  const handleDateRangeChange = (dates, formattedFromDate, formattedToDate) => {
    setSelectedDatesRange({
      from_date: formattedFromDate,
      to_date: formattedToDate,
    });
    // console.log(formattedFromDate, formattedToDate);

    if (formattedToDate === "") {
    } else {
      if (property_id) {
        fetchEventSlots(
          property_id.id,
          formattedFromDate,
          formattedToDate,
          addItemData.banquet_id
        );
      }
    }
  };

  // calculate total plate plus food cost
  const [platesCost, setPlatesCost] = useState(null);
  useEffect(() => {
    const FoodCost = addItemData.no_of_plates * addItemData.per_plate_cost;
    setPlatesCost(FoodCost);
  }, [addItemData.no_of_plates, addItemData.per_plate_cost]);

  // calculate the total addon cost
  const [totalAddonCost, setTotalAddonCost] = useState(null);
  useEffect(() => {
    const totalAddonCost = dynamicInputList.reduce(
      (prev, curr, index, array) => +prev + +curr.expense_amount,
      0
    );
    setTotalAddonCost(totalAddonCost, "totalAddonCost");
  }, [dynamicInputList]);

  // Calculate total before tax and apply tax and calculate subtotal, grandtotal
  const [grandTotal, setGrandTotal] = useState("");
  const [taxAmount, setTaxAmount] = useState("");
  const [subtotal, setSubtotal] = useState("");
  const [targetedYearlyProfit, setTargetedYearlyProfit] = useState("0");
  const [targetedMonthlyProfit, setTargetedMonthlyProfit] = useState("0");
  const [targetedDailyProfit, setTargetedDailyProfit] = useState("0");

  // "total_expense": 60000,
  // "profit_margin": 20,
  // "targeted_yearly_profit": 72000,
  // "targeted_monthly_profit": 6000,
  // "targeted_daily_profit": 200

  // const [amount, setSubtotal] = useState("");

  useEffect(() => {
    const newSubtotal = parseFloat(totalAddonCost) + parseFloat(platesCost);

    setSubtotal(newSubtotal);
    const discountPercent = 0;

    if (!isNaN(discountPercent) && discountPercent <= 100) {
      const discountAmount = (discountPercent / 100) * newSubtotal;

      const profitMargin = parseFloat(addItemData.profit_margin);

      if (!isNaN(profitMargin) && profitMargin >= 0) {
        const profitMarginAmount = (profitMargin / 100) * newSubtotal;

        setTargetedYearlyProfit(profitMarginAmount + subtotal);

        const monthlyProfit = targetedYearlyProfit / 12;
        const dayProfit = monthlyProfit / 30;

        setTargetedMonthlyProfit(monthlyProfit);
        setTargetedDailyProfit(dayProfit);
        setSubtotal(newSubtotal);

        setSubtotal(newSubtotal - discountAmount);
        setTaxAmount(taxAmount);
        setGrandTotal(grandTotal);
      }
    }
  }, [
    addItemData,
    subtotal,
    platesCost,
    totalAddonCost,
    targetedDailyProfit,
    targetedMonthlyProfit,
    targetedYearlyProfit,
  ]);

  useEffect(() => {
    if (property_id) {
      setAddItemData({
        ...addItemData,
        event_slots: selectedTags,
        property_id: property_id?.id,
      });
    }
  }, [property_id]);

  const [bedding, setBedding] = useState("");

  const beddingFinalValue = useCallback((data) => {
    setBedding(data);
  }, []);

  useEffect(() => {
    if (selectedTags) {
      setAddItemData({
        ...addItemData,
        // banquet_parking: bedding,
        event_slots: selectedTags,
        property_id: property_id?.id,
      });
    }
  }, [selectedTags, bedding]);

  useEffect(() => {
    if (property_id) {
      fetchEventCategories(property_id?.id);
      fetchAllBanquets(property_id?.id);
      fetchAllRoomTypes(property_id?.id);
    }
  }, [property_id]);

  const [eventCategories, setEventCategories] = useState([]);

  const fetchEventCategories = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        // `${process.env.REACT_APP_BASE_URL}/api/v1/event/update/${id}/${property_id?.id}`
        `${process.env.REACT_APP_BASE_URL}/api/v1/event/category/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setEventCategories(data.categories);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const [eventSlots, setEventSlots] = useState(null);

  const fetchEventSlots = async (propertyId, from_date, to_date, banquetId) => {
    try {
      if (from_date === "" || to_date === "") {
        showErrorToast("Please select the Date Range First..");
        return;
      }
      if (addItemData.banquet_id === "") {
        showErrorToast("Please select the banquet first..");
        return;
      }
      // setLoader(true);
      const response = await fetch(
        // `${process.env.REACT_APP_BASE_URL}/api/v1/event/update/${id}/${property_id?.id}`
        `${process.env.REACT_APP_BASE_URL}/api/v1/check/banquet/slot/${banquetId}/${propertyId}?start_date=${from_date}&end_date=${to_date}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setEventSlots(data.slots);
      // setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const [allBanquets, setAllBanquets] = useState([]);

  const fetchAllBanquets = async (id) => {
    try {
      setLoader(true);
      const response = await fetch(
        // `${process.env.REACT_APP_BASE_URL}/api/v1/event/update/${id}/${property_id?.id}`
        `${process.env.REACT_APP_BASE_URL}/api/v1/banquet/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAllBanquets(data.banquet);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };
  const [allRoomTypes, setAllRoomTypes] = useState([]);

  const fetchAllRoomTypes = async (propertyId) => {
    try {
      setLoader(true);
      const response = await fetch(
        // `${process.env.REACT_APP_BASE_URL}/api/v1/event/update/${id}/${property_id?.id}`
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${propertyId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setAllRoomTypes(data.rooms);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error);
    }
  };

  const [availableSlots, setAvailableSlots] = useState([]);

  const fetchAvailableSlots = async (id, date) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/check/banquet/slot/${date}/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json().then((data) => {
        // if (data.success) {
        setAvailableSlots(data.slots);
        // } else {
        //   showErrorToast("Error fetching Slots");
        // }
      });
    } catch (error) {
      console.error(error);
      showErrorToast("Something went wrong");
    }
  };

  const choiceListArray = allBanquets.map((banquet, index) => {
    const label = index === 0 ? "Select an option" : banquet.name;
    return { label, value: banquet.id };
  });

  const [hotelRoomArray, setHotelRoomArray] = useState([]);

  const holdHandleCheckboxChange = async (e, room) => {
    const roomId = room.value;
    const isChecked = e.target.checked;
    if (selectedDatesRange.from_date === "") {
      showErrorToast("Please fill the above dates.");
      return;
    }

    if (isChecked) {
      try {
        const response = await fetch(
          `https://live.revcatalyst.in/api/v1/inventories/edit/${roomId}/${selectedDatesRange.from_date}/${property_id?.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          const availableRooms = data.available_rooms;

          setHotelRoomArray((prevRoomData) => [
            ...prevRoomData,
            {
              property_id: property_id?.id,
              available_rooms: availableRooms,
              room_id: roomId,
              booking_rooms: "",
              from_date: selectedDatesRange.from_date,
              to_date: selectedDatesRange.to_date,
            },
          ]);
        } else {
          // Handle error if the fetch request is not successful
          console.error("Failed to fetch available rooms data");
        }
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
      }
    } else {
      setHotelRoomArray((prevRoomData) =>
        prevRoomData.filter((data) => data.room_id !== roomId)
      );
    }
  };

  const handleHoldInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedRoomData = [...hotelRoomArray];

    // Find the index of the current room data
    const roomDataIndex = updatedRoomData.findIndex(
      (data) => data.room_id === hotelRoomArray[index].room_id
    );

    if (!isNaN(value)) {
      if (
        name === "booking_rooms" &&
        parseInt(value) > hotelRoomArray[index].available_rooms
      ) {
        showErrorToast("Booking Rooms should be less then Available Rooms.");
        return;
      }

      updatedRoomData[roomDataIndex][name] = value;
      setHotelRoomArray(updatedRoomData);
    } else {
      showErrorToast("Please enter a valid number.");
    }
  };

  const choiceListRoomsArray = allRoomTypes.map((room) => {
    return { label: room.room_name, value: room.id };
  });

  const [selectedTimes, setSelectedTimes] = useState({});

  const handleCheckboxChange = (date, time) => {
    setSelectedTimes((prevSelectedTimes) => {
      const updatedTimes = { ...prevSelectedTimes };
      if (updatedTimes[date]) {
        if (updatedTimes[date].includes(time)) {
          // Remove the time if it already exists
          updatedTimes[date] = updatedTimes[date].filter(
            (selectedTime) => selectedTime !== time
          );
        } else {
          // Add the time if it doesn't exist
          updatedTimes[date] = [...updatedTimes[date], time];
        }
      } else {
        // If the date does not exist, create a new entry with the time
        updatedTimes[date] = [time];
      }
      return updatedTimes;
    });
  };

  // useEffect(() => {
  //   if (addItemData.date != "") {
  //     fetchAvailableSlots(property_id.id, addItemData.date);
  //   }
  // }, [property_id, addItemData.date]);

  const profitMarginsArray = [
    { label: "5%", value: "5" },
    { label: "10%", value: "10" },
    { label: "15%", value: "15" },
    { label: "20%", value: "20" },
    { label: "25%", value: "25" },
    { label: "30%", value: "30" },
    { label: "35%", value: "35" },
    { label: "40%", value: "40" },
    { label: "45%", value: "45" },
    { label: "50%", value: "50" },
    { label: "55%", value: "55" },
    { label: "60%", value: "60" },
    { label: "65%", value: "65" },
    { label: "70%", value: "70" },
    { label: "75%", value: "75" },
    { label: "80%", value: "80" },
    { label: "85%", value: "85" },
    { label: "90%", value: "90" },
    { label: "95%", value: "95" },
  ];

  const slotSelectOptions = [
    { label: "Select Option" },
    ...availableSlots?.map((slot) => {
      return { label: slot, value: slot };
    }),
  ];
  const [selectedDateFilter, setSelectedDateFilter] = useState([]);

  const [selectedDiscountMethod, setSelectedDiscountMethod] = useState([]);
  const handleDiscountMethodChange = useCallback(
    (value) => setSelectedDiscountMethod(value),
    []
  );

  const navigate = useNavigate();

  const [roomTypeAddImages, setRoomTypeAddImages] = useState([]);

  // Validate function
  const validate = (values) => {
    const errors = {};
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneNumberRegex = /^([+]\d{2})?\d{10}$/;
    const pincodeRegex = /^[1-9][0-9]{5}$/;

    return errors;
  };

  const updateDataFromChild = (updatedImages) => {
    setRoomTypeAddImages(updatedImages);
  };

  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    const urls = roomTypeAddImages.map((image) => URL.createObjectURL(image));
    setImageUrls(urls);

    return () => {
      urls.forEach((url) => URL.revokeObjectURL(url));
    };
  }, [roomTypeAddImages]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const imageDataArray = files.map((file) => file);
    setRoomTypeAddImages(imageDataArray);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files);
    setRoomTypeAddImages([...roomTypeAddImages, ...newFiles]);
    // updateDataFromChild(selectedImages);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    setAddItemData({
      ...addItemData,
      event_slots: selectedTimes,
      add_on: dynamicInputList,
      grand_total: grandTotal,
      tax_amount: taxAmount,
      sub_total: subtotal,
    });
  }, [selectedTimes, subtotal, grandTotal, taxAmount]);

  // console.log(selectedOptions);

  const handleAddEvent = (e) => {
    e.preventDefault();

    const errors = validate(addItemData);
    setFormErrors(errors);
    const hasErrors = Object.values(errors).some((error) => !!error);

    if (hasErrors === true) {
      showErrorToast("Please check the above errors.");
      return;
    }

    // if (Object.keys(selectedOptions).length === 0) {
    //   showErrorToast("Please select the menus first...!!");
    //   return null; // or return an error message, component, etc.
    // }

    console.log({
      property_id: propertyId,
      details: dynamicInputList,
      total_expense: subtotal,
      profit_margin: addItemData.profit_margin,
      targeted_yearly_profit: targetedYearlyProfit,
      targeted_monthly_profit: targetedMonthlyProfit,
      targeted_daily_profit: targetedDailyProfit,
    });

    setLoader(true);
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/admin/property/auto/generate/targets`,
      {
        method: "POST",
        body: JSON.stringify({
          property_id: propertyId,
          details: dynamicInputList,
          total_expense: subtotal,
          profit_margin: addItemData.profit_margin,
          targeted_yearly_profit: targetedYearlyProfit,
          targeted_monthly_profit: targetedMonthlyProfit,
          targeted_daily_profit: targetedDailyProfit,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setLoader(false);
          showSuccessToast(data.message);
          navigate(`/targets/${propertyId}`);
        } else {
          showErrorToast(data.message);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
        setLoader(false);
      });
  };

  const addonsForBanquetEvents = [
    { id: 1, label: "Loans (Liability)", value: "Loans (Liability)" },
    { id: 2, label: "Secured Loans", value: "Secured Loans" },
    { id: 3, label: "Unsecured Loans", value: "Unsecured Loans" },
    { id: 4, label: "Current Liabilities", value: "Current Liabilities" },
    { id: 5, label: "Duties & Taxes", value: "Duties & Taxes" },
    {
      id: 6,
      label: "Accountancy Charges Payable",
      value: "Accountancy Charges Payable",
    },
    {
      id: 7,
      label: "Electricity Bill Payable",
      value: "Electricity Bill Payable",
    },
    { id: 8, label: "GST Payable", value: "GST Payable" },
    {
      id: 9,
      label: "Staff Salary & Wages Payable",
      value: "Staff Salary & Wages Payable",
    },
    {
      id: 10,
      label: "Telephone Bill Payable",
      value: "Telephone Bill Payable",
    },
    { id: 11, label: "Crockery & Cutlery", value: "Crockery & Cutlery" },
    { id: 12, label: "Furniture & Fixture", value: "Furniture & Fixture" },
    { id: 13, label: "Televisions", value: "Televisions" },
    { id: 14, label: "ODC & Labour Charges", value: "ODC & Labour Charges" },
    { id: 15, label: "Cable Charges Exp.", value: "Cable Charges Exp." },
    { id: 16, label: "Bank Charges", value: "Bank Charges" },
    { id: 17, label: "Cable Charges Exp.", value: "Cable Charges Exp." },
    { id: 18, label: "Depreciation Expenses", value: "Depreciation Expenses" },
    { id: 19, label: "Discount/Allowance", value: "Discount/Allowance" },
    {
      id: 20,
      label: "Fee and Subscription Expenses",
      value: "Fee and Subscription Expenses",
    },
    { id: 21, label: "Garbage Expenses", value: "Garbage Expenses" },
    { id: 22, label: "Gas Cylinder LPG.", value: "Gas Cylinder LPG." },
    { id: 23, label: "Interest Paid on GST", value: "Interest Paid on GST" },
    {
      id: 24,
      label: "Laundry & Dry Cleaning Expenses",
      value: "Laundry & Dry Cleaning Expenses",
    },
    {
      id: 25,
      label: "Repair & Maintenance Electrical",
      value: "Repair & Maintenance Electrical",
    },
    {
      id: 26,
      label: "Sale Promotion Expenses",
      value: "Sale Promotion Expenses",
    },
    { id: 27, label: "Staff Welfare", value: "Staff Welfare" },
    { id: 28, label: "Staff Uniform", value: "Staff Uniform" },
    { id: 29, label: "Staff Accommodation", value: "Staff Accommodation" },
    { id: 30, label: "Telephone Expenses", value: "Telephone Expenses" },
    { id: 31, label: "Water Expenses", value: "Water Expenses" },
    { id: 32, label: "Security Services", value: "Security Services" },
    { id: 33, label: "Landscaping Expenses", value: "Landscaping Expenses" },
    { id: 34, label: "HVAC Maintenance", value: "HVAC Maintenance" },
    { id: 35, label: "Pest Control Services", value: "Pest Control Services" },
    { id: 36, label: "Building Insurance", value: "Building Insurance" },
    {
      id: 37,
      label: "Fire Safety Compliance",
      value: "Fire Safety Compliance",
    },
    {
      id: 38,
      label: "Parking Lot Maintenance",
      value: "Parking Lot Maintenance",
    },
    { id: 39, label: "Common Area Cleaning", value: "Common Area Cleaning" },
    { id: 40, label: "Elevator Maintenance", value: "Elevator Maintenance" },
    { id: 41, label: "Roof Repairs", value: "Roof Repairs" },
    {
      id: 42,
      label: "Window Cleaning Services",
      value: "Window Cleaning Services",
    },
    { id: 43, label: "Exterior Painting", value: "Exterior Painting" },
    { id: 44, label: "Waste Management", value: "Waste Management" },
  ];

  const renderSchedule = () => {
    return Object.keys(eventSlots).map((date) => (
      <div
        key={date}
        style={{
          display: "flex",
          flexDirection: "column",
          borderBottom: "0.5px solid #CCC",
          padding: "15px 0",
        }}
      >
        <div className="dynamic_event_slot_date">{date}</div>
        {eventSlots[date].length < 1 ? (
          <div>No slot available... </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            {eventSlots[date].map((time, index) => {
              return (
                <>
                  <label key={index} style={{ marginRight: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          type="checkbox"
                          value={time}
                          checked={selectedTimes[date]?.[time]}
                          onChange={() => handleCheckboxChange(date, time)}
                        />
                      </div>
                      <div>{time}</div>
                    </div>
                  </label>
                </>
              );
            })}
          </div>
        )}
      </div>
    ));
  };

  const areaOptions = [
    { label: "Hilly Areas", value: "Hilly Areas" },
    { label: "Plain Areas", value: "Plain Areas" },
  ];

  //   ‘Hilly Areas’,
  // 4:39
  // ‘Plain Areas’,

  useEffect(() => {
    const formattedData = [];

    for (const date in selectedTimes) {
      const times = selectedTimes[date];
      if (times.length > 0) {
        const entry = `${date} | ${times.join(", ")}`;
        formattedData.push(entry);
      }
    }
  }, [selectedTimes]);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <>
          <form
            onSubmit={(e) => handleAddEvent(e)}
            className="edit-rooms-full-container"
          >
            <div>
              <div className="edit-rooms-full-container-div">
                <div className="edit-rooms-full-container-left">
                  <div style={{ marginLeft: "-22px" }}>
                    <Page
                      backAction={{
                        content: `/targets/10`,
                        url: `/targets/${propertyId}`,
                      }}
                      title="Setup Targets"
                      compactTitle
                    ></Page>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                      width: "591px",
                    }}
                  >
                    <div>
                      <LegacyCard sectioned>
                        <LegacyStack vertical>
                          <div
                            onClick={handleToggle2}
                            aria_expanded={open2}
                            aria_controls="basic-collapsible"
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              fontWeight: "700",
                              fontSize: "14px",
                            }}
                          >
                            <div>Expenses</div>
                            <div>
                              <Icon source={ChevronDownMinor} />
                            </div>
                          </div>

                          <Collapsible
                            open={open2}
                            id="basic-collapsible"
                            transition={{
                              duration: "500ms",
                              timingFunction: "ease-in-out",
                            }}
                            expandOnPrint
                          >
                            <div className="edit-rooms-form-container">
                              <div className="form-container-div-half">
                                <table
                                  style={{
                                    backgroundColor: "white",
                                    padding: "0 10px 20px 0px",
                                    borderCollapse: "collapse",
                                    borderRadius: "10px",
                                    fontFamily: "Inter",
                                    width: "100%",
                                  }}
                                >
                                  <tr
                                    style={{
                                      textAlign: "left",
                                    }}
                                  >
                                    <th style={{ padding: "0px" }}>
                                      Expense Type
                                    </th>
                                    <th style={{ padding: "0px" }}>
                                      Expense Amount (₹)
                                    </th>
                                    {/* <th style={{ padding: "10px" }}>Total Amount</th> */}
                                    <th
                                      style={{
                                        width: "10px",
                                        padding: "10px",
                                      }}
                                    ></th>
                                  </tr>

                                  {dynamicInputList &&
                                    dynamicInputList.map((bill, index) => {
                                      return (
                                        // <div>{bill.reference}</div>
                                        <tr>
                                          <>
                                            <td
                                              style={{ paddingRight: "10px" }}
                                            >
                                              <VerticalInputSelectCustomCopy
                                                options={addonsForBanquetEvents}
                                                className="inputDynamicPreviewBill"
                                                type="text"
                                                name="expense_type"
                                                value={
                                                  dynamicInputList[index]
                                                    ?.expense_type
                                                }
                                                style={{ width: "100px" }}
                                                onChange={(e) =>
                                                  dynamicPreviewBillHandleChange(
                                                    e,
                                                    index
                                                  )
                                                }
                                              />
                                            </td>
                                            <td>
                                              <VerticalInputFieldCustom
                                                className="inputDynamicPreviewBill"
                                                type="number"
                                                name="expense_amount"
                                                value={
                                                  dynamicInputList[index]
                                                    ?.expense_amount
                                                }
                                                style={{ width: "100px" }}
                                                onChange={(e) =>
                                                  dynamicPreviewBillHandleChange(
                                                    e,
                                                    index
                                                  )
                                                }
                                              />
                                            </td>

                                            <td
                                              style={{
                                                padding: "10px",
                                                width: "10px",
                                              }}
                                            >
                                              {dynamicInputList.length > 1 ? (
                                                <div className="flex flex_gap_10">
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      handleAddClick(bill.id)
                                                    }
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="14"
                                                      height="14"
                                                      viewBox="0 0 14 14"
                                                      fill="none"
                                                    >
                                                      <g clipPath="url(#clip0_1270_978)">
                                                        <path
                                                          d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                                          stroke="#3968ED"
                                                        />
                                                        <path
                                                          d="M10.5866 7H3.41333"
                                                          stroke="#3968ED"
                                                          strokeMiterlimit="10"
                                                        />
                                                        <path
                                                          d="M7 3.41344L7 10.5867"
                                                          stroke="#3968ED"
                                                          strokeMiterlimit="10"
                                                        />
                                                      </g>
                                                      <defs>
                                                        <clipPath id="clip0_1270_978">
                                                          <rect
                                                            width="14"
                                                            height="14"
                                                            fill="white"
                                                          />
                                                        </clipPath>
                                                      </defs>
                                                    </svg>
                                                  </div>
                                                  <div
                                                    onClick={() =>
                                                      handleRemoveClick(bill.id)
                                                    }
                                                    style={{
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="14"
                                                      height="14"
                                                      viewBox="0 0 14 14"
                                                      fill="none"
                                                    >
                                                      <g clipPath="url(#clip0_1270_876)">
                                                        <path
                                                          d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                                          stroke="#3968ED"
                                                        />
                                                        <path
                                                          d="M10.5866 7H3.41333"
                                                          stroke="#3968ED"
                                                          strokeMiterlimit="10"
                                                        />
                                                      </g>
                                                      <defs>
                                                        <clipPath id="clip0_1270_876">
                                                          <rect
                                                            width="14"
                                                            height="14"
                                                            fill="white"
                                                          />
                                                        </clipPath>
                                                      </defs>
                                                    </svg>
                                                  </div>
                                                </div>
                                              ) : (
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    handleAddClick(bill.id)
                                                  }
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="14"
                                                    height="14"
                                                    viewBox="0 0 14 14"
                                                    fill="none"
                                                  >
                                                    <g clipPath="url(#clip0_1270_978)">
                                                      <path
                                                        d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                                        stroke="#3968ED"
                                                      />
                                                      <path
                                                        d="M10.5866 7H3.41333"
                                                        stroke="#3968ED"
                                                        strokeMiterlimit="10"
                                                      />
                                                      <path
                                                        d="M7 3.41344L7 10.5867"
                                                        stroke="#3968ED"
                                                        strokeMiterlimit="10"
                                                      />
                                                    </g>
                                                    <defs>
                                                      <clipPath id="clip0_1270_978">
                                                        <rect
                                                          width="14"
                                                          height="14"
                                                          fill="white"
                                                        />
                                                      </clipPath>
                                                    </defs>
                                                  </svg>
                                                </div>
                                              )}
                                            </td>
                                          </>
                                        </tr>
                                      );
                                    })}
                                  <div style={{ marginTop: "10px" }}>
                                    Total Expense : ₹{totalAddonCost || 0}
                                  </div>
                                </table>
                              </div>
                            </div>
                          </Collapsible>
                        </LegacyStack>
                      </LegacyCard>
                    </div>
                    <div>
                      <LegacyCard sectioned>
                        <LegacyStack vertical>
                          <div
                            onClick={handleToggle3}
                            aria_expanded={open3}
                            aria_controls="basic-collapsible"
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              fontWeight: "700",
                              fontSize: "14px",
                            }}
                          >
                            <div>Revenue</div>
                            <div>
                              <Icon source={ChevronDownMinor} />
                            </div>
                          </div>

                          <Collapsible
                            open={open3}
                            id="basic-collapsible"
                            transition={{
                              duration: "500ms",
                              timingFunction: "ease-in-out",
                            }}
                            expandOnPrint
                          >
                            <div className="edit-rooms-form-container">
                              <div className="form-container-div-half">
                                <div className="form-container-div">
                                  <VerticalInputSelectCustomCopy
                                    options={profitMarginsArray}
                                    required={true}
                                    type={"text"}
                                    titleName="Profit Margin"
                                    name="profit_margin"
                                    value={addItemData.profit_margin}
                                    onChange={setAddItemDataHandleChange}
                                  />
                                </div>
                              </div>
                              <div className="form-container-div-half">
                                <div>
                                  <b>Total Estimated Revenue</b>: ₹
                                  {targetedYearlyProfit || 0}
                                </div>
                              </div>
                            </div>
                          </Collapsible>
                        </LegacyStack>
                      </LegacyCard>
                    </div>

                    <div className="rooms-edit-left-container-buttons">
                      <div>
                        <BgThemeButton type="submit" children={"Create"} />
                      </div>
                      <div style={{ color: "rgba(0, 122, 92, 1)" }}>
                        <Link
                          to={`/targets/${propertyId}`}
                          style={{ textDecoration: "none" }}
                        >
                          <BgOutlineThemeButton children={"Discard"} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="edit-rooms-full-container-right">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                      }}
                    >
                      <div>
                        <LegacyCard sectioned>
                          <LegacyStack vertical>
                            <div
                              onClick={handleToggle1}
                              aria-expanded={open1}
                              aria-controls="basic-collapsible"
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontWeight: "700",
                                fontSize: "14px",
                                cursor: "pointer",
                              }}
                            >
                              <div>Profit</div>
                              <div>
                                <Icon source={ChevronDownMinor} />
                              </div>
                            </div>
                            <Collapsible
                              open={open1}
                              id="basic-collapsible"
                              transition={{
                                duration: "500ms",
                                timingFunction: "ease-in-out",
                              }}
                              expandOnPrint
                            >
                              <div className="edit-rooms-form-wrapper-container">
                                <div className="edit-rooms-form-container">
                                  <div className="form-container-div-half">
                                    <div className="form-container-div">
                                      <VerticalInputSelectCustomCopy
                                        options={profitMarginsArray}
                                        required={true}
                                        type={"text"}
                                        titleName="Profit Margin"
                                        name="profit_margin"
                                        value={addItemData.profit_margin}
                                        onChange={setAddItemDataHandleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Collapsible>
                          </LegacyStack>
                        </LegacyCard>
                      </div>
                    </div>
                  </div> */}
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default AddTarget;
