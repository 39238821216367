import {
  TextField,
  IndexTable,
  LegacyCard,
  IndexFilters,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Badge,
  Avatar,
  Button,
  Icon,
  Select,
  Page,
} from "@shopify/polaris";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import logo from "../assets/images/room_type.png";
import {
  BgOutlineThemeButton,
  BgThemeButton,
  WithoutBgButtonBlue,
} from "./UI/Buttons";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import Loader from "./UI/Loader/Loader";
import StatusUpdate from "./CustomComponents/StatusUpdate";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SidebarInnerLists from "./CustomComponents/SidebarInnerLists";
import { rooms_inner_lists } from "../assets/constant";
import { checkIsAuthenticatedFalse } from "../reduxToolkit/Slice/isAuthenticatedSlice";

const Rooms = () => {
  const navigate = useNavigate();
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);
  const userToken = JSON.parse(localStorage.getItem("userToken"));

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);

  const [showLowerMenuAddMenu, setShowLowerMenuAddMenu] = useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [itemStrings, setItemStrings] = useState([
    "All",
    "Unpaid",
    "Open",
    "Closed",
    "Local delivery",
    "Local pickup",
  ]);
  const deleteView = (index) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
  };

  const duplicateView = async (name) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    await sleep(1);
    return true;
  };

  const tabs = itemStrings.map((item, index) => ({
    content: item,
    key: index,
    index,
    onAction: () => {},
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions:
      index === 0
        ? []
        : [
            {
              type: "rename",
              onAction: () => {},
              onPrimaryAction: async (value) => {
                const newItemsStrings = tabs.map((item, idx) => {
                  if (idx === index) {
                    return value;
                  }
                  return item.content;
                });
                await sleep(1);
                setItemStrings(newItemsStrings);
                return true;
              },
            },
            {
              type: "duplicate",
              onPrimaryAction: async (value) => {
                await sleep(1);
                duplicateView(value);
                return true;
              },
            },
            {
              type: "edit",
            },
            {
              type: "delete",
              onPrimaryAction: async () => {
                await sleep(1);
                deleteView(index);
                return true;
              },
            },
          ],
  }));

  const [selected, setSelected] = useState(0);

  const onCreateNewView = async (value) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    return true;
  };

  const sortOptions = [
    { label: "Order", value: "order asc", directionLabel: "Ascending" },
    { label: "Order", value: "order desc", directionLabel: "Descending" },
    { label: "Customer", value: "customer asc", directionLabel: "A-Z" },
    { label: "Customer", value: "customer desc", directionLabel: "Z-A" },
    { label: "Date", value: "date asc", directionLabel: "A-Z" },
    { label: "Date", value: "date desc", directionLabel: "Z-A" },
    { label: "Total", value: "total asc", directionLabel: "Ascending" },
    { label: "Total", value: "total desc", directionLabel: "Descending" },
  ];

  const [sortSelected, setSortSelected] = useState(["order asc"]);
  const { mode, setMode } = useSetIndexFiltersMode();
  const onHandleCancel = () => {};

  const onHandleSave = async () => {
    await sleep(1);
    return true;
  };

  const primaryAction =
    selected === 0
      ? {
          type: "save-as",
          onAction: onCreateNewView,
          disabled: false,
          loading: false,
        }
      : {
          type: "save",
          onAction: onHandleSave,
          disabled: false,
          loading: false,
        };
  const [accountStatus, setAccountStatus] = useState(undefined);
  const [moneySpent, setMoneySpent] = useState(undefined);
  const [taggedWith, setTaggedWith] = useState("");
  const [queryValue, setQueryValue] = useState("");

  const handleAccountStatusChange = useCallback(
    (value) => setAccountStatus(value),
    []
  );
  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );
  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleAccountStatusRemove = useCallback(
    () => setAccountStatus(undefined),
    []
  );
  const handleMoneySpentRemove = useCallback(
    () => setMoneySpent(undefined),
    []
  );
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAccountStatusRemove();
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [
    handleAccountStatusRemove,
    handleMoneySpentRemove,
    handleQueryValueRemove,
    handleTaggedWithRemove,
  ]);

  const filters = [
    {
      key: "accountStatus",
      label: "Account status",
      filter: (
        <ChoiceList
          title="Account status"
          titleHidden
          choices={[
            { label: "Enabled", value: "enabled" },
            { label: "Not invited", value: "not invited" },
            { label: "Invited", value: "invited" },
            { label: "Declined", value: "declined" },
          ]}
          selected={accountStatus || []}
          onChange={handleAccountStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "taggedWith",
      label: "Tagged with",
      filter: (
        <TextField
          label="Tagged with"
          value={taggedWith}
          onChange={handleTaggedWithChange}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    },
    {
      key: "moneySpent",
      label: "Money spent",
      filter: (
        <RangeSlider
          label="Money spent is between"
          labelHidden
          value={moneySpent || [0, 500]}
          prefix="$"
          output
          min={0}
          max={2000}
          step={1}
          onChange={handleMoneySpentChange}
        />
      ),
    },
  ];

  const appliedFilters = [];

  if (accountStatus && !isEmpty(accountStatus)) {
    const key = "accountStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, accountStatus),
      onRemove: handleAccountStatusRemove,
    });
  }

  if (moneySpent) {
    const key = "moneySpent";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }

  if (!isEmpty(taggedWith)) {
    const key = "taggedWith";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }

  const booked = (
    <div
      style={{
        color: "#E03838",
        padding: "6px",
        backgroundColor: "#FFEAEA",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
      }}
    >
      Booked
    </div>
  );

  const available = (
    <div
      style={{
        color: "#15AA12",
        padding: "6px",
        backgroundColor: "#DFF8DF",
        display: "inline-flex",
        borderRadius: "10px",
        fontWeight: "500",
      }}
    >
      Available
    </div>
  );

  const resourceName = {
    singular: "order",
    plural: "orders",
  };
  const [orders, setOrders] = useState([]);
  const dispatch = useDispatch();
  // fetch data
  const [btnStatus, setButtonStatus] = useState([]);

  const property_id = useSelector((state) => state.property.value);
  // console.log(property_id.id);

  const [loader, setLoader] = useState(true);

  const fetchInfo = async (id) => {
    console.log(id, "propertyId");
    // console.log(userToken.token, "token");
    if (!id) {
      navigate("/login");
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/assign/rooms/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setOrders(data.rooms);
      console.log(data.rooms);
    } catch (error) {
      console.error(error);
    }
  };

  const [fetchedRoomType, setFetchedRoomType] = useState([]);

  const fetchRoomType = async (id) => {
    if (!id) {
      navigate("/login");
    }
    try {
      setLoader(true);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/rooms/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.message === "Unauthorised.") {
        navigate("/");
      }
      setFetchedRoomType(data.rooms);
      // console.log(data.rooms[0].id);
    } catch (error) {
      console.error(error + "sdfghjkl;");
    }
  };

  useEffect(() => {
    if (property_id) {
      fetchInfo(property_id?.id);
      fetchRoomType(property_id?.id);
    }
  }, [property_id]);

  setTimeout(() => {
    setLoader(false);
  }, 1000);

  // let { selectedResources, allResourcesSelected, handleSelectionChange } =
  //   useIndexResourceState(orders);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(orders);

  const removeSingle = () => {
    setOrders((current) =>
      current.filter((uniqueRoom) => {
        return uniqueRoom.id !== selectedResources[0];
      }, clearSelection())
    );
  };

  const removeMultiple = () => {
    selectedResources.map((item) => {
      setOrders((current) =>
        current.filter((uniqueRoom) => {
          return uniqueRoom.id !== item;
        }, clearSelection())
      );
    });
  };

  const selectedIndex = () => {
    selectedResources = [];
  };

  useEffect(() => {
    if (selectedResources.length === 1) {
      getOneData(selectedResources[0]);
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
      setShowLowerMenuAddMenu(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuAddMenu(false);
    } else {
      setAddItemData({
        room_name: "",
        room_no: "",
        floor_no: "",
        status: "booked",
        room_id: fetchedRoomType ? fetchedRoomType[0]?.id : "",

        property_id: property_id.id,
      });
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuAddMenu(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const addButtonClickHandler = () => {
    setShowLowerMenuSingleSelect(false);

    setShowLowerMenuMultipleSelect(false);
    setShowLowerMenuAddMenu(!showLowerMenuAddMenu);
  };

  const getItemChecked = (id) => {
    // console.log(id);
  };

  // getItemChecked(selectedResources[0]);
  const newSelectedResources = [];
  const showId = (id) => {
    newSelectedResources.push(id);
  };

  // useEffect(() => {
  // http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/assign/rooms/edit/99f5544e-fb01-49b0-9374-1b30bd6162d8
  const getOneData = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/assign/rooms/edit/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      console.log(data.room, "data.room");
      const updatedData = {
        room_name: data.room.room_name,
        room_no: data.room.room_no,
        floor_no: data.room.floor_no,
        status: data.room.status,
        room_id: data.room.room_id,
        property_id: data.room.property_id,
      };

      setAddItemData(updatedData);
      // console.log(addRoomPlans);
    } catch (error) {
      console.error(error);
    }
  };
  
  const [bookedBtn, setBookedBtn] = useState(booked);

  const rowMarkup = orders.map(
    ({ id, room_no, room_name, floor_no, status, index }) => {
      return (
        <IndexTable.Row
          id={id}
          key={index}
          selected={selectedResources.includes(id)}
          position={id}
        >
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={room_no}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={room_name}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <CustomCssComponentTableText
              innerText={floor_no}
            ></CustomCssComponentTableText>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <StatusUpdate status={status}></StatusUpdate>
          </IndexTable.Cell>
        </IndexTable.Row>
      );
    }
  );

  const showModelMenu = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  const removeItemConfirm = () => {
    setShowModelMenuActive(!showModelMenuActive);
  };

  // Plan Name Options
  const [selectedStatus, setSelectedStatus] = useState("");
  const planStatusOptions = [
    { label: "Booked", value: "booked", name: "Booked" },
    {
      label: "Available",
      value: "available",
      name: "Available",
    },
  ];

  const [planStatusSelected, setPlanStatusSelected] = useState("Booked");
  const planStatusHandleSelectChange = useCallback(
    (value) => setPlanStatusSelected(value),
    []
  );

  const roomTypeOptions = fetchedRoomType.map((roomType) => {
    return { label: roomType.room_name, value: roomType.id };
  });

  const [roomTypeSelected, setRoomTypeSelected] = useState("EP");
  const roomTypeHandleSelectChange = useCallback(
    (value) => setRoomTypeSelected(value),
    []
  );

  const handleSubmit = useCallback((e) => {
    console.log(addItemData);
  }, []);

  const statusOptions = [
    { label: "Booked", value: "booked" },
    { label: "Available", value: "available" },
  ];

  // UseState for forms
  const [addItemData, setAddItemData] = useState({
    room_name: "",
    room_no: "",
    floor_no: "",
    status: "booked",
    room_id: "dsd",

    property_id: property_id?.id,
  });

  const setAddItemDataHandleChange = (event) => {
    setAddItemData({
      ...addItemData,
      [event.target.name]: event.target.value,
    });
  };
  console.log(addItemData);

  const deletedIds = {
    ids: selectedResources,
  };

  const deleteData = async () => {
    clearSelection();
    console.log(deletedIds);

    // "http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/assign/rooms/delete"
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/assign/rooms/delete`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify(deletedIds),
      }
    );

    const data = await response.json();
    clearSelection();
    // now do whatever you want with the data
    console.log(data);
    fetchInfo(property_id.id);
    clearSelection();
    setShowLowerMenuMultipleSelect(false);
    setShowModelMenuActive(false);
  };

  const handleSubmitAddMeals = (event) => {
    // http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/assign/rooms/store
    console.log(addItemData);
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/assign/rooms/store`, {
      method: "POST",
      body: JSON.stringify({ ...addItemData }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((json) => fetchInfo(property_id.id))
      .then((json) => setShowAddModelMenuActive(false))
      .then((json) =>
        setAddItemData({
          room_id: "",
          room_name: "",
          room_no: "",
          floor_no: "",
          status: "booked",
        })
      );
  };

  const updateFunction = (id) => {
    console.log(id, "Update Function");
    console.log(addItemData);

    const findOrder = orders.find((user) => user.id === id);
    // console.log(findOrder,"findOrder");
    //http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/assign/rooms/update/99f55437-8105-476c-aced-2ef199f8b0cb
    console.log({ ...addItemData, status: addItemData.status });
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/assign/rooms/update/${id}`,
      {
        method: "POST",

        body: JSON.stringify({ ...addItemData, status: addItemData.status }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => fetchInfo(property_id.id), clearSelection());
  };

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
    clearSelection();
  };

  // const fetchPropertyInfo = async () => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_BASE_URL}/api/v1/properties`
  //     );
  //     const data = await response.json();
  //     // console.log("no property");
  //     if (data.properties.length > 0) {
  //       if (data.properties.length === 0) {
  //         navigate("/nopropertypage");
  //         localStorage.clear();
  //       }
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchPropertyInfo();
  // }, []);

  return (
    <>
      <SidebarInnerLists innerLists={rooms_inner_lists} />
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div className="room-type-heading">Rooms</div>
              <div>
                <BgThemeButton
                  onClick={addModalButtonClickHandler}
                  children={"Add Rooms"}
                />
              </div>
            </div>

            <LegacyCard>
              <IndexTable
                resourceName={resourceName}
                itemCount={orders.length}
                selectedItemsCount={
                  allResourcesSelected ? "All" : selectedResources.length
                }
                onSelectionChange={handleSelectionChange}
                headings={[
                  { title: "Room No" },
                  { title: "Room Type" },
                  { title: "Floor No." },
                  { title: "Status" },
                ]}
              >
                {rowMarkup}
              </IndexTable>
              {/* Single select */}
              {showLowerMenuSingleSelect && (
                <div className=" room-type-bottom-container">
                  <div className="room-type-bottom-container-div">
                    <div>
                      <Button
                        plain
                        destructive
                        // onClick={showModelMenu}
                        onClick={() => showModelMenu()}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <Icon source={DeleteMajor} color="base" />
                          </span>
                          <span>Remove</span>
                        </div>
                      </Button>
                    </div>
                    <form
                      onSubmit={(e) => e.preventDefault()}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="room-type-bottom-div">
                        <div>Room Status</div>
                        <div style={{ width: "250px" }}>
                          <VerticalInputSelectCustomCopy
                            options={statusOptions}
                            name="status"
                            type="text"
                            value={addItemData.status}
                            onChange={setAddItemDataHandleChange}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          color: "#3968ED",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <BgThemeButton
                          onClick={() => updateFunction(selectedResources[0])}
                          children={"Update"}
                        />
                        {/* <BgOutlineThemeButton children={"Edit"} /> */}
                      </div>
                    </form>
                  </div>
                </div>
              )}

              {/* Multiple select */}
              {showLowerMenuMultipleSelect && (
                <div className=" room-type-bottom-container">
                  <div className="room-type-bottom-container-div multipleroom-delete-btn-div">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Button plain destructive>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textDecoration: "none",
                          }}
                        >
                          <span>
                            <Icon source={DeleteMajor} color="base" />
                          </span>
                          <span onClick={deleteData}>Remove</span>
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              )}

              {/* Add Button Click */}
              {showAddModelMenuActive && (
                <>
                  <div className="bg-backdrop">
                    <form
                      onSubmit={(e) => e.preventDefault()}
                      style={{ width: "700px" }}
                      className="stock-add-model-container"
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "15px",
                        }}
                      >
                        <div className="add-ota-model-container-heading">
                          Add Rooms
                        </div>
                        <div
                          onClick={() => setShowAddModelMenuActive(false)}
                          style={{ cursor: "pointer" }}
                        >
                          <Icon source={CancelMajor} color="base" />
                        </div>
                      </div>

                      <div className="form-container-div-half">
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            required={true}
                            titleName="Room No."
                            name="room_no"
                            type="number"
                            value={addItemData.room_no}
                            onChange={setAddItemDataHandleChange}
                          />
                        </div>
                        <div className="form-container-div">
                          <VerticalInputSelectCustomCopy
                            options={roomTypeOptions}
                            titleName="Room Type"
                            name="room_id"
                            value={addItemData.room_id}
                            onChange={setAddItemDataHandleChange}
                          />
                        </div>
                      </div>

                      <div className="form-container-div-half">
                        <div className="form-container-div">
                          <VerticalInputFieldCustom
                            required={true}
                            titleName="Floor No."
                            name="floor_no"
                            value={addItemData.floor_no}
                            onChange={setAddItemDataHandleChange}
                          />
                        </div>

                        <div
                          style={{ width: "100%" }}
                          className="form-container-div"
                        >
                          <VerticalInputSelectCustomCopy
                            options={statusOptions}
                            titleName="Status"
                            name="status"
                            value={addItemData.status}
                            onChange={setAddItemDataHandleChange}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "start",
                            gap: "10px",
                          }}
                        >
                          <BgThemeButton
                            onClick={(e) => handleSubmitAddMeals(e)}
                            type="submit"
                            children={"Add"}
                          />
                          <Button
                            onClick={() => setShowAddModelMenuActive(false)}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
            </LegacyCard>
          </div>

          {showModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-type-model-container ">
                  <h4>Are You Sure?</h4>
                  <p>You Want To Remove?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        setShowModelMenuActive(!showModelMenuActive)
                      }
                    >
                      Discard
                    </Button>
                    <Button onClick={deleteData} destructive>
                      Yes, Remove
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}
        </>
      )}
    </>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "moneySpent":
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case "taggedWith":
        return `Tagged with ${value}`;
      case "accountStatus":
        return value.map((val) => `Customer ${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};
export default Rooms;
