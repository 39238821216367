import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BgOutlineThemeButton, BgThemeButton } from "./UI/Buttons";
import Loader from "./UI/Loader/Loader";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import { useSelector } from "react-redux";
import { Button, Icon } from "@shopify/polaris";

import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { rooms_inner_lists } from "../assets/constant";
import SidebarInnerLists from "./CustomComponents/SidebarInnerLists";
import DatePicker from "react-datepicker";

const Inventory = () => {
  const [purchaseHeadingActiveIndex, setPurchaseHeadingActiveIndex] =
    useState(1);
  const [purchaseHeadingActive, setPurchaseHeadingActive] = useState("");

  const setPurchaseHeading = (idx) => {
    setPurchaseHeadingActiveIndex(idx);
    if (purchaseHeadingActive === idx) {
      setPurchaseHeadingActive("active");
    } else {
      setPurchaseHeadingActive("");
    }
  };
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const [data, setData] = useState([]);
  console.log(data);
  const [loader, setLoader] = useState(true);

  const [propertyUniqueId, setPropertyUniqueId] = useState("");
  const selectedPropertyUniqueId = useSelector((state) => state.property.value);
  // setPropertyUniqueId(selectedPropertyUniqueId.id);
  // console.log(
  //   selectedPropertyUniqueId.id,
  //   selectedPropertyUniqueId.property_name,
  //   "selectedPropertyUniqueId"
  // );

  const property_id = useSelector((state) => state.property.value);
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(currentDate.getDate()).padStart(2, "0");
  const formattedDate = `${year}-${month}-${day}`;
  // console.log(formattedDate);
  const [selectedDate, setSelectedDate] = useState(formattedDate);

  const dateClickHandler = (date) => {
    {
      setBulkInventoryData({
        ...bulkInventoryData,
        from_date: date,
      });
    }

    setSelectedDate(date);

    fetchInfoWithDate(property_id.id, date);
  };

  console.log(selectedDate);
  const fetchInfo = async (id) => {
    console.log(id, "propertyId");
    try {
      const response = await fetch(
        // `${process.env.REACT_APP_BASE_URL}/api/v1/assign/rooms/${process.env.REACT_APP_PROPERTY_ID}`
        `https://app.revcatalyst.in/api/v1/inventories/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchInfoWithDate = async (id, date) => {
    console.log(id, "propertyId");
    console.log(date, "date");
    try {
      const response = await fetch(
        // {{Url}}/api/v1/inventories/9a037b05-f21b-4efd-8fdf-5db1309a2c26?date=2023-09-06
        `https://app.revcatalyst.in/api/v1/inventories/${id}?date=${date}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error(error);
    }
  };
  //   fetchPost(selectedPropertyUniqueId.id);

  //   console.log(selectedPropertyUniqueId.id);
  // }, [selectedPropertyUniqueId.id]);

  useEffect(() => {
    console.log(property_id, "property_id");
    if (property_id) {
      fetchInfo(property_id?.id);
    }
  }, [property_id]);

  setTimeout(() => {
    setLoader(false);
  }, 1000);

  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const dummyRoomPlansName = [
    {
      name: "Base Rate",
      svg: (
        <>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 15 14"
            fill="none"
          >
            <path
              d="M7.50005 6.3001C8.65985 6.3001 9.60005 5.3599 9.60005 4.2001C9.60005 3.0403 8.65985 2.1001 7.50005 2.1001C6.34025 2.1001 5.40005 3.0403 5.40005 4.2001C5.40005 5.3599 6.34025 6.3001 7.50005 6.3001Z"
              fill="#888888"
            />
            <path
              d="M5.05004 11.9002C4.60218 11.9002 4.15432 11.7294 3.81261 11.3877C3.12919 10.7042 3.12919 9.59621 3.81261 8.91279C4.22509 8.50856 5.54005 7.7001 7.50005 7.7001C9.46005 7.7001 10.775 8.50856 11.1875 8.91279C11.8709 9.59621 11.8709 10.7042 11.1875 11.3877C10.8458 11.7294 10.3979 11.9002 9.95005 11.9002H5.05004Z"
              fill="#888888"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 15 14"
            fill="none"
          >
            <path
              d="M7.50005 6.3001C8.65985 6.3001 9.60005 5.3599 9.60005 4.2001C9.60005 3.0403 8.65985 2.1001 7.50005 2.1001C6.34025 2.1001 5.40005 3.0403 5.40005 4.2001C5.40005 5.3599 6.34025 6.3001 7.50005 6.3001Z"
              fill="#888888"
            />
            <path
              d="M5.05004 11.9002C4.60218 11.9002 4.15432 11.7294 3.81261 11.3877C3.12919 10.7042 3.12919 9.59621 3.81261 8.91279C4.22509 8.50856 5.54005 7.7001 7.50005 7.7001C9.46005 7.7001 10.775 8.50856 11.1875 8.91279C11.8709 9.59621 11.8709 10.7042 11.1875 11.3877C10.8458 11.7294 10.3979 11.9002 9.95005 11.9002H5.05004Z"
              fill="#888888"
            />
          </svg>
        </>
      ),
    },
    {
      name: "Extra Adult",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 15 14"
          fill="none"
        >
          <path
            d="M7.50005 6.3001C8.65985 6.3001 9.60005 5.3599 9.60005 4.2001C9.60005 3.0403 8.65985 2.1001 7.50005 2.1001C6.34025 2.1001 5.40005 3.0403 5.40005 4.2001C5.40005 5.3599 6.34025 6.3001 7.50005 6.3001Z"
            fill="#888888"
          />
          <path
            d="M5.05004 11.9002C4.60218 11.9002 4.15432 11.7294 3.81261 11.3877C3.12919 10.7042 3.12919 9.59621 3.81261 8.91279C4.22509 8.50856 5.54005 7.7001 7.50005 7.7001C9.46005 7.7001 10.775 8.50856 11.1875 8.91279C11.8709 9.59621 11.8709 10.7042 11.1875 11.3877C10.8458 11.7294 10.3979 11.9002 9.95005 11.9002H5.05004Z"
            fill="#888888"
          />
        </svg>
      ),
    },
    {
      name: "Extra Child",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 15 14"
          fill="none"
        >
          <path
            d="M2.25 4.08325V10.4999M2.25 8.16659H12.75M12.75 10.4999V5.83325C12.75 5.52383 12.6271 5.22709 12.4083 5.00829C12.1895 4.7895 11.8928 4.66659 11.5833 4.66659H6.91667V8.16659M4 5.83325C4 5.98796 4.06146 6.13633 4.17085 6.24573C4.28025 6.35513 4.42862 6.41659 4.58333 6.41659C4.73804 6.41659 4.88642 6.35513 4.99581 6.24573C5.10521 6.13633 5.16667 5.98796 5.16667 5.83325C5.16667 5.67854 5.10521 5.53017 4.99581 5.42077C4.88642 5.31138 4.73804 5.24992 4.58333 5.24992C4.42862 5.24992 4.28025 5.31138 4.17085 5.42077C4.06146 5.53017 4 5.67854 4 5.83325Z"
            stroke="#888888"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      name: "Extra Child",
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 9 14"
          fill="none"
        >
          <path
            d="M2.75005 1.75C2.75005 1.28587 2.93442 0.840752 3.26261 0.512563C3.5908 0.184374 4.03592 0 4.50005 0C4.96417 0 5.40929 0.184374 5.73748 0.512563C6.06567 0.840752 6.25005 1.28587 6.25005 1.75C6.25005 2.21413 6.06567 2.65925 5.73748 2.98744C5.40929 3.31563 4.96417 3.5 4.50005 3.5C4.03592 3.5 3.5908 3.31563 3.26261 2.98744C2.93442 2.65925 2.75005 2.21413 2.75005 1.75ZM4.06255 10.5V13.125C4.06255 13.609 3.67153 14 3.18755 14C2.70356 14 2.31255 13.609 2.31255 13.125V7.86953L1.74106 8.77734C1.48403 9.1875 0.942624 9.30781 0.535202 9.05078C0.12778 8.79375 0.00199857 8.25508 0.25903 7.84766L1.35005 6.1168C2.0309 5.03125 3.22036 4.375 4.50005 4.375C5.77973 4.375 6.96919 5.03125 7.65005 6.11406L8.74106 7.84766C8.99809 8.25781 8.87505 8.79648 8.46762 9.05352C8.0602 9.31055 7.5188 9.1875 7.26176 8.78008L6.68755 7.86953V13.125C6.68755 13.609 6.29653 14 5.81255 14C5.32856 14 4.93755 13.609 4.93755 13.125V10.5H4.06255Z"
            fill="#888888"
          />
        </svg>
      ),
    },
  ];
  const [activeIndexes, setActiveIndexes] = useState([]);

  const handleAccordionClick = (index) => {
    if (activeIndexes.includes(index)) {
      setActiveIndexes(activeIndexes.filter((i) => i !== index));
    } else {
      setActiveIndexes([...activeIndexes, index]);
    }
  };

  const [expandedItems, setExpandedItems] = useState([]);

  // const togglePlanExpand = (index) => {
  //   setIsExpanded(!isExpanded);
  // };
  const togglePlanExpand = (rateType) => {
    if (expandedItems.includes(rateType)) {
      setExpandedItems(expandedItems.filter((i) => i !== rateType));
    } else {
      setExpandedItems([...expandedItems, rateType]);
    }
  };
  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);

  const [addItemData, setAddItemData] = useState({
    room_name: "",
    room_no: "",
    floor_no: "",
    status: "booked",
    room_id: "",

    // property_id: property_id.id,
  });

  const [bulkInventoryData, setBulkInventoryData] = useState({
    property_id: property_id.id,
    from_date: "2023-08-30",
    available_rooms: "",
    update_type: "",
    room_price: "",
  });

  const setBulkInventoryHandleChange = (event) => {
    setBulkInventoryData({
      ...bulkInventoryData,
      property_id: property_id.id,
      [event.target.name]: event.target.value,
    });
  };

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
  };

  const setAddItemDataHandleChange = (event) => {
    setAddItemData({
      ...addItemData,
      [event.target.name]: event.target.value,
    });
  };

  console.log(bulkInventoryData);

  const bulkInventoryDataUpdateHandle = async () => {
    try {
      // Set the loader to true at the start
      setLoader(true);

      const response = await fetch(
        // http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/inventories/store
        `${process.env.REACT_APP_BASE_URL}/api/v1/inventories/store`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
          body: JSON.stringify(bulkInventoryData),
        }
      );

      if (response.ok) {
        // Request was successful
        // Update loader state to false

        // Perform necessary actions when the button is clicked
        // navigate("/inventory");
        addModalButtonClickHandler();

        // Parse the JSON response
        const jsonResponse = await response.json();
        console.log("Post request successful:", jsonResponse);

        window.location.reload();
        // Reset the bulkInventoryData state
        setBulkInventoryData({
          property_id: property_id.id,
          from_date: "",
          available_rooms: "",
          update_type: "",
          room_price: "",
        });
        setLoader(false);
        // Reload the page
      } else {
        console.log("Post request failed with status:", response.status);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Ensure the loader is set to false in case of an error
      setLoader(false);
    }
  };

  const [selectedDatePickerDate, setSelectedDatePickerDate] =
    useState(formattedDate);
  console.log(selectedDatePickerDate, "selectedDatePickerDate");
  const formatDate = (date) => {
    if (!date) return "";
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed, so we add 1
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  // console.log(formatDate);

  const CustomInput = ({ onClick }) => (
    <div onClick={onClick} className="dateRangePicker">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <g clip-path="url(#clip0_1270_814)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.625 2H11.75V1.25C11.75 0.835786 11.4142 0.5 11 0.5C10.5858 0.5 10.25 0.835786 10.25 1.25V2H5V1.25C5 0.835786 4.66421 0.5 4.25 0.5C3.83579 0.5 3.5 0.835786 3.5 1.25V2H2.375C1.775 2 1.25 2.525 1.25 3.125V14.375C1.25 14.975 1.775 15.5 2.375 15.5H13.625C14.225 15.5 14.75 14.975 14.75 14.375V3.125C14.75 2.525 14.225 2 13.625 2ZM2.75 14H13.25V6.5H2.75V14Z"
            fill="#888888"
          />
        </g>
        <defs>
          <clipPath id="clip0_1270_814">
            <rect
              width="15"
              height="15"
              fill="white"
              transform="translate(0.5 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );

  const handleDateChange = (date) => {
    setSelectedDatePickerDate(formatDate(date));
    console.log(formatDate(date));
    fetchInfoWithDate(property_id.id, formatDate(date));
  };

  const handleIncrementDate = () => {
    if (selectedDatePickerDate) {
      const newDate = new Date(selectedDatePickerDate);
      newDate.setDate(newDate.getDate() + 1);
      setSelectedDatePickerDate(formatDate(newDate));
      console.log(formatDate(newDate));
      fetchInfoWithDate(property_id.id, formatDate(newDate));
    }
  };

  const handleDecrementDate = () => {
    if (selectedDatePickerDate) {
      const newDate = new Date(selectedDatePickerDate);
      newDate.setDate(newDate.getDate() - 1);
      setSelectedDatePickerDate(formatDate(newDate));
      console.log(formatDate(newDate));
      fetchInfoWithDate(property_id.id, formatDate(newDate));
    }
  };

  return (
    <>
      <SidebarInnerLists innerLists={rooms_inner_lists} />

      {/* {loader ? (
        <Loader />
      ) : ( */}
      <>
        {data && data.date_range && data.date_range.length > 0 && (
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div className="room-type-heading">
                Manage Inventory, Rates & Restrictions
              </div>
              <div>
                <Link style={{ textDecoration: "none" }} to={`/lowinventory`}>
                  <BgThemeButton children={"Low Inventory Dates"} />
                </Link>
              </div>
            </div>

            <div className="inventory-full-container">
              <div
                style={{
                  borderBottom: "1px solid #ccc",
                  paddingBottom: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="inventory-first-row-head">
                  <div
                    onClick={() => setPurchaseHeading(1)}
                    className={`booking-first-row-head-divs ${
                      purchaseHeadingActiveIndex === 1 ? "active" : ""
                    }`}
                  >
                    Website
                  </div>
                  <div
                    onClick={() => setPurchaseHeading(2)}
                    className={`booking-first-row-head-divs ${
                      purchaseHeadingActiveIndex === 2 ? "active" : ""
                    }`}
                  >
                    Tripadvisor
                  </div>
                  <div
                    onClick={() => setPurchaseHeading(3)}
                    className={`booking-first-row-head-divs ${
                      purchaseHeadingActiveIndex === 3 ? "active" : ""
                    }`}
                  >
                    Makemytrip
                  </div>
                  <div
                    onClick={() => setPurchaseHeading(4)}
                    className={`booking-first-row-head-divs ${
                      purchaseHeadingActiveIndex === 4 ? "active" : ""
                    }`}
                  >
                    Goibibo
                  </div>
                  <div
                    onClick={() => setPurchaseHeading(5)}
                    className={`booking-first-row-head-divs ${
                      purchaseHeadingActiveIndex === 5 ? "active" : ""
                    }`}
                  >
                    Agoda
                  </div>
                  <div
                    onClick={() => setPurchaseHeading(6)}
                    className={`booking-first-row-head-divs ${
                      purchaseHeadingActiveIndex === 6 ? "active" : ""
                    }`}
                  >
                    OYO
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <BgOutlineThemeButton
                      onClick={addModalButtonClickHandler}
                      children={"Bulk Updates"}
                    />
                  </div>
                  <div className="dateRangePickerContainer">
                    <button
                      className="dateRangePickerButtonone"
                      onClick={handleDecrementDate}
                      disabled={!selectedDatePickerDate}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="7"
                        height="10"
                        viewBox="0 0 7 10"
                        fill="none"
                      >
                        <path
                          d="M6 1L2 5L6 9"
                          stroke="#888888"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </button>
                    <DatePicker
                      selected={
                        selectedDatePickerDate
                          ? new Date(selectedDatePickerDate)
                          : null
                      }
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM-dd" // Use the desired date format
                      customInput={<CustomInput />}
                    />
                    <button
                      onClick={handleIncrementDate}
                      disabled={!selectedDatePickerDate}
                      className="dateRangePickerButtontwo"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="7"
                        height="10"
                        viewBox="0 0 7 10"
                        fill="none"
                      >
                        <path
                          d="M1 9L5 5L0.999999 1"
                          stroke="#888888"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div className="inventory-second-column-2div">
                <div
                  style={{
                    borderRadius: "6px",
                  }}
                >
                  <div
                    style={{
                      background: "#EFF0F2",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      padding: "5px 33px",
                      borderBottom: "0.5px solid #CCC",
                    }}
                  >
                    <div
                      style={{
                        color: "#333",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                    >
                      Room & Rates
                    </div>

                    <div
                      className="inventory-dates"
                      style={{ display: "flex", gap: "39px" }}
                    >
                      {data?.date_range?.map((date) => {
                        return (
                          <div
                            onClick={() => dateClickHandler(date)}
                            className="inventory-dates-div"
                            style={{
                              padding: "10px 26px",
                              width: "80px",
                              borderRadius: "4px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <div className="inventory-upper-date">
                              {date.slice(8, 10)}
                            </div>

                            <div className="inventory-upper-month">
                              {months[parseInt(date.slice(5, 7), 10) - 1]}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    style={{
                      borderRadius: "6px",
                      background: "#EFF0F2",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      padding: "5px 33px",
                    }}
                  >
                    <div
                      style={{
                        color: "#888",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontWeight: "600",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div>Competitor Rates</div>
                      <div style={{ display: "flex", marginLeft: "5px" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M12.5999 6.9999C12.5999 3.9073 10.0925 1.3999 6.9999 1.3999C3.9073 1.3999 1.3999 3.9073 1.3999 6.9999C1.3999 10.0925 3.9073 12.5999 6.9999 12.5999C10.0925 12.5999 12.5999 10.0925 12.5999 6.9999ZM6.2999 9.0999C6.2999 9.4865 6.6133 9.7999 6.9999 9.7999C7.3865 9.7999 7.6999 9.4865 7.6999 9.0999V7.6999C7.6999 7.3133 7.3865 6.9999 6.9999 6.9999C6.6133 6.9999 6.2999 7.3133 6.2999 7.6999V9.0999ZM6.2999 4.8999C6.2999 5.2865 6.6133 5.5999 6.9999 5.5999C7.3865 5.5999 7.6999 5.2865 7.6999 4.8999C7.6999 4.5133 7.3865 4.1999 6.9999 4.1999C6.6133 4.1999 6.2999 4.5133 6.2999 4.8999Z"
                            fill="#888888"
                          />
                        </svg>
                      </div>
                    </div>

                    <div style={{ display: "flex", gap: "39px" }}>
                      {data?.date_range?.map((date) => {
                        return (
                          <div
                            style={{
                              // padding: "10px 26px",
                              width: "80px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <div className="inventory-INRPrice">
                              INR 3360.00
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="inventory-third-column-3div">
                <div style={{ marginRight: "7px" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1_1380)">
                      <path
                        d="M11.3189 0H2.6811C1.20037 0 0 1.20037 0 2.6811V11.3189C0 12.7996 1.20037 14 2.6811 14H11.3189C12.7996 14 14 12.7996 14 11.3189V2.6811C14 1.20037 12.7996 0 11.3189 0Z"
                        fill="#3968ED"
                      />
                      <path
                        d="M10.5866 7H3.41333"
                        stroke="white"
                        strokeMiterlimit="10"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_1380">
                        <rect width="14" height="14" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div>Hide Rates</div>
                <div></div>
              </div>

              {data?.rooms?.map((room, index) => {
                console.log(room, "room");
                return (
                  <div className="inventory-fourth-column-4div">
                    <div
                      style={{
                        borderRadius: "6px",
                        border: "0.5px solid #CCC",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          padding: "0 0 0 22px",
                          borderBottom: "0.5px solid #CCC",
                        }}
                      >
                        <div
                          style={{
                            color: "#333",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: "600",
                            width: "58%",
                          }}
                        >
                          <div
                            className="inventory-third-column-3div"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div
                              style={{ display: "flex", cursor: "pointer" }}
                              onClick={() => handleAccordionClick(index)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  marginRight: "7px",
                                }}
                              >
                                {activeIndexes.includes(index) ? (
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                    >
                                      <g clip-path="url(#clip0_1270_876)">
                                        <path
                                          d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                          stroke="#3968ED"
                                        />
                                        <path
                                          d="M10.5866 7H3.41333"
                                          stroke="#3968ED"
                                          strokeMiterlimit="10"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_1270_876">
                                          <rect
                                            width="14"
                                            height="14"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </>
                                ) : (
                                  <>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="14"
                                      height="14"
                                      viewBox="0 0 14 14"
                                      fill="none"
                                    >
                                      <g clip-path="url(#clip0_1270_1017)">
                                        <path
                                          d="M2.6811 0.5H11.3189C12.5235 0.5 13.5 1.47651 13.5 2.6811V11.3189C13.5 12.5235 12.5235 13.5 11.3189 13.5H2.6811C1.47651 13.5 0.5 12.5235 0.5 11.3189V2.6811C0.5 1.47651 1.47651 0.5 2.6811 0.5Z"
                                          stroke="#3968ED"
                                        />
                                        <path
                                          d="M10.5866 7H3.41333"
                                          stroke="#3968ED"
                                          strokeMiterlimit="10"
                                        />
                                        <path
                                          d="M7 3.41344L7 10.5867"
                                          stroke="#3968ED"
                                          strokeMiterlimit="10"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_1270_1017">
                                          <rect
                                            width="14"
                                            height="14"
                                            fill="white"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </>
                                )}
                              </div>
                              <div className="inventory-roomName">
                                {room.room_name}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                gap: "10px",
                              }}
                            >
                              <div className="updates-rates-button">
                                Update Rates
                              </div>
                              <div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                >
                                  <g clip-path="url(#clip0_1_1562)">
                                    <path
                                      d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
                                      fill="#DAE3FC"
                                    />
                                    <path
                                      d="M5.54088 4.01685L8.50242 6.97838L5.4978 9.983"
                                      stroke="#3968ED"
                                      strokeMiterlimit="10"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1_1562">
                                      <rect
                                        width="14"
                                        height="14"
                                        fill="white"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div style={{ display: "flex" }}>
                          {data?.rooms[0]?.inventory
                            ?.slice(0, 7)
                            .map((data) => {
                              return (
                                <div
                                  style={{
                                    width: "120px",
                                    height: "60px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    // border: "0.5px solid #CCC",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      flexDirection: "column",
                                      gap: "5px",
                                    }}
                                  >
                                    <input
                                      value={data.available_rooms}
                                      className="inventory-1st-column-inner-input"
                                      style={{ width: "40px" }}
                                      type="text"
                                    />
                                    <div className="inventory-1st-column-inner-input-bottom-text">
                                      {data.sold_rooms} Sold / {data.hold_rooms}{" "}
                                      Hold
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>

                      {/* inner div */}

                      {activeIndexes.includes(index) && (
                        <>
                          {Object.keys(room.rates).map((rateType, index) => {
                            console.log(rateType);
                            return (
                              <div key={rateType}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    padding: "0 0 0 18px",
                                    borderBottom: "0.5px solid #CCC",
                                    height: expandedItems.includes(rateType)
                                      ? "60px"
                                      : "100%",
                                    overflow: "hidden",
                                    transition: "height 0.5s ease-in-out",
                                  }}
                                >
                                  <div
                                    onClick={() => togglePlanExpand(rateType)}
                                    style={{
                                      color: "#333",
                                      fontFamily: "Inter",
                                      fontSize: "12px",
                                      fontWeight: "600",
                                      width: "100%",
                                    }}
                                  >
                                    <div style={{}}>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div className="inventory-third-column-3div">
                                          <div>
                                            <div
                                              style={{
                                                display: "flex",
                                                marginRight: "7px",
                                                alignItems: "center",
                                              }}
                                            >
                                              <div style={{ display: "flex" }}>
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  width="14"
                                                  height="14"
                                                  viewBox="0 0 14 14"
                                                  fill="none"
                                                >
                                                  <path
                                                    d="M10.5866 7H3.41333"
                                                    stroke="#3968ED"
                                                    strokeMiterlimit="10"
                                                  />
                                                </svg>
                                              </div>
                                              <div>{rateType}</div>
                                            </div>
                                          </div>
                                          <div></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div>{room.rat}</div> */}

                                  <div style={{ display: "flex" }}>
                                    <div>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          flexDirection: "column",
                                        }}
                                      >
                                        {dummyRoomPlansName.map((roomType) => {
                                          return (
                                            <div
                                              style={{
                                                width: "120px",
                                                height: "60px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                border: "0.5px solid #CCC",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                  flexDirection: "column",
                                                }}
                                              >
                                                <div>{roomType.svg}</div>
                                                <div
                                                  style={{
                                                    color: "#888",
                                                    fontSize: "12px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  {roomType.name}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>

                                    {Object.keys(room.rates[rateType]).map(
                                      (date) => {
                                        return (
                                          <>
                                            <div key={date}>
                                              {room.rates[rateType][date].map(
                                                (rate) => {
                                                  return (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent:
                                                          "center",
                                                        flexDirection: "column",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          flexDirection:
                                                            "column",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            display: "flex",
                                                            flexDirection:
                                                              "column",
                                                          }}
                                                        >
                                                          <>
                                                            <div
                                                              style={{
                                                                width: "120px",
                                                                height: "60px",
                                                                display: "flex",
                                                                justifyContent:
                                                                  "center",
                                                                alignItems:
                                                                  "center",
                                                                flexDirection:
                                                                  "column",
                                                                border:
                                                                  "0.5px solid #CCC",
                                                              }}
                                                            >
                                                              <div
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  justifyContent:
                                                                    "center",
                                                                  alignItems:
                                                                    "center",
                                                                  flexDirection:
                                                                    "column",
                                                                  gap: "5px",
                                                                }}
                                                              >
                                                                <input
                                                                  value={
                                                                    rate.rate_bar_price
                                                                  }
                                                                  className="inventory-1st-column-inner-input"
                                                                  style={{
                                                                    width:
                                                                      "55px",
                                                                  }}
                                                                  type="text"
                                                                />
                                                              </div>
                                                            </div>
                                                            <div
                                                              style={{
                                                                width: "120px",
                                                                height: "60px",
                                                                display: "flex",
                                                                justifyContent:
                                                                  "center",
                                                                alignItems:
                                                                  "center",
                                                                flexDirection:
                                                                  "column",
                                                                border:
                                                                  "0.5px solid #CCC",
                                                              }}
                                                            >
                                                              <div
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  justifyContent:
                                                                    "center",
                                                                  alignItems:
                                                                    "center",
                                                                  flexDirection:
                                                                    "column",
                                                                  gap: "5px",
                                                                }}
                                                              >
                                                                <input
                                                                  value={
                                                                    rate.extra_adult
                                                                  }
                                                                  className="inventory-1st-column-inner-input"
                                                                  style={{
                                                                    width:
                                                                      "55px",
                                                                  }}
                                                                  type="text"
                                                                />
                                                              </div>
                                                            </div>
                                                            <div
                                                              style={{
                                                                width: "120px",
                                                                height: "60px",
                                                                display: "flex",
                                                                justifyContent:
                                                                  "center",
                                                                alignItems:
                                                                  "center",
                                                                flexDirection:
                                                                  "column",
                                                                border:
                                                                  "0.5px solid #CCC",
                                                              }}
                                                            >
                                                              <div
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  justifyContent:
                                                                    "center",
                                                                  alignItems:
                                                                    "center",
                                                                  flexDirection:
                                                                    "column",
                                                                  gap: "5px",
                                                                }}
                                                              >
                                                                <input
                                                                  value={
                                                                    rate.child_with_Bed
                                                                  }
                                                                  className="inventory-1st-column-inner-input"
                                                                  style={{
                                                                    width:
                                                                      "55px",
                                                                  }}
                                                                  type="text"
                                                                />
                                                              </div>
                                                            </div>
                                                            <div
                                                              style={{
                                                                width: "120px",
                                                                height: "60px",
                                                                display: "flex",
                                                                justifyContent:
                                                                  "center",
                                                                alignItems:
                                                                  "center",
                                                                flexDirection:
                                                                  "column",
                                                                border:
                                                                  "0.5px solid #CCC",
                                                              }}
                                                            >
                                                              <div
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  justifyContent:
                                                                    "center",
                                                                  alignItems:
                                                                    "center",
                                                                  flexDirection:
                                                                    "column",
                                                                  gap: "5px",
                                                                }}
                                                              >
                                                                <input
                                                                  value={
                                                                    rate.child_without_Bed
                                                                  }
                                                                  className="inventory-1st-column-inner-input"
                                                                  style={{
                                                                    width:
                                                                      "55px",
                                                                  }}
                                                                  type="text"
                                                                />
                                                              </div>
                                                            </div>
                                                          </>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    // <div key={rate.id}>
                                                    //   <p>
                                                    //     Rate Plan Name:{" "}
                                                    //     {rate.rate_plan_name}
                                                    //   </p>
                                                    //   <p>
                                                    //     Rate Plan Type:{" "}
                                                    //     {rate.rate_plan_type}
                                                    //   </p>
                                                    //   <p>
                                                    //     Rate Rack Price:{" "}
                                                    //     {rate.rate_rack_price}
                                                    //   </p>
                                                    // </div>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </>
                                        );
                                      }
                                    )}
                                  </div>

                                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <>
                          <div
                            style={{
                              width: "120px",
                              height: "60px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              border: "0.5px solid #CCC",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                gap: "5px",
                              }}
                            >
                              <input
                                value="500"
                                className="inventory-1st-column-inner-input"
                                style={{ width: "55px" }}
                                type="text"
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              width: "120px",
                              height: "60px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              border: "0.5px solid #CCC",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                gap: "5px",
                              }}
                            >
                              <input
                                value="500"
                                className="inventory-1st-column-inner-input"
                                style={{ width: "55px" }}
                                type="text"
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              width: "120px",
                              height: "60px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              border: "0.5px solid #CCC",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                gap: "5px",
                              }}
                            >
                              <input
                                value="500"
                                className="inventory-1st-column-inner-input"
                                style={{ width: "55px" }}
                                type="text"
                              />
                            </div>
                          </div>
                        </>
                      </div>
                    </div>
                  </div> */}
                                </div>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {/* Add Button Click */}
        {showAddModelMenuActive && (
          <>
            <div className="bg-backdrop">
              <form
                onSubmit={(e) => e.preventDefault()}
                style={{ width: "700px" }}
                className="stock-add-model-container"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div className="add-ota-model-container-heading">
                      Bulk Update
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      Bulk changes will be update for only weekdays
                    </div>
                  </div>
                  <div
                    onClick={() => setShowAddModelMenuActive(false)}
                    style={{ cursor: "pointer" }}
                  >
                    <Icon source={CancelMajor} color="base" />
                  </div>
                </div>

                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <div
                      style={{
                        color: "#333",
                        fontFamily: "Inter",
                        fontSize: "16px",
                        fontWeight: "600",
                        marginBottom: "10px",
                      }}
                    >
                      What you want to update?
                    </div>

                    <div
                      style={{
                        display: "flex",
                        gap: "13px",
                        color: "#333",
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      <input
                        type="radio"
                        name="update_type"
                        onChange={setBulkInventoryHandleChange}
                        value="room"
                      />
                      Room
                      <input
                        type="radio"
                        name="update_type"
                        value="rates"
                        onChange={setBulkInventoryHandleChange}
                      />
                      Rates
                      <input
                        type="radio"
                        value="both"
                        name="update_type"
                        onChange={setBulkInventoryHandleChange}
                      />
                      Both
                    </div>
                  </div>
                </div>
                <div className="form-container-div-half">
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Room"
                      name="available_rooms"
                      type="number"
                      value={bulkInventoryData.available_rooms}
                      onChange={setBulkInventoryHandleChange}
                    />
                  </div>
                  <div className="form-container-div">
                    <VerticalInputFieldCustom
                      required={true}
                      titleName="Rates"
                      type="number"
                      name="room_price"
                      value={bulkInventoryData.room_price}
                      onChange={setBulkInventoryHandleChange}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "start",
                      gap: "10px",
                    }}
                  >
                    <BgThemeButton
                      onClick={bulkInventoryDataUpdateHandle}
                      type="submit"
                      children={"Update"}
                    />
                    <Button onClick={() => setShowAddModelMenuActive(false)}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </>
        )}
      </>
      {/* )} */}
    </>
  );
};

export default Inventory;
