import {
  TextField,
  IndexTable,
  LegacyCard,
  IndexFilters,
  useSetIndexFiltersMode,
  useIndexResourceState,
  Text,
  ChoiceList,
  RangeSlider,
  Badge,
  Avatar,
  Button,
  Icon,
  Select,
} from "@shopify/polaris";

import NoDataFound from "./CustomComponents/NoDataFound";
import { DeleteMajor, CancelMajor } from "@shopify/polaris-icons";
import { useState, useCallback, useEffect } from "react";
import logo from "../assets/images/room_type.png";
import { Link, useNavigate } from "react-router-dom";
import { BgOutlineThemeButton, BgThemeButton } from "./UI/Buttons";
import CustomCssComponentTableText from "./CustomComponents/CustomCssComponentTableText";
import { useSelector } from "react-redux";
import Loader from "./UI/Loader/Loader";
import AddCsvFile from "./UI/AddCsvFile";
import VerticalInputFieldCustom from "./CustomComponents/VerticalInputFieldCustom";
import VerticalInputSelectCustomCopy from "./CustomComponents/VerticalInputSelectCustomCopy";
import { showErrorToast, showSuccessToast } from "../assets/toastUtils";

const PlanFeatures = () => {
  const [showLowerMenuSingleSelect, setShowLowerMenuSingleSelect] =
    useState(false);

  const [showLowerMenuMultipleSelect, setShowLowerMenuMultipleSelect] =
    useState(false);

  const [showModelMenuActive, setShowModelMenuActive] = useState(false);
  // const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);
  const [showAddMapsMenuActive, setShowAddMapsMenuActive] = useState(false);
  const [showEditMapsMenuActive, setEditShowAddMapsMenuActive] =
    useState(false);

  const [showAddModelMenuActive, setShowAddModelMenuActive] = useState(false);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const [itemStrings, setItemStrings] = useState([
    "All",
    "Unpaid",
    "Open",
    "Closed",
    "Local delivery",
    "Local pickup",
  ]);

  const deleteView = (index) => {
    const newItemStrings = [...itemStrings];
    newItemStrings.splice(index, 1);
    setItemStrings(newItemStrings);
    setSelected(0);
  };

  const duplicateView = async (name) => {
    setItemStrings([...itemStrings, name]);
    setSelected(itemStrings.length);
    await sleep(1);
    return true;
  };

  const userToken = JSON.parse(localStorage.getItem("userToken"));
  const tabs = itemStrings.map((item, index) => ({
    content: item,
    index,
    onAction: () => {},
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions:
      index === 0
        ? []
        : [
            {
              type: "rename",
              onAction: () => {},
              onPrimaryAction: async (value) => {
                const newItemsStrings = tabs.map((item, idx) => {
                  if (idx === index) {
                    return value;
                  }
                  return item.content;
                });
                await sleep(1);
                setItemStrings(newItemsStrings);
                return true;
              },
            },
            {
              type: "duplicate",
              onPrimaryAction: async (value) => {
                await sleep(1);
                duplicateView(value);
                return true;
              },
            },
            {
              type: "edit",
            },
            {
              type: "delete",
              onPrimaryAction: async () => {
                await sleep(1);
                deleteView(index);
                return true;
              },
            },
          ],
  }));
  const [selected, setSelected] = useState(0);
  const onCreateNewView = async (value) => {
    await sleep(500);
    setItemStrings([...itemStrings, value]);
    setSelected(itemStrings.length);
    return true;
  };
  const sortOptions = [
    { label: "Order", value: "order asc", directionLabel: "Ascending" },
    { label: "Order", value: "order desc", directionLabel: "Descending" },
    { label: "Customer", value: "customer asc", directionLabel: "A-Z" },
    { label: "Customer", value: "customer desc", directionLabel: "Z-A" },
    { label: "Date", value: "date asc", directionLabel: "A-Z" },
    { label: "Date", value: "date desc", directionLabel: "Z-A" },
    { label: "Total", value: "total asc", directionLabel: "Ascending" },
    { label: "Total", value: "total desc", directionLabel: "Descending" },
  ];
  const [sortSelected, setSortSelected] = useState(["order asc"]);
  const { mode, setMode } = useSetIndexFiltersMode();
  const onHandleCancel = () => {};

  const onHandleSave = async () => {
    await sleep(1);
    return true;
  };

  const primaryAction =
    selected === 0
      ? {
          type: "save-as",
          onAction: onCreateNewView,
          disabled: false,
          loading: false,
        }
      : {
          type: "save",
          onAction: onHandleSave,
          disabled: false,
          loading: false,
        };
  const [accountStatus, setAccountStatus] = useState(undefined);
  const [moneySpent, setMoneySpent] = useState(undefined);
  const [taggedWith, setTaggedWith] = useState("");
  const [queryValue, setQueryValue] = useState("");

  const handleAccountStatusChange = useCallback(
    (value) => setAccountStatus(value),
    []
  );
  const handleMoneySpentChange = useCallback(
    (value) => setMoneySpent(value),
    []
  );
  const handleTaggedWithChange = useCallback(
    (value) => setTaggedWith(value),
    []
  );
  const handleFiltersQueryChange = useCallback(
    (value) => setQueryValue(value),
    []
  );
  const handleAccountStatusRemove = useCallback(
    () => setAccountStatus(undefined),
    []
  );
  const handleMoneySpentRemove = useCallback(
    () => setMoneySpent(undefined),
    []
  );
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(""), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(""), []);
  const handleFiltersClearAll = useCallback(() => {
    handleAccountStatusRemove();
    handleMoneySpentRemove();
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [
    handleAccountStatusRemove,
    handleMoneySpentRemove,
    handleQueryValueRemove,
    handleTaggedWithRemove,
  ]);

  const [channelUniqueId, setChannelUniqueId] = useState("");
  const [secretKey, setSecretKey] = useState();

  const addCompetitorsRatesHandler = (id, secret_key) => {
    setSecretKey(secret_key);
    clearSelection();
    setChannelUniqueId(id);
    setShowAddMapsMenuActive(true);
  };

  const editCompetitorsRatesHandler = async (id, secret_key) => {
    setSecretKey(secret_key);
    clearSelection();
    setChannelUniqueId(id);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/edit/module/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();

      if (data.success) {
        setEditShowAddMapsMenuActive(true);
        setAddChannelData(data.data);
        setSelectedPermissions(data.permissions);
        console.log(data.channel);
      } else {
        showErrorToast(data.message);
      }

      console.log(data.details);
    } catch (error) {
      console.error(error);
    }
  };

  const filters = [
    {
      key: "accountStatus",
      label: "Account status",
      filter: (
        <ChoiceList
          title="Account status"
          titleHidden
          choices={[
            { label: "Enabled", value: "enabled" },
            { label: "Not invited", value: "not invited" },
            { label: "Invited", value: "invited" },
            { label: "Declined", value: "declined" },
          ]}
          selected={accountStatus || []}
          onChange={handleAccountStatusChange}
          allowMultiple
        />
      ),
      shortcut: true,
    },
    {
      key: "taggedWith",
      label: "Tagged with",
      filter: (
        <TextField
          label="Tagged with"
          value={taggedWith}
          onChange={handleTaggedWithChange}
          autoComplete="off"
          labelHidden
        />
      ),
      shortcut: true,
    },
    {
      key: "moneySpent",
      label: "Money spent",
      filter: (
        <RangeSlider
          label="Money spent is between"
          labelHidden
          value={moneySpent || [0, 500]}
          prefix="$"
          output
          min={0}
          max={2000}
          step={1}
          onChange={handleMoneySpentChange}
        />
      ),
    },
  ];

  // roles

  const [options, setOptions] = useState([
    {
      id: 1,
      name: "Reservations",
      permission: [
        {
          id: "create_reservations",
          label: "Create reservations",
          value: "create_reservations",
        },
        {
          id: "update_reservations",
          label: "Update reservations",
          value: "update_reservations",
        },
        {
          id: "delete_reservations",
          label: "Delete reservations",
          value: "delete_reservations",
        },
        {
          id: "read_reservations",
          label: "Read reservations",
          value: "read_reservations",
        },
        //new permission
        {
          id: "read_cancel_booking",
          label: "Cancel Reservation",
          value: "read_cancel_booking",
        },
        //old permissions
        {
          id: "show_single_booking",
          label: "Show single booking",
          value: "show_single_booking",
        },
        {
          id: "show_preview_bill",
          label: "Show preview booking",
          value: "show_preview_bill",
        },
        {
          id: "read_preview_voucher",
          label: "Read Preview Voucher",
          value: "read_preview_voucher",
        },
        ,
      ],
    },
    {
      id: 2,
      name: "Dashboard",
      permission: [
        {
          id: "update_dashboard",
          label: "Update dashboard",
          value: "update_dashboard",
        },
        {
          id: "read_dashboard",
          label: "Read dashboard",
          value: "read_dashboard",
        },
      ],
    },
    {
      id: 3,
      name: "Rooms",
      permission: [
        { id: "create_rooms", label: "Create rooms", value: "create_rooms" },
        { id: "update_rooms", label: "Update rooms", value: "update_rooms" },
        { id: "delete_rooms", label: "Delete rooms", value: "delete_rooms" },
        { id: "read_rooms", label: "Read rooms", value: "read_rooms" },
        { id: "assign_rooms", label: "Assign rooms", value: "assign_rooms" }, //new
        { id: "change_rooms", label: "Change rooms", value: "change_rooms" }, //new
      ],
    },
    //checked: true
    {
      id: 4,
      name: "Rooms Type",
      permission: [
        {
          id: "read_room_types",
          label: "Read room types",
          value: "read_room_types",
        },
        {
          id: "update_room_types",
          label: "Update room types",
          value: "update_room_types",
        },
        {
          id: "delete_room_types",
          label: "Delete room types",
          value: "delete_room_types",
        },
        {
          id: "create_room_types",
          label: "Create room types",
          value: "create_room_types",
        },
        //new permission
        {
          id: "change_room_types",
          label: "change room types",
          value: "change_room_types",
        },
      ],
    },
    // checked: true
    {
      id: 5,
      name: "Customers",
      permission: [
        //old permissions
        {
          id: "create_customers",
          label: "Create customers",
          value: "create_customers",
        },
        {
          id: "update_customers",
          label: "Update customers",
          value: "update_customers",
        },
        {
          id: "delete_customers",
          label: "Delete customers",
          value: "delete_customers",
        },
        {
          id: "read_customers",
          label: "Read customers",
          value: "read_customers",
        },
        {
          id: "edit_customers",
          label: "Edit Customers",
          value: "edit_customers",
        },
      ],
    },
    //checked: true
    {
      id: 6,
      name: "Inventory",
      permission: [
        {
          id: "read_inventory",
          label: "Read inventory",
          value: "read_inventory",
        },

        //  old permission
        //   {
        //     id: "read_bulk_inventory",
        //     label: "Read bulk_inventory",
        //     value: "read_bulk_inventory",
        //   },
        //   {
        //     id: "read_block_inventory",
        //     label: "Read block inventory",
        //     value: "read_block_inventory",
        //   },
        //   {
        //     id: "read_unhold_inventory",
        //     label: "Read unhold inventory",
        //     value: "read_unhold_inventory",
        //   },
        //   {
        //     id: "read_unblock_inventory",
        //     label: "Read unblock inventory",
        //     value: "read_unblock_inventory",
        //   },
        //   {
        //     id: "read_hold_inventory",
        //     label: "Read hold inventory",
        //     value: "read_hold_inventory",
        //   },
        //   {
        //     id: "update_hold_inventory",
        //     label: "Update hold inventory",
        //     value: "update_hold_inventory",
        //   },
        //   {
        //     id: "update_bulk_inventory",
        //     label: "Update bulk inventory",
        //     value: "update_bulk_inventory",
        //   },
        //   {
        //     id: "update_unhold_inventory",
        //     label: "Update unhold inventory",
        //     value: "update_unhold_inventory",
        //   },
        //   {
        //     id: "update_unblock_inventory",
        //     label: "Update unblock inventory",
        //     value: "update_unblock_inventory",
        //   },
        //   {
        //     id: "update_block_inventory",
        //     label: "Update block inventory",
        //     value: "update_block_inventory",
        //   },

        //new permission
        {
          id: "update_inventory",
          label: "Update inventory",
          value: "update_inventory",
        },
      ],
    },
    {
      id: 7,
      name: "Room Rate Plans",
      permission: [
        {
          id: "create_room_rate_plan",
          label: "Create room rate_plans",
          value: "create_room_rate_plan",
        },
        {
          id: "update_room_rate_plan",
          label: "Update read room_rate_plans",
          value: "update_room_rate_plan",
        },
        {
          id: "delete_room_rate_plan",
          label: "Delete read room rate plans",
          value: "delete_room_rate_plan",
        },
        {
          id: "read_room_rate_plan",
          label: "Read room rate plans",
          value: "read_room_rate_plan",
        },
      ],
    },
    //checked: true (with meal plan)
    {
      id: 8,
      name: "Meal Plans",
      permission: [
        {
          id: "create_meal_plans",
          label: "Add Meal Plans",
          value: "create_meal_plans",
        },
        {
          id: "update_meal_plans",
          label: "Update Meal Plans",
          value: "update_meal_plans",
        },
        {
          id: "delete_meal_plans",
          label: "Delete Meal Plans",
          value: "delete_meal_plans",
        },
        {
          id: "read_meal_plans",
          label: "Read Meal Plans",
          value: "read_meal_plans",
        },
      ],
    },

    //   //checked: true (not added in sheet)
    {
      id: 9,
      name: "Suppliers",
      permission: [
        {
          id: "create_suppliers",
          label: "Create suppliers",
          value: "create_suppliers",
        },
        {
          id: "update_suppliers",
          label: "Update suppliers",
          value: "update_suppliers",
        },
        {
          id: "delete_suppliers",
          label: "Delete suppliers",
          value: "delete_suppliers",
        },
        {
          id: "read_suppliers",
          label: "Read suppliers",
          value: "read_suppliers",
        },
      ],
    },

    //checked: true
    {
      id: 10,
      name: "Purchases",
      permission: [
        {
          id: "create_purchases",
          label: "Create purchases",
          value: "create_purchases",
        },
        {
          id: "update_purchases",
          label: "Update purchases",
          value: "update_purchases",
        },
        {
          id: "delete_purchases",
          label: "Delete purchases",
          value: "delete_purchases",
        },
        {
          id: "read_purchases",
          label: "Read purchases",
          value: "read_purchases",
        },
        {
          id: "issue_item_purchases",
          label: "Issue item purchases",
          value: "issue_item_purchases",
        },
      ],
    },
    //checked: true
    {
      id: 11,
      name: "Expenses",
      permission: [
        {
          id: "create_expenses",
          label: "Create expenses",
          value: "create_expenses",
        },
        {
          id: "update_expenses",
          label: "Update expenses",
          value: "update_expenses",
        },
        {
          id: "delete_expenses",
          label: "Delete expenses",
          value: "delete_expenses",
        },
        {
          id: "read_expenses",
          label: "Read expenses",
          value: "read_expenses",
        },
        //new permission
        { id: "edit_expenses", label: "Edit expenses", value: "edit_expenses" },
      ],
    },
    //checked: true
    {
      id: 12,
      name: "KOT",
      permission: [
        { id: "create_kot", label: "Create KOT", value: "create_kot" },
        { id: "update_kot", label: "Update KOT", value: "update_kot" },
        // { id: "delete_kot", label: "Delete KOT", value: "delete_kot" }, //old permission
        { id: "read_kot", label: "Read KOT", value: "read_kot" },
        { id: "view_kot_bill", label: "View KOT Bill", value: "view_kot_bill" }, //old permission
        {
          id: "generate_kot_bill",
          label: "Generate KOT Bill", //old permission
          value: "generate_kot_bill",
        },
        {
          id: "cancel_kot_bill",
          label: "Cancel KOT Bill",
          value: "cancel_kot_bill",
        },
      ],
    },
    //   not in sheet
    {
      id: 13,
      name: "Food Sale",
      permission: [
        {
          id: "read_food_sale",
          label: "Read Food Sale",
          value: "read_food_sale",
        },
        {
          id: "create_food_sale",
          label: "Create Food Sale",
          value: "create_food_sale",
        },
        {
          id: "settle_bill_food_sale",
          label: "Settle bill food sale",
          value: "settle_bill_food_sale",
        },
        {
          id: "view_bill_food_sale",
          label: "View bill food sale",
          value: "view_bill_food_sale",
        },
        {
          id: "generate_bill_food_sale",
          label: "Generate bill food sale",
          value: "generate_bill_food_sale",
        },
      ],
    },
    //new permissions that are in sheet but not in permission.js
    {
      id: 14,
      name: "Check In",
      permission: [
        {
          id: "check_in",
          label: "Check In Guest",
          value: "check_in",
        },
        { id: "late_check_in", label: "Late Check In", value: "late_check_in" },
        {
          id: "early_check_in",
          label: "Early Check In",
          value: "early_check_in",
        },
      ],
    },
    {
      id: 15,
      name: "Check Out",
      permission: [
        {
          id: "check_out",
          label: "Check Out Guest",
          value: "check_out",
        },
        {
          id: "late_check_out",
          label: "Late Check Out",
          value: "late_check_out",
        },
        {
          id: "early_check_out",
          label: "Early Check out",
          value: "early_check_out",
        },
      ],
    },
    {
      id: 16,
      name: "Inventory",
      permission: [
        {
          id: "read_inventory",
          label: "Read Inventory",
          value: "read_inventory",
        },
        {
          id: "update_inventory",
          label: "Update Inventory",
          value: "update_inventory",
        },
      ],
    },
    {
      id: 17,
      name: "Bulk Inventory Updates",
      permission: [
        {
          id: "read_bulk_inventory",
          label: "Read Bulk Updates",
          value: "read_bulk_inventory",
        },
        {
          id: "update_bulk_inventory",
          label: "Update Bulk Updates",
          value: "update_bulk_inventory",
        },
      ],
    },
    {
      id: 18,
      name: "Low Inventory Dates",
      permission: [
        {
          id: "read_low_inventory_dates",
          label: "Read Low Inventory Dates",
          value: "read_low_inventory_dates",
        },
        {
          id: "update_low_inventory_dates",
          label: "Update Low Inventory Dates",
          value: "update_low_inventory_dates",
        },
      ],
    },
    {
      id: 19,
      name: "Block Inventory",
      permission: [
        {
          id: "read_block_inventory",
          label: "Read Block Inventory",
          value: "read_block_inventory",
        },
        {
          id: "update_block_inventory",
          label: "Update Block Inventory",
          value: "update_block_inventory",
        },
      ],
    },
    {
      id: 20,
      name: "Unblock Inventory",
      permission: [
        {
          id: "read_unblock_inventory",
          label: "Read Unblock Inventory",
          value: "read_unblock_inventory",
        },
        {
          id: "update_unblock_inventory",
          label: "Update Unblock Inventory",
          value: "update_unblock_inventory",
        },
      ],
    },
    {
      id: 21,
      name: "Unhold Inventory",
      permission: [
        {
          id: "read_unhold_inventory",
          label: "Read Unhold Inventory",
          value: "read_unhold_inventory",
        },
        {
          id: "update_unhold_inventory",
          label: "Update Unhold Inventory",
          value: "update_unhold_inventory",
        },
      ],
    },
    {
      id: 22,
      name: "Hold Inventory",
      permission: [
        {
          id: "read_hold_inventory",
          label: "Read Hold Inventory",
          value: "read_hold_inventory",
        },
        {
          id: "update_hold_inventory",
          label: "Update Hold Inventory",
          value: "update_hold_inventory",
        },
      ],
    },
    {
      id: 23,
      name: "Channel Inventory",
      permission: [
        {
          id: "read_channel_inventory",
          label: "Read Channel Inventory",
          value: "read_channel_inventory",
        },
        {
          id: "update_channel_inventory",
          label: "Update Channel Inventory",
          value: "update_channel_inventory",
        },
        {
          id: "read_channel_inventory",
          label: "Read Channel Inventory",
          value: "read_channel_inventory",
        },
        {
          id: "update_channel_inventory",
          label: "Update Channel Inventory",
          value: "update_channel_inventory",
        },
      ],
    },
    {
      id: 24,
      name: "Day Status",
      permission: [
        {
          id: "update_day_status",
          label: "Update Day Status",
          value: "update_day_status",
        },
        {
          id: "read_day_status",
          label: "Read Day Status",
          value: "read_day_status",
        },
      ],
    },
    {
      id: 25,
      name: "Night Audit",
      permission: [
        {
          id: "start_night_audit",
          label: "Start Night Audit",
          value: "start_night_audit",
        },
        {
          id: "generate_night_audit_reports",
          label: "Generate Night Audit Reports",
          value: "generate_night_audit_reports",
        },
      ],
    },
    {
      id: 26,
      name: "Activity Logs",
      permission: [
        {
          id: "read_activity_logs",
          label: "Read Activity Logs",
          value: "read_activity_logs",
        },
      ],
    },
    {
      id: 27,
      name: "OTA Mapping",
      permission: [
        {
          id: "read_ota_mapping",
          label: "Read OTA Mapping",
          value: "read_ota_mapping",
        },
        {
          id: "update_ota_mapping",
          label: "update OTA Mapping",
          value: "update_ota_mapping",
        },
      ],
    },
    {
      id: 28,
      name: "Roles And Permissions",
      permission: [
        {
          id: "create_roles",
          label: "Create Roles",
          value: "create_roles",
        },
        {
          id: "read_roles",
          label: "Read Roles",
          value: "read_roles",
        },
        {
          id: "update_roles",
          label: "Update Roles",
          value: "update_roles",
        },
        {
          id: "delete_roles",
          label: "Delete Roles",
          value: "delete_roles",
        },
        {
          id: "read_permission",
          label: "Read Permissions",
          value: "read_permission",
        },
        {
          id: "update_permission",
          label: "Update Permissions",
          value: "update_permission",
        },
        {
          id: "delete_permission",
          label: "Delete Permissions",
          value: "delete_permission",
        },
      ],
    },
    {
      id: 29,
      name: "Staff",
      permission: [
        {
          id: "read_staff",
          label: "Read Staff",
          value: "read_staff",
        },
        {
          id: "create_staff",
          label: "Create Staff",
          value: "create_staff",
        },
        {
          id: "delete_staff",
          label: "Delete Staff",
          value: "delete_staff",
        },
        {
          id: "update_permission",
          label: "Update Permissions",
          value: "update_permission",
        },
        {
          id: "delete_permission",
          label: "Delete Permissions",
          value: "delete_permission",
        },
      ],
    },
    {
      id: 30,
      name: "Communications",
      permission: [
        {
          id: "create_communications",
          label: "Create Communications",
          value: "create_communications",
        },
        {
          id: "send_communications",
          label: "Send Communications",
          value: "send_communications",
        },
        {
          id: "delete_communications",
          label: "Delete Communications",
          value: "delete_communications",
        },
        {
          id: "read_communications",
          label: "Read Communications",
          value: "read_communications",
        },
        {
          id: "edit_communications",
          label: "Edit Communications",
          value: "edit_communications",
        },
      ],
    },
    {
      id: 31,
      name: "Discount",
      permission: [
        {
          id: "read_discount",
          label: "Read Discount",
          value: "read_discount",
        },
        {
          id: "create_discount",
          label: "Create Discount",
          value: "create_discount",
        },
        {
          id: "delete_discount",
          label: "Delete Discount",
          value: "delete_discount",
        },

        {
          id: "update_discount",
          label: "Update Discount",
          value: "update_discount",
        },
      ],
    },
    {
      id: 32,
      name: "Agents",
      permission: [
        {
          id: "read_agent",
          label: "Read Agent",
          value: "read_agent",
        },
        {
          id: "create_agent",
          label: "Create Agent",
          value: "create_agent",
        },
        {
          id: "delete_agent",
          label: "Delete Agent",
          value: "delete_agent",
        },

        {
          id: "update_agent",
          label: "Update Agent",
          value: "update_agent",
        },
        {
          id: "read_agent_category",
          label: "Read Agent Category",
          value: "read_agent_category",
        },
        {
          id: "create_agent_category",
          label: "Create Agent Category",
          value: "create_agent_category",
        },
        {
          id: "delete_agent_category",
          label: "Delete Agent Category",
          value: "delete_agent_category",
        },

        {
          id: "update_agent_category",
          label: "Update Agent Category",
          value: "update_agent_category",
        },
        {
          id: "create_company_credits",
          label: "Create Company Credits",
          value: "create_company_credits",
        },
        {
          id: "read_company_credits",
          label: "Read Company Credits",
          value: "read_company_credits",
        },
        {
          id: "update_company_credits",
          label: "Update Company Credits",
          value: "update_company_credits",
        },

        {
          id: "delete_company_credits",
          label: "Delete Company Credits",
          value: "delete_company_credits",
        },

        {
          id: "create_advance_payments",
          label: "Create Advance Payments",
          value: "create_advance_payments",
        },
        {
          id: "read_advance_payments",
          label: "Read Advance Payments",
          value: "read_advance_payments",
        },
        {
          id: "update_advance_payments",
          label: "Update Advance Payments",
          value: "update_advance_payments",
        },

        {
          id: "delete_advance_payments",
          label: "Delete Advance Payments",
          value: "delete_advance_payments",
        },
      ],
    },
    {
      id: 33,
      name: "Analytics",
      permission: [
        {
          id: "create_analytics",
          label: "Create Analytics",
          value: "create_analytics",
        },
        {
          id: "update_analytics",
          label: "Update Analytics",
          value: "update_analytics",
        },
        {
          id: "delete_analytics",
          label: "Delete Analytics",
          value: "delete_analytics",
        },
        {
          id: "read_analytics",
          label: "Read Analytics",
          value: "read_analytics",
        },
        {
          id: "edit_analytics",
          label: "Edit Analytics",
          value: "edit_analytics",
        },
        {
          id: "read_daily_activity_reports",
          label: "Read Daily Activity Reports",
          value: "read_daily_activity_reports",
        },
        {
          id: "read_arrival_report",
          label: "Read Arrival Report",
          value: "read_arrival_report",
        },

        {
          id: "read_departure_report",
          label: "Read Departure Report",
          value: "read_departure_report",
        },
        {
          id: "read_in_house_report",
          label: "Read In House Report",
          value: "read_in_house_report",
        },
        {
          id: "read_account_balance_report",
          label: "Read Account Balance Report",
          value: "read_account_balance_report",
        },
        {
          id: "read_cancellation_report",
          label: "Read Cancellation Report",
          value: "read_cancellation_report",
        },

        {
          id: "read_no_show_report",
          label: "Read No Show Report",
          value: "read_no_show_report",
        },
        {
          id: "read_room_assignment_report",
          label: "Read Room Assignment Report",
          value: "read_room_assignment_report",
        },
        {
          id: "read_payment_reconciliation_report",
          label: "Read Payment Reconciliation Report",
          value: "read_payment_reconciliation_report",
        },
        {
          id: "read_daily_revenue_report",
          label: "Read Daily Revenue Report",
          value: "read_daily_revenue_report",
        },

        {
          id: "read_production_reports",
          label: "Read Production Reports",
          value: "read_production_reports",
        },
        {
          id: "read_revpar",
          label: "Read RevPAR",
          value: "read_revpar",
        },
        {
          id: "read_room_sold_occupancy_report",
          label: "Read Room Sold/ Occupancy Report",
          value: "read_room_sold_occupancy_report",
        },
        {
          id: "read_reservation_by_region_report",
          label: "Read Reservation by Region Report",
          value: "read_reservation_by_region_report",
        },

        {
          id: "read_adr_report",
          label: "Read ADR (Average Daily Rate) Report",
          value: "read_adr_report",
        },
        {
          id: "Read Reservation by Room Categories Report",
          label: "Read Reservation by Room Categories Report",
          value: "Read Reservation by Room Categories Report",
        },
        {
          id: "Read Reservation by Plan Report",
          label: "Read Reservation by Plan Report",
          value: "Read Reservation by Plan Report",
        },
        {
          id: "Read Room Occupancy Report",
          label: "Read Room Occupancy Report",
          value: "Read Room Occupancy Report",
        },
        {
          id: "Read Finance Reports",
          label: "Read Finance Reports",
          value: "Read Finance Reports",
        },
        {
          id: "read_adr_report",
          label: "Read ADR (Average Daily Rate) Report",
          value: "read_adr_report",
        },
        {
          id: "read_property_occupancy_report",
          label: "Read Property Occupancy Report",
          value: "read_property_occupancy_report",
        },
        {
          id: "read_payment_ledger_report",
          label: "Read Payment Ledger Report",
          value: "read_payment_ledger_report",
        },
        {
          id: "read_invoice_report",
          label: "Read Invoice Report",
          value: "read_invoice_report",
        },
        {
          id: "read_payout_commission_report",
          label: "Read Payout/ Commission Report",
          value: "read_payout_commission_report",
        },
        {
          id: "read_food_sale_report",
          label: "Read Food Sale Report",
          value: "read_food_sale_report",
        },
        {
          id: "read_kpi_targets_report",
          label: "Read KPI Targets Report",
          value: "read_kpi_targets_report",
        },
        {
          id: "read_competitors_report",
          label: "Read Competitors Report",
          value: "read_competitors_report",
        },
      ],
    },
    {
      id: 34,
      name: "Add stock",
      permission: [
        {
          id: "create_stock",
          label: "Create Stock",
          value: "create_stock",
        },
        {
          id: "read_stock",
          label: "Read Stock",
          value: "read_stock",
        },
        {
          id: "update_stock",
          label: "Update Stock",
          value: "update_stock",
        },
        {
          id: "delete_stock",
          label: "Delete Stock",
          value: "delete_stock",
        },

        {
          id: "edit_stock",
          label: "Edit Stock",
          value: "edit_stock",
        },
      ],
    },
    {
      id: 35,
      name: "Issue Items",
      permission: [
        {
          id: "create_issue_items",
          label: "Create Issue Items",
          value: "create_issue_items",
        },
        {
          id: "read_issue_items",
          label: "Read Issue Items",
          value: "read_issue_items",
        },
        {
          id: "update_issue_items",
          label: "Update Issue Items",
          value: "update_issue_items",
        },
        {
          id: "delete_issue_items",
          label: "Delete Issue Items",
          value: "delete_issue_items",
        },
        {
          id: "edit_issue_items",
          label: "Edit Issue Items",
          value: "edit_issue_items",
        },
      ],
    },
    {
      id: 36,
      name: "Items",
      permission: [
        // { id: "create_agent", label: "Create Agent", value: "create_agent" },
        // { id: "update_agent", label: "Update Agent", value: "update_agent" },
        // { id: "delete_agent", label: "Delete Agent", value: "delete_agent" },
        { id: "read_items", label: "Read Items", value: "read_items" },
      ],
    },
    {
      id: 37,
      name: "Food Menu",
      permission: [
        {
          id: "create_food_menu",
          label: "Create Food Menu",
          value: "create_food_menu",
        },
        {
          id: "read_food_menu",
          label: "Read Food Menu",
          value: "read_food_menu",
        },
        {
          id: "update_food_menu",
          label: "Update Food Menu",
          value: "update_food_menu",
        },
        {
          id: "delete_food_menu",
          label: "Delete Food Menu",
          value: "delete_food_menu",
        },
        {
          id: "create_food_category",
          label: "Create Food Category",
          value: "create_food_category",
        },
        {
          id: "read_food_category",
          label: "Read Food Category",
          value: "read_food_category",
        },
        {
          id: "update_food_category",
          label: "Update Food Category",
          value: "update_food_category",
        },
        {
          id: "delete_food_category",
          label: "Delete Food Category",
          value: "delete_food_category",
        },
      ],
    },
    {
      id: 38,
      name: "Tables",
      permission: [
        {
          id: "create_table",
          label: "Create Table",
          value: "create_table",
        },
        {
          id: "read_table",
          label: "Read Table",
          value: "read_table",
        },
        {
          id: "update_table",
          label: "Update Table",
          value: "update_table",
        },
        {
          id: "delete_table",
          label: "Delete Table",
          value: "delete_table",
        },
        {
          id: "change_table_status",
          label: "Change Table Status",
          value: "change_table_status",
        },
      ],
    },
    {
      id: 39,
      name: "Bill",
      permission: [
        {
          id: "view_bill",
          label: "View Bill",
          value: "view_bill",
        },
        {
          id: "generate_bill",
          label: "Generate Bill",
          value: "generate_bill",
        },
      ],
    },
    {
      id: 40,
      name: "Assign Rooms",
      permission: [
        {
          id: "assign_rooms",
          label: "Assign Rooms",
          value: "assign_rooms",
        },
        {
          id: "update_assign_rooms",
          label: "Update Assign Rooms",
          value: "update_assign_rooms",
        },
        {
          id: "unassign_rooms",
          label: "Unassign Rooms",
          value: "unassign_rooms",
        },
      ],
    },
    {
      id: 41,
      name: "Rooms Under Cleaning",
      permission: [
        {
          id: "read_rooms_under_cleaning",
          label: "Read Rooms Under Cleaning",
          value: "read_rooms_under_cleaning",
        },
        {
          id: "update_rooms_under_cleaning",
          label: "Update Rooms Under Cleaning",
          value: "update_rooms_under_cleaning",
        },
      ],
    },
    {
      id: 99,
      name: "Housekeeping Staff",
      permission: [
        {
          id: "read_housekeeping_staff",
          label: "Read Housekeeping Staff",
          value: "read_housekeeping_staff",
        },
        {
          id: "active_housekeeping_staff",
          label: "Active Housekeeping Staff",
          value: "active_housekeeping_staff",
        },
        {
          id: "inactive_housekeeping_staff",
          label: "Inactive Housekeeping Staff",
          value: "inactive_housekeeping_staff",
        },
      ],
    },
    {
      id: 42,
      name: "Competitors",
      permission: [
        {
          id: "read_competitors",
          label: "Read Competitors",
          value: "read_competitors",
        },
        {
          id: "update_competitors",
          label: "Update Competitors",
          value: "update_competitors",
        },
        {
          id: "delete_competitors",
          label: "Delete Competitors",
          value: "delete_competitors",
        },
        {
          id: "create_competitors",
          label: "Create Competitors",
          value: "create_competitors",
        },
      ],
    },
    {
      id: 43,
      name: "Property Targets",
      permission: [
        {
          id: "read_property_targets",
          label: "Read Property Targets",
          value: "read_property_targets",
        },
        {
          id: "update_property_targets",
          label: "Update Property Targets",
          value: "update_property_targets",
        },
        {
          id: "delete_property_targets",
          label: "Delete Property Targets",
          value: "delete_property_targets",
        },
        {
          id: "create_property_targets",
          label: "Create Property Targets",
          value: "create_property_targets",
        },
      ],
    },
    {
      id: 44,
      name: "Website",
      permission: [
        {
          id: "read_website",
          label: "Read Website",
          value: "read_website",
        },
        {
          id: "update_website",
          label: "Update Website",
          value: "update_website",
        },

        {
          id: "create_website",
          label: "Create Website",
          value: "create_website",
        },
      ],
    },
    {
      id: 45,
      name: "Blog Posts",
      permission: [
        {
          id: "read_blog_posts",
          label: "Read Blog Posts",
          value: "read_blog_posts",
        },
        {
          id: "update_blog_posts",
          label: "Update Blog Posts",
          value: "update_blog_posts",
        },

        {
          id: "create_blog_posts",
          label: "Create Blog Posts",
          value: "create_blog_posts",
        },
        {
          id: "delete_blog_posts",
          label: "Delete Blog Posts",
          value: "delete_blog_posts",
        },
      ],
    },
    {
      id: 98,
      name: "Pages",
      permission: [
        {
          id: "read_pages",
          label: "Read Pages",
          value: "read_pages",
        },
        {
          id: "update_pages",
          label: "Update Pages",
          value: "update_pages",
        },

        {
          id: "create_pages",
          label: "Create Pages",
          value: "create_pages",
        },
        {
          id: "delete_pages",
          label: "Delete Pages",
          value: "delete_pages",
        },
      ],
    },
    {
      id: 46,
      name: "Events",
      permission: [
        {
          id: "read_events",
          label: "Read Events",
          value: "read_events",
        },
        {
          id: "update_events",
          label: "Update Events",
          value: "update_events",
        },

        {
          id: "create_events",
          label: "Create Events",
          value: "create_events",
        },
        {
          id: "delete_events",
          label: "Delete Events",
          value: "delete_events",
        },
        {
          id: "read_event_categories",
          label: "Read Events Categories",
          value: "read_event_categories",
        },
        {
          id: "update_event_categories",
          label: "Update Events Categories",
          value: "update_event_categories",
        },
        {
          id: "create_event_categories",
          label: "Create Events Categories",
          value: "create_event_categories",
        },
        {
          id: "delete_event_categories",
          label: "Delete Events Categories",
          value: "delete_event_categories",
        },
        {
          id: "read_banquet_hall",
          label: "Read Banquet Hall",
          value: "read_banquet_hall",
        },
        {
          id: "update_banquet_hall",
          label: "Update Banquet Hall",
          value: "update_banquet_hall",
        },

        {
          id: "create_banquet_hall",
          label: "Create Banquet Hall",
          value: "create_banquet_hall",
        },
        {
          id: "delete_banquet_hall",
          label: "Delete Banquet Hall",
          value: "delete_banquet_hall",
        },
        {
          id: "read_banquet_menus",
          label: "Read Banquet Menu",
          value: "read_banquet_menus",
        },
        {
          id: "update_banquet_menus",
          label: "Update Banquet Menu",
          value: "update_banquet_menus",
        },
        {
          id: "create_banquet_menus",
          label: "Create Banquet Menu",
          value: "create_banquet_menus",
        },
        {
          id: "delete_banquet_menus",
          label: "Delete Banquet Menu",
          value: "delete_banquet_menus",
        },
      ],
    },
    {
      id: 47,
      name: "Competitor Analysis",
      permission: [
        {
          id: "create_competitors_analysis",
          label: "Create Competitor Analysis",
          value: "create_competitors_analysis",
        },
        {
          id: "update_competitors_analysis",
          label: "Update Competitor Analysis",
          value: "update_competitors_analysis",
        },
        {
          id: "delete_competitors_analysis",
          label: "Delete Competitor Analysis",
          value: "delete_competitors_analysis",
        },
        {
          id: "read_competitors_analysis",
          label: "Read Competitor Analysis",
          value: "read_competitors_analysis",
        },
        {
          id: "edit_competitors_analysis",
          label: "Edit Competitor Analysis",
          value: "edit_competitors_analysis",
        },
      ],
    },
    {
      id: 48,
      name: "Banquet Menu Categories",
      permission: [
        {
          id: "create_banquet_menu_categories",
          label: "Create Banquet Menu Categories",
          value: "create_banquet_menu_categories",
        },
        {
          id: "update_banquet_menu_categories",
          label: "Update Banquet Menu Categories",
          value: "update_banquet_menu_categories",
        },
        {
          id: "delete_banquet_menu_categories",
          label: "Delete Banquet Menu Categories",
          value: "delete_banquet_menu_categories",
        },
        {
          id: "read_banquet_menu_categories",
          label: "Read Banquet Menu Categories",
          value: "read_banquet_menu_categories",
        },
        {
          id: "edit_banquet_menu_categories",
          label: "Edit Banquet Menu Categories",
          value: "edit_banquet_menu_categories",
        },
      ],
    },
    {
      id: 49,
      name: "Discounts",
      permission: [
        {
          id: "create_discounts",
          label: "Create Discounts",
          value: "create_discounts",
        },
        {
          id: "update_discounts",
          label: "Update Discounts",
          value: "update_discounts",
        },
        {
          id: "delete_discounts",
          label: "Delete Discounts",
          value: "delete_discounts",
        },
        {
          id: "read_discounts",
          label: "Read Discounts",
          value: "read_discounts",
        },
        {
          id: "edit_discounts",
          label: "Edit Discounts",
          value: "edit_discounts",
        },
      ],
    },
    {
      id: 50,
      name: "Settle Bill",
      permission: [
        {
          id: "create_settle_bill",
          label: "Create Settle Bill",
          value: "create_settle_bill",
        },
        {
          id: "update_settle_bill",
          label: "Update Settle Bill",
          value: "update_settle_bill",
        },
        {
          id: "delete_settle_bill",
          label: "Delete Settle Bill",
          value: "delete_settle_bill",
        },
        {
          id: "read_settle_bill",
          label: "Read Settle Bill",
          value: "read_settle_bill",
        },
        {
          id: "edit_settle_bill",
          label: "Edit Settle Bill",
          value: "edit_settle_bill",
        },
      ],
    },
    {
      id: 51,
      name: "Settings",
      permission: [
        // { id: "create_agent", label: "Create Agent", value: "create_agent" },
        // { id: "update_agent", label: "Update Agent", value: "update_agent" },
        // { id: "delete_agent", label: "Delete Agent", value: "delete_agent" },
        { id: "read_settings", label: "Read Settings", value: "read_settings" },
        {
          id: "update_settings",
          label: "Update Settings",
          value: "update_settings",
        }, //new permission
      ],
    },
  ]);

  const [selectedOptions, setSelectedOptions] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (id) => {
    setActiveAccordion((prevId) => (prevId === id ? null : id));
    toggleIconRotation(id);
  };

  const toggleIconRotation = (id) => {
    const icon = document.getElementById(`icon-${id}`);
    if (icon) {
      icon.classList.toggle("rotateicon");
    }
  };

  const handleCheckboxChange = (optionId) => {
    setSelectedOptions((prevSelectedOptions) => {
      const optionSelectedPermissions = prevSelectedOptions[optionId] || [];

      // Check if all permission are already selected
      const allPermissionsSelected =
        optionSelectedPermissions.length ===
        options.find((opt) => opt.id === optionId).permission.length;

      const updatedPermissions = allPermissionsSelected
        ? [] // If all permission are already selected, clear the array
        : options
            .find((opt) => opt.id === optionId)
            .permission.map((permission) => permission.value);

      return {
        ...prevSelectedOptions,
        [optionId]: updatedPermissions,
      };
    });
  };

  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    setSelectedPermissions(sendDataInDesiredFormat(selectedOptions));
  }, [selectedOptions]);

  const sendDataInDesiredFormat = (selectedOptions) => {
    const permissionValues = [];

    for (const optionId in selectedOptions) {
      if (selectedOptions.hasOwnProperty(optionId)) {
        if (optionId !== "create_reservation") {
          const selectedPermissions = selectedOptions[optionId];
          if (Array.isArray(selectedPermissions)) {
            selectedPermissions.forEach((permission) => {
              permissionValues.push(permission);
            });
          }
        }
      }
    }

    // console.log(permissionValues);

    return permissionValues;
  };

  // const selectedPermissions = sendDataInDesiredFormat(selectedOptions);

  const handlePermissionChange = (optionId, permissionId) => {
    setSelectedOptions((prevSelectedOptions) => {
      const optionSelectedPermissions = prevSelectedOptions[optionId] || [];
      const updatedPermissions = optionSelectedPermissions.includes(
        permissionId
      )
        ? optionSelectedPermissions.filter((perm) => perm !== permissionId)
        : [...optionSelectedPermissions, permissionId];

      return {
        ...prevSelectedOptions,
        [optionId]: updatedPermissions,
      };
    });
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedOptions({});
    } else {
      const allSelected = {};
      options.forEach((option) => {
        allSelected[option.id] = option.permission.map(
          (permission) => permission.value
        );
      });
      setSelectedOptions(allSelected);
    }
    setSelectAll(!selectAll);
  };

  const appliedFilters = [];
  if (accountStatus && !isEmpty(accountStatus)) {
    const key = "accountStatus";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, accountStatus),
      onRemove: handleAccountStatusRemove,
    });
  }
  if (moneySpent) {
    const key = "moneySpent";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, moneySpent),
      onRemove: handleMoneySpentRemove,
    });
  }
  if (!isEmpty(taggedWith)) {
    const key = "taggedWith";
    appliedFilters.push({
      key,
      label: disambiguateLabel(key, taggedWith),
      onRemove: handleTaggedWithRemove,
    });
  }
  const manageButton = <BgOutlineThemeButton children={"Manage"} />;

  const [orders, setOrders] = useState([]);
  const resourceName = {
    singular: "order",
    plural: "orders",
  };

  // Fetch Info
  const property_id = useSelector((state) => state.property.value);
  // console.log(property_id.id);

  const [loader, setLoader] = useState(true);

  const fetchInfo = async () => {
    // console.log(id, "propertyId");
    try {
      setLoader(true);
      const response = await fetch(
        //{{development}}/api/v1/admin/channels/list
        `${process.env.REACT_APP_BASE_URL}/api/v1/all/modules`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      const data = await response.json();
      // console.log(data, "data");
      setOrders(data.data);
      // console.log(data.data.data);
      setLoader(false);
      // console.log(data.rooms);
    } catch (error) {
      console.error(error);
      setLoader(false);
    }
  };

  const [properties, setProperties] = useState([]);

  useEffect(() => {
    fetchInfo();
    console.log("fetching");
  }, []);

  setTimeout(() => {
    setLoader(false);
  }, 2000);

  const propertiesOptions = properties?.map((property) => {
    return { label: property.property_name, value: property.id };
  });
  // console.log(propertiesOptions);

  const {
    selectedResources,
    allResourcesSelected,
    handleSelectionChange,
    clearSelection,
  } = useIndexResourceState(orders);

  useEffect(() => {
    if (selectedResources.length === 1) {
      setShowLowerMenuSingleSelect(true);
      setShowLowerMenuMultipleSelect(false);
    } else if (selectedResources.length > 1) {
      setShowLowerMenuMultipleSelect(true);
      setShowLowerMenuSingleSelect(false);
    } else {
      setShowLowerMenuSingleSelect(false);
      setShowLowerMenuMultipleSelect(false);
    }
  }, [selectedResources]);

  const addModalButtonClickHandler = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
    // setShowLowerMenuSingleSelect(false);
    // setShowLowerMenuMultipleSelect(false);(!showLowerMenuAddMenu);
  };

  const removeAddModalContainer = () => {
    setShowAddModelMenuActive(!showAddModelMenuActive);
  };

  // UseState for forms
  const [addMapsData, setAddMapsData] = useState({
    property_id: "",
    channel_unique_id: "",
    property_room_id: "",
    channel_room_id: "",
    property_rate_plan_id: "",
    channel_rate_plan_id: "",

    // competitor_id: channelUniqueId,
  });

  const setAddItemRateDataHandleChange = (event) => {
    setAddMapsData({
      ...addMapsData,
      [event.target.name]: event.target.value,
    });
  };

  const showModelMenu = (id) => {
    setDeletedId(id);
    setShowModelMenuActive(!showModelMenuActive);
  };

  const rowMarkup = orders.map(
    ({
      id,
      average_rate,
      competitor_name,
      hotel_category,
      channel_name,
      index,
      module_description,
      module_name,
      secret_key,
    }) => (
      <IndexTable.Row
        id={id}
        key={id}
        selected={selectedResources.includes(id)}
        position={index}
      >
        <IndexTable.Cell>
          {<CustomCssComponentTableText innerText={""} />}
        </IndexTable.Cell>
        <IndexTable.Cell>
          {<CustomCssComponentTableText innerText={module_name} />}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <div className="truncate-desc-modules">

          {module_description}
          </div>
          {/* {<CustomCssComponentTableText innerText={module_description} />} */}
        </IndexTable.Cell>
        <IndexTable.Cell>
          <button
            style={{
              padding: "5px",
              fontSize: "14px",
              color: "white",
              fontWeight: "500",
              backgroundColor: "#3968ed",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              zIndex: "99999999",
            }}
            onClick={() => navigate(`/edit/module/${id}`)}
          >
            Edit
          </button>
        </IndexTable.Cell>
        <IndexTable.Cell>
          <button
            style={{
              padding: "5px",
              fontSize: "14px",
              color: "white",
              fontWeight: "500",
              backgroundColor: "#c30909",
              borderRadius: "5px",
              border: "none",
              cursor: "pointer",
              zIndex: "99999999",
            }}
            onClick={() => showModelMenu(id)}
          >
            Delete
          </button>
        </IndexTable.Cell>
        {/* <IndexTable.Cell>{<CustomCssComponentTableText innerText={manageButton} />}</IndexTable.Cell> */}
      </IndexTable.Row>
    )
  );

  const [selectedPropertyRooms, setSelectedPropertyRooms] = useState(null);

  const [propertyId, setPropertyId] = useState("");
  const onPropertyChangeFunc = (e) => {
    setPropertyId(e.target.value);
    // console.log(e.target.value);
    e.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/admin/properties/rooms/${e.target.value}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setSelectedPropertyRooms(data.rooms);

        if (data.success) {
          setAddMapsData({
            ...addMapsData,
            [e.target.name]: e.target.value,
          });
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const roomOptions = selectedPropertyRooms?.map((room) => {
    return { label: room.room_name, value: room.id };
  });
  // console.log(roomOptions, "roomOptions");

  const [roomId, setRoomId] = useState("");
  const [selectedRoomRates, setSelectedRoomRates] = useState(null);

  const onRoomChangeFunc = (e) => {
    setRoomId(e.target.value);
    // console.log(e.target.value);
    e.preventDefault();

    if (addMapsData.channel_unique_id === "") {
      showErrorToast("Please enter the channel ID");
      return;
    }

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/admin/properties/rooms/rates/${e.target.value}/${propertyId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setSelectedRoomRates(data.roomRates);

        if (data.success) {
          setAddMapsData({
            ...addMapsData,
            [e.target.name]: e.target.value,
          });
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const onChannelIdChangeBlur = () => {
    const proxyUrl =
      "https://bookingjini.com/pms/hotel-details.php?key=3ac8497f-3a21-4925-b5de-08af72cdc5fd&hotel_id=1953";
    fetch(proxyUrl, {
      method: "GET",
      // mode: "no-cors",
      headers: {
        "Content-Type": "application/json",
      },
      // redirect: "follow",
      // referrerPolicy: "no-referrer",
    })
      .then((response) => response.json())
      .then((data) => {
        setSelectedRoomRates(data.roomRates);
        // if (data.success) {
        //   setAddMapsData({
        //     ...addMapsData,
        //     channel_room_id: 1,
        //   });
        // } else {
        //   showErrorToast(data.message);
        // }
      })
      .catch((error) => {
        // console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const rateOptions = selectedRoomRates?.map((room) => {
    // console.log(room);
    return { label: room.rate_plan_name, value: room.id };
  });

  // console.log(roomOptions);

  const removeItemConfirm = () => {
    setShowModelMenuActive(!showModelMenuActive);
    // console.log("removed");
  };

  // Plan Type Options
  const hotelCategoryOptions = [
    { label: "Standard", value: "Standard" },
    { label: "Luxury", value: "Luxury" },
  ];

  const [hotelCategoryTypeSelected, setHotelCategoryPlanTypeSelected] =
    useState("EP");
  const hotelCategoryTypeHandleSelectChange = useCallback(
    (value) => setHotelCategoryPlanTypeSelected(value),
    []
  );

  // Plan Name Options
  const planNameOptions = [
    { label: "Room Only", value: "newestUpdate" },
    { label: "Room + Complimentary Breakfast", value: "oldestUpdate" },
    {
      label: "Room with Complimentary Breakfast and dinner",
      value: "mostSpent",
    },
    { label: "Breakfast + Lunch + Dinner", value: "mostOrders" },
  ];

  const [planNameSelected, setNameTypeSelected] = useState("EP");
  const planNameHandleSelectChange = useCallback(
    (value) => setNameTypeSelected(value),
    []
  );

  // Plan Name Options
  const planStatusOptions = [
    { label: "Booked", value: "Booked" },
    {
      label: "Available",
      value: "Available",
    },
  ];

  const [planStatusSelected, setPlanStatusSelected] = useState("Booked");
  const planStatusHandleSelectChange = useCallback(
    (value) => setPlanStatusSelected(value),
    []
  );

  // Plan Type Options
  const roomTypeOptions = [
    { label: "Deluxe", value: "Deluxe" },
    { label: "Super Deluxe", value: "Super Deluxe" },
    { label: "Cottage", value: "Cottage" },
    { label: "Luxury", value: "Luxury" },
    { label: "Executive", value: "Executive" },
    { label: "Family", value: "Family" },
  ];

  const [roomTypeSelected, setRoomTypeSelected] = useState("EP");
  const roomTypeHandleSelectChange = useCallback(
    (value) => setRoomTypeSelected(value),
    []
  );

  // UseState for forms
  const [addChannelData, setAddChannelData] = useState({
    module_name: "",
    module_description: "",
  });

  const setAddItemDataHandleChange = (event) => {
    setAddChannelData({
      ...addChannelData,
      [event.target.name]: event.target.value,
    });
  };
  // console.log(competitorsData);

  const deletedIds = {
    ids: selectedResources,
  };

  const [deletedId, setDeletedId] = useState("");

  const deleteData = async () => {
    clearSelection();
    // http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/competitors/delete
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/delete/module`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: JSON.stringify({ id: deletedId }),
      }
    );

    const data = await response.json();
    console.log(data);

    if (data.success) {
      fetchInfo();
      clearSelection();
      setShowLowerMenuMultipleSelect(false);
      setShowModelMenuActive(false);
    } else {
      showErrorToast(data.message);
    }
    // now do whatever you want with the data
    // console.log(data);
    // fetchInfo();
    // clearSelection();
    // setShowLowerMenuMultipleSelect(false);
    // setShowModelMenuActive(false);
  };

  const navigate = useNavigate();

  const submitChannelData = (e) => {
    e.preventDefault();

    if (
      addChannelData.module_name === "" ||
      addChannelData.module_description === ""
    ) {
      showErrorToast("Please fill the above name..!");
      return;
    }

    if (selectedPermissions.length < 1) {
      showErrorToast("Please select at least one permission");
      return;
    }

    // {{development}}}/api/v1/admin/channel/create
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/create/module`, {
      method: "POST",
      body: JSON.stringify({
        ...addChannelData,
        permission: selectedPermissions,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);

        if (data.success) {
          fetchInfo();
          showSuccessToast("Channel added Successfully");
          setShowAddModelMenuActive(false);
        } else {
          showErrorToast(data.message);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showErrorToast("An error occurred while processing your request.");
      });
  };

  const handleSubmitAddMeals = (event) => {
    event.preventDefault();
    // console.log(channelUniqueId, "channelUniqueId channelUniqueId");
    // http://phplaravel-634084-3806003.cloudwaysapps.com/api/v1/assign/rooms/store
    console.log({
      ...addMapsData,
    });
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/admin/map/channels`, {
      method: "POST",
      body: JSON.stringify({
        ...addMapsData,
        channel_id: channelUniqueId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setShowAddMapsMenuActive(false);
          // setAddRateData({
          //   rate: "",
          //   rate_date: "",
          // });
        } else {
          showErrorToast(data.message);
        }
      });
  };

  const handleUpdateAddMeals = (event) => {
    event.preventDefault();
    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/update/module/${channelUniqueId}`,
      {
        method: "POST",
        body: JSON.stringify(addChannelData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          showSuccessToast(data.message);
          setEditShowAddMapsMenuActive(false);
        } else {
          showErrorToast(data.message);
        }
      });
  };
  console.log(addChannelData);

  return (
    <>
      {loader ? (
        <Loader />
      ) : (
        <div className="main_container">
          <div className="room-types-full-container">
            <div className="room-types-full-container-div">
              <div className="room-type-heading">Module</div>
              <div style={{ display: "flex", gap: "10px" }}>
                {showLowerMenuSingleSelect && (
                  <Button plain destructive onClick={showModelMenu}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <Icon source={DeleteMajor} color="base" />
                      </span>
                      <span>Remove</span>
                    </div>
                  </Button>
                )}

                {showLowerMenuMultipleSelect && (
                  <Button plain destructive onClick={showModelMenu}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span>
                        <Icon source={DeleteMajor} color="base" />
                      </span>
                      <span>Remove</span>
                    </div>
                  </Button>
                )}

                <BgThemeButton
                  onClick={addModalButtonClickHandler}
                  children={"Add Module"}
                />
              </div>
            </div>

            {orders && orders.length > 0 ? (
              <LegacyCard>
                <IndexTable
                  resourceName={resourceName}
                  itemCount={orders.length}
                  selectedItemsCount={
                    allResourcesSelected ? "All" : selectedResources.length
                  }
                  selectable={false}
                  onSelectionChange={handleSelectionChange}
                  headings={[
                    { title: "" },
                    { title: "Name" },
                    { title: "Description" },
                    // { title: "Average Rates" },
                    // { title: "" },
                  ]}
                >
                  {rowMarkup}
                </IndexTable>

                {/* Single select */}
                {showLowerMenuSingleSelect && (
                  <div className=" room-type-bottom-container">
                    <div className="room-type-bottom-container-div multipleroom-delete-btn-div">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          gap: "20px",
                        }}
                      >
                        <Button plain destructive onClick={showModelMenu}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <Icon source={DeleteMajor} color="base" />
                            </span>
                            <span>Remove</span>
                          </div>
                        </Button>
                        {/* <Link
                    style={{ textDecoration: "none" }}
                    to={`/competitors/manage`}
                  >
                    <BgOutlineThemeButton children={"Manage"} />
                  </Link> */}
                      </div>
                    </div>
                  </div>
                )}

                {/* Multiple select */}
                {showLowerMenuMultipleSelect && (
                  <div className=" room-type-bottom-container">
                    <div className="room-type-bottom-container-div multipleroom-delete-btn-div">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Button plain destructive onClick={showModelMenu}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <Icon source={DeleteMajor} color="base" />
                            </span>
                            <span>Remove</span>
                          </div>
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </LegacyCard>
            ) : (
              <NoDataFound />
            )}
          </div>

          {/* Edit Button Click */}
          {showEditMapsMenuActive && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <div className="add-ota-model-container-heading">
                      Edit Module
                    </div>
                    <div
                      onClick={() => setEditShowAddMapsMenuActive(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Module Name"
                        name="module_name"
                        type="text"
                        value={addChannelData.module_name}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Description"
                        name="module_description"
                        type="text"
                        value={addChannelData.module_description}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                  </div>

                  <>
                    <div className="permission_container">
                      <div
                        className="permission_heading"
                        style={{ padding: "10px" }}
                      >
                        Module Permission
                      </div>
                      <div className="permission_checkBox_container  ">
                        <label>
                          <input
                            style={{ marginRight: "10px", marginLeft: "10px" }}
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                          Select All
                        </label>
                      </div>
                      <div className="permission_item-accords">
                        {options.map((option) => (
                          <div
                            // onClick={() => toggleAccordion(option.id)}
                            style={{ padding: "10px" }}
                            key={option.id}
                            className={`accordion-item ${
                              activeAccordion === option.id ? "active" : ""
                            }`}
                          >
                            <div
                              // style={{ cursor: "pointer" }}
                              className="addstaff_accordion-title"
                              onClick={() => toggleAccordion(option.id)}
                              style={{
                                backgroundColor: "rgba(235, 236, 239, 1)",
                                cursor: "pointer",
                                width: "100%",
                                padding: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={
                                    selectedOptions[option.id] &&
                                    selectedOptions[option.id].length ===
                                      option.permission.length
                                  }
                                  onChange={() =>
                                    handleCheckboxChange(option.id)
                                  }
                                />
                                <span
                                  style={{
                                    marginLeft: "10px",
                                    fontSize: "14px",
                                  }}
                                >
                                  {option.name}
                                </span>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                <div>
                                  (
                                  {selectedOptions[option.id]
                                    ? selectedOptions[option.id].length
                                    : 0}
                                  /{option.permission.length})
                                </div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="8"
                                  viewBox="0 0 12 8"
                                  fill="none"
                                >
                                  <path
                                    d="M11 1.5L6 6.5L0.999999 1.5"
                                    stroke="#888888"
                                    strokeWidth="1.5"
                                  />
                                </svg>
                              </div>
                            </div>
                            <div className="addstaff_accordion-content">
                              {activeAccordion === option.id && (
                                <div>
                                  {option.permission.map((permission) => (
                                    <div
                                      key={permission.id}
                                      className="addstaff_secondCkeckbox_container"
                                    >
                                      <label
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          cursor: "pointer",
                                          width: "fit-content",
                                        }}
                                        key={permission.id}
                                      >
                                        <input
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          type="checkbox"
                                          checked={
                                            selectedPermissions &&
                                            selectedPermissions.includes(
                                              permission.value
                                            )
                                          }
                                          onChange={() =>
                                            handlePermissionChange(
                                              option.id,
                                              permission.value
                                            )
                                          }
                                          className="addstaff_secondCkeckbox"
                                        />
                                        <div>{permission.label}</div>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        onClick={(e) => handleUpdateAddMeals(e)}
                        type="submit"
                        children={"Update"}
                      />
                      <Button
                        onClick={() => {
                          setEditShowAddMapsMenuActive(false);
                          setAddChannelData({});
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
          {/* Add Button Click */}
          {showAddModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <div className="add-ota-model-container-heading">
                      Add Module
                    </div>
                    <div
                      onClick={() => setShowAddModelMenuActive(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Module Name"
                        name="module_name"
                        type="text"
                        value={addChannelData.module_name}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Description"
                        name="module_description"
                        type="text"
                        value={addChannelData.module_description}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div>
                    {/* <div className="form-container-div">
                      <VerticalInputFieldCustom
                        required={true}
                        titleName="Limit"
                        name="limit"
                        type="text"
                        value={addChannelData.limit}
                        onChange={setAddItemDataHandleChange}
                      />
                    </div> */}
                  </div>

                  <>
                    <div className="permission_container">
                      <div
                        className="permission_heading"
                        style={{ padding: "10px" }}
                      >
                        Module Permission
                      </div>
                      <div className="permission_checkBox_container  ">
                        <label>
                          <input
                            style={{ marginRight: "10px", marginLeft: "10px" }}
                            type="checkbox"
                            checked={selectAll}
                            onChange={handleSelectAll}
                          />
                          Select All
                        </label>
                      </div>
                      <div className="permission_item-accords">
                        {options.map((option) => (
                          <div
                            // onClick={() => toggleAccordion(option.id)}
                            style={{ padding: "10px" }}
                            key={option.id}
                            className={`accordion-item ${
                              activeAccordion === option.id ? "active" : ""
                            }`}
                          >
                            <div
                              // style={{ cursor: "pointer" }}
                              className="addstaff_accordion-title"
                              onClick={() => toggleAccordion(option.id)}
                              style={{
                                backgroundColor: "rgba(235, 236, 239, 1)",
                                cursor: "pointer",
                                width: "100%",
                                padding: "10px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <input
                                  type="checkbox"
                                  checked={
                                    selectedOptions[option.id] &&
                                    selectedOptions[option.id].length ===
                                      option.permission.length
                                  }
                                  onChange={() =>
                                    handleCheckboxChange(option.id)
                                  }
                                />
                                <span
                                  style={{
                                    marginLeft: "10px",
                                    fontSize: "14px",
                                  }}
                                >
                                  {option.name}
                                </span>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "8px",
                                }}
                              >
                                <div>
                                  (
                                  {selectedOptions[option.id]
                                    ? selectedOptions[option.id].length
                                    : 0}
                                  /{option.permission.length})
                                </div>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="8"
                                  viewBox="0 0 12 8"
                                  fill="none"
                                >
                                  <path
                                    d="M11 1.5L6 6.5L0.999999 1.5"
                                    stroke="#888888"
                                    strokeWidth="1.5"
                                  />
                                </svg>
                              </div>
                              {/* <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "8px",
                            }}
                            className={`selectboxicon ${
                              activeAccordion === option.id ? "rotateicon" : ""
                            }`}
                            id={`icon-${option.id}`}
                          >
                            <div>
                              (
                              {selectedOptions[option.id]
                                ? selectedOptions[option.id].length
                                : 0}
                              /{option.permission.length})
                            </div>
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="8"
                                viewBox="0 0 12 8"
                                fill="none"
                              >
                                <path
                                  d="M11 1.5L6 6.5L0.999999 1.5"
                                  stroke="#888888"
                                  strokeWidth="1.5"
                                />
                              </svg>
                            </div>
                          </div> */}
                            </div>
                            <div className="addstaff_accordion-content">
                              {activeAccordion === option.id && (
                                <div>
                                  {option.permission.map((permission) => (
                                    <div
                                      key={permission.id}
                                      className="addstaff_secondCkeckbox_container"
                                    >
                                      <label
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          cursor: "pointer",
                                          width: "fit-content",
                                        }}
                                        key={permission.id}
                                      >
                                        <input
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          type="checkbox"
                                          checked={
                                            selectedOptions[option.id] &&
                                            selectedOptions[option.id].includes(
                                              permission.value
                                            )
                                          }
                                          onChange={() =>
                                            handlePermissionChange(
                                              option.id,
                                              permission.value
                                            )
                                          }
                                          className="addstaff_secondCkeckbox"
                                        />
                                        <div>{permission.label}</div>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        onClick={(e) => submitChannelData(e)}
                        type="submit"
                        children={"Add"}
                      />
                      <Button onClick={() => setShowAddModelMenuActive(false)}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}

          {/* Add Rates Click */}
          {showAddMapsMenuActive && (
            <>
              <div className="bg-backdrop">
                <form
                  onSubmit={(e) => handleSubmitAddMeals(e)}
                  style={{ width: "700px" }}
                  className="stock-add-model-container"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "15px",
                    }}
                  >
                    <div className="add-ota-model-container-heading">
                      Mapping
                    </div>
                    <div
                      onClick={() => setShowAddMapsMenuActive(false)}
                      style={{ cursor: "pointer" }}
                    >
                      <Icon source={CancelMajor} color="base" />
                    </div>
                  </div>

                  <div className="form-container-div-half">
                    <div className="form-container-div">
                      <div className="form-container-div">
                        <div style={{ marginBottom: "4px" }}>
                          Properties
                          <span style={{ color: "red" }}>*</span>
                        </div>
                        <select
                          // value={addMapsData.property_id}
                          name="property_id"
                          style={{
                            width: "100%",
                            minHeight: "2.25rem",
                            border: "1px solid rgba(171, 177, 186, 1)",
                            borderRadius: "3px",
                          }}
                          onChange={(e) => onPropertyChangeFunc(e)}
                        >
                          <option value="">Select Option</option>
                          {propertiesOptions &&
                            propertiesOptions?.map((x) => {
                              // console.log(x);
                              return (
                                <>
                                  <option key={x.value} value={x.value}>
                                    {x.label}
                                  </option>
                                </>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="form-container-div">
                      <VerticalInputFieldCustom
                        onBlur={(e) => onChannelIdChangeBlur(e)}
                        titleName="Channel Id"
                        name="channel_unique_id"
                        type="number"
                        value={addMapsData.channel_unique_id}
                        onChange={setAddItemRateDataHandleChange}
                      />
                    </div>
                  </div>

                  {roomOptions && roomOptions.length > 0 && (
                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <div className="form-container-div">
                          <div style={{ marginBottom: "4px" }}>
                            Rooms
                            <span style={{ color: "red" }}>*</span>
                          </div>
                          <select
                            name="property_room_id"
                            // value={addMapsData.property_room_id}
                            style={{
                              width: "100%",
                              minHeight: "2.25rem",
                              border: "1px solid rgba(171, 177, 186, 1)",
                              borderRadius: "3px",
                            }}
                            onChange={(e) => onRoomChangeFunc(e)}
                          >
                            <option value="">Select Option</option>
                            {roomOptions &&
                              roomOptions?.map((x) => {
                                return (
                                  <>
                                    <option key={x.value} value={x.value}>
                                      {x.label}
                                    </option>
                                  </>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          titleName="Channel Room Id"
                          name="channel_room_id"
                          type="number"
                          value={addMapsData.channel_room_id}
                          onChange={setAddItemRateDataHandleChange}
                        />
                      </div>
                    </div>
                  )}

                  {rateOptions && rateOptions.length > 0 && (
                    <div className="form-container-div-half">
                      <div className="form-container-div">
                        <div className="form-container-div">
                          <div style={{ marginBottom: "4px" }}>
                            Rate
                            <span style={{ color: "red" }}>*</span>
                          </div>
                          <select
                            name="property_rate_plan_id"
                            // value={addMapsData.property_rate_plan_id}
                            style={{
                              width: "100%",
                              minHeight: "2.25rem",
                              border: "1px solid rgba(171, 177, 186, 1)",
                              borderRadius: "3px",
                            }}
                            onChange={setAddItemRateDataHandleChange}
                          >
                            <option value="">Select Option</option>
                            {rateOptions &&
                              rateOptions?.map((x) => {
                                return (
                                  <>
                                    <option key={x.value} value={x.value}>
                                      {x.label}
                                    </option>
                                  </>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="form-container-div">
                        <VerticalInputFieldCustom
                          titleName="Channel Rate Id"
                          name="channel_rate_plan_id"
                          type="number"
                          value={addMapsData.channel_rate_plan_id}
                          onChange={setAddItemRateDataHandleChange}
                        />
                      </div>
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      <BgThemeButton
                        // onClick={() => console.log(addMapsData)}
                        type="submit"
                        children={"Add"}
                      />
                      <Button onClick={() => setShowAddMapsMenuActive(false)}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
          {showModelMenuActive && (
            <>
              <div className="bg-backdrop">
                <section className="room-type-model-container ">
                  <h4>Are You Sure?</h4>
                  <p>You Want To Remove?</p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Button
                      onClick={() =>
                        setShowModelMenuActive(!showModelMenuActive)
                      }
                    >
                      Discard
                    </Button>
                    <Button onClick={deleteData} destructive>
                      Yes, Remove
                    </Button>
                  </div>
                </section>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );

  function disambiguateLabel(key, value) {
    switch (key) {
      case "moneySpent":
        return `Money spent is between $${value[0]} and $${value[1]}`;
      case "taggedWith":
        return `Tagged with ${value}`;
      case "accountStatus":
        return value.map((val) => `Customer ${val}`).join(", ");
      default:
        return value;
    }
  }

  function isEmpty(value) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === "" || value == null;
    }
  }
};
export default PlanFeatures;
