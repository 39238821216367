import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { showErrorToast } from "../assets/toastUtils";
import {
  CustomShopifyIndexTable,
  CustomShopifyIndexTableCell,
  CustomShopifyIndexTableRow,
} from "../Components/CustomComponents/CustomShopifyIndexTable/CustomShopifyIndexTable";
import Loader from "./UI/Loader/Loader";
import InfiniteLoading from "../Components/CustomComponents/InfiniteLoading/InfiniteLoading";

const ChannelDetails = () => {
  const { ID } = useParams();
  const userToken = localStorage.getItem("token");
  const [data, setData] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [loader, setLoader] = useState(false);

  const fetchDetails = async (nextPageUrl) => {
    setLoader(true);
    const url = nextPageUrl
      ? nextPageUrl
      : `${process.env.REACT_APP_BASE_LOCAL_URL}/api/v1/admin/channel/mappings/${ID}`;
    try {
      const res = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (res.ok) {
        const json = await res.json();
        setData([...data, ...json.data.data]);
        setLoader(false);
        setNextPageUrl(json.data.nextPageUrl);
      }
    } catch (error) {
      showErrorToast(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, [ID]);
  return (
    <div className="room-types-full-container">
      <div style={{ background: "#fff", padding: "20px" }}>
        {loader ? (
          <Loader />
        ) : (
          <InfiniteLoading fetchInfo={fetchDetails} nextPageUrl={nextPageUrl}>
            <CustomShopifyIndexTable
              headings={[
                "Sr.No",
                "Property Name",
                "Channel ID",
                "Room Type",
                "Meal Plan",
              ]}
              style={{ border: "1px solid #ccc" }}
            >
              {data &&
                data.length > 0 &&
                data?.map((item, index) => {
                  return (
                    <>
                      <CustomShopifyIndexTableRow>
                        <CustomShopifyIndexTableCell
                          style={{
                            borderBottom:
                              index !== data.length - 1 && "1px solid #ccc",
                          }}
                        >
                          {index + 1}
                        </CustomShopifyIndexTableCell>
                        <CustomShopifyIndexTableCell
                          style={{
                            borderBottom:
                              index !== data.length - 1 && "1px solid #ccc",
                          }}
                        >
                          {item.property_name || "N/A"}
                        </CustomShopifyIndexTableCell>
                        <CustomShopifyIndexTableCell
                          style={{
                            borderBottom:
                              index !== data.length - 1 && "1px solid #ccc",
                          }}
                        >
                          {item.channel_unique_id || "N/A"}
                        </CustomShopifyIndexTableCell>

                        <CustomShopifyIndexTableCell
                          style={{
                            borderBottom:
                              index !== data.length - 1 && "1px solid #ccc",
                          }}
                        >
                          {item.room_name || "N/A"}
                        </CustomShopifyIndexTableCell>
                        <CustomShopifyIndexTableCell
                          style={{
                            borderBottom:
                              index !== data.length - 1 && "1px solid #ccc",
                          }}
                        >
                          {item.rate_plan_name || "N/A"}
                        </CustomShopifyIndexTableCell>
                      </CustomShopifyIndexTableRow>
                    </>
                  );
                })}
            </CustomShopifyIndexTable>
          </InfiniteLoading>
        )}
      </div>
    </div>
  );
};

export default ChannelDetails;
