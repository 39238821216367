export const ApprovedButton = () => {
  return (
    <div
      style={{
        color: "#15AA12",
        borderRadius: "4px",
        background: "#DFF8DF",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        padding: "3px 6px",
      }}
    >
      Approved
    </div>
  );
};

export const UpdateButton = () => {
  return (
    <div
      style={{
        color: "#FFF5DC;",
        borderRadius: "4px",
        background: "#FFF5DC;",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        padding: "3px 6px",
      }}
    >
      Update
    </div>
  );
};

export const PaidButton = () => {
  return (
    <div
      style={{
        color: "#888",
        borderRadius: "4px",
        background: "#EEE",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        padding: "3px 6px",
      }}
    >
      Paid
    </div>
  );
};
export const CPButton = () => {
  return (
    <div
      style={{
        color: "#888",
        borderRadius: "4px",
        background: "#EEE",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        padding: "3px 6px",
        display: "inline-flex",
      }}
    >
      CP
    </div>
  );
};

export const UnpaidButton = () => {
  return (
    <div
      style={{
        color: "#888",
        borderRadius: "4px",
        background: "#EEE",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        padding: "3px 6px",
      }}
    >
      Unpaid
    </div>
  );
};

export const CancelledButton = () => {
  return (
    <div
      style={{
        color: "#E03838",
        borderRadius: "4px",
        background: "#FFEAEA",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        padding: "3px 6px",
      }}
    >
      Cancelled
    </div>
  );
};

export const PendingButton = () => {
  return (
    <div
      style={{
        color: "#E8B41A",
        borderRadius: "4px",
        background: "#FFF5DC",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        padding: "3px 6px",
      }}
    >
      Pending
    </div>
  );
};

export const PaymentStatusButton = () => {
  return (
    <div
      style={{
        color: "#3968ED",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
      }}
    >
      Payment Details
    </div>
  );
};

export const BillGenerateButton = () => {
  return (
    <div
      style={{
        color: "#3968ED",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
      }}
    >
      Bill generate
    </div>
  );
};

export const ViewVoucherButton = ({ children }) => {
  return (
    <button
      style={{
        color: "#3968ED",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        borderRadius: "4px",
        border: "0.5px solid #3968ED",
        padding: "3px 6px",
        background: "transparent",
      }}
    >
      {children}
    </button>
  );
};

export const SyncButton = ({ onClick, icon }) => {
  return (
    <button icon={icon} onClick={onClick} className="BgOutlineThemeButton">
      {icon} Sync
    </button>
  );
};

export const BgThemeButton = ({
  disabled,
  children,
  onClick,
  type,
  style,
  icon,
  className,
}) => {
  return (
    <button
      icon={icon}
      style={style}
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={`BgThemeButton ${className}`}
    >
      {icon}
      {children}
    </button>
  );
};

export const BgThemeButtonFullWidth = ({
  children,
  onClick,
  onChange,
  style = { style },
}) => {
  return (
    <button
      style={style}
      onClick={onClick}
      onChange={onchange}
      className="BgThemeButtonFullWidth"
    >
      {children}
    </button>
  );
};

export const BgWhiteButton = ({ children, onClick, onChange }) => {
  return (
    <button onClick={onClick} onChange={onchange} className="BgThemeButton">
      {children}
    </button>
  );
};

export const BgOutlineWhite = ({
  children,
  onClick,
  onChange,
  style,
  icon,
}) => {
  return (
    <button
      style={style}
      onClick={onClick}
      onChange={onchange}
      className="BgOutlineWhite"
    >
      {icon}
      {children}
    </button>
  );
};

export const WithoutBgButtonBlue = ({
  style,
  children,
  onClick,
  onChange,
  icon,
}) => {
  return (
    <button
      style={style}
      onClick={onClick}
      onChange={onchange}
      className="WithoutBgButtonBlue"
    >
      {icon}
      <span>{children}</span>
    </button>
  );
};

export const BgOutlineThemeButton = ({
  children,
  onClick,
  onChange,
  icon,
  type,
  style,
}) => {
  return (
    <button
      style={style}
      onClick={onClick}
      onChange={onchange}
      className="BgOutlineThemeButton"
      type={type}
    >
      {icon}
      {children}
    </button>
  );
};

export const CheckWithoutBgUnderlineBtn = ({ children, onClick, onChange }) => {
  return (
    <button
      onClick={onClick}
      onChange={onchange}
      className="CheckWithoutBgUnderlineBtn"
      // style={{ fontSize: "12px" }}
    >
      {children}
    </button>
  );
};

export const BgThemeButtonDisabled = ({
  disabled,
  children,
  onClick,
  type,
  style,
  icon,
  className,
}) => {
  return (
    <button
      // style={style}
      icon={icon}
      style={{ backgroundColor: "#95A2C8", cursor: "no-drop" }}
      disabled={true}
      type={type}
      onClick={onClick}
      className={`BgThemeButton ${className}`}
    >
      {icon}
      {children}
    </button>
  );
};